import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    Table,
    Tooltip,
    Image,
    message
} from 'antd'

import people_icon from '../../../assets/images/people_icon.png'
import {roleListRequest} from '../../../services/roleService'

import './salesmanRole.scss'


const SalesmanRole = (props) => {
    let history = useHistory();
    const [isLoading,setIsLoading] = useState(false)
    const [roleList,setRoleList] = useState([])

    useEffect(() =>{
        getRoleList()
    },[])

      // 获取角色列表
      const getRoleList = () =>{
        roleListRequest({}).then(res =>{
            setRoleList(res)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    const columns = [
        {
            title: '角色',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '入职时间，并获取证书（天）',
            dataIndex: 'day_time',
            key: 'day_time',
        },
        {
            title: '服务商家',
            dataIndex: 'service_num',
            key: 'service_num',
        },
        {
            title: '数量',
            dataIndex: 'salesman_num',
            key: 'salesman_num',
        },
        // {
        //     title: '操作人',
        //     dataIndex: 'id5',
        //     key: 'id5',
        // },
        {
            title: '操作',
            render: (text,record) =>  <Tooltip placement="top" title={'查看人员'}>
            <Image
                src={people_icon}
                className="action_icon"
                preview={false}
                onClick={() => {
                    history.push('/sidebar/salesmanManage/list',{role_id: record.id})
                }}
                ></Image>
        </Tooltip>
        },
    ]


    return <div className="salesmanManage">
        <div className="content_body">
        <Table
            loading = {isLoading}
            columns={columns}
            dataSource={roleList}
            rowKey='id'
        />
        </div>
        
    </div>
}

export default SalesmanRole