import { request } from '../api/http';

//工作台（未分配门店、接入中门店、已接入门店)
export async function getWorkList(params) {
    return request({
        url: '/manage/work_rows',
        params,
        method:'get'
    });
}

//门店详情（流程节点）
export async function getStoreDetail(params) {
    return request({
        url: '/manage/store_detail',
        params,
        method:'get'
    });
}

//所有流程节点
export async function getAllCircuitNode(params) {
    return request({
        url: '/manage/all_circuit_node',
        params,
        method:'get'
    });
}

//保存流程节点
export async function saveCircuitNode(params) {
    return request({
        url: '/manage/save_circuit_node',
        params,
        method:'post'
    });
}

//删除流程节点
export async function delCircuitNode(params) {
    return request({
        url: '/manage/del_circuit_node',
        params,
        method:'post'
    });
}

//排序流程节点
export async function sortCircuitNode(params) {
    return request({
        url: '/manage/sort_circuit_node',
        params,
        method:'post'
    });
}

//获取所有流程商家门店列表
export async function getCircuitNodeStore(params) {
    return request({
        url: '/manage/circuit_node',
        params,
        method:'get'
    });
}

//获取指定流程节点商家列表
export async function findCircuitNodeStore(params) {
    return request({
        url: '/manage/find_circuit_node',
        params,
        method:'get'
    });
}

//获取所有角色成员
export async function getAllRoleMember(params) {
    return request({
        url: '/role/all_role_member',
        params,
        method:'get'
    });
}

//分配工作人员
export async function saveResponsible(params) {
    return request({
        url: '/manage/responsible',
        params,
        method:'post'
    });
}

//保存任务
export async function saveTask(params) {
    return request({
        url: '/manage/save_task',
        params,
        method:'post'
    });
}

//删除任务
export async function delTask(params) {
    return request({
        url: '/manage/del_task',
        params,
        method:'post'
    });
}

//保存任务问题、答案
export async function saveProblem(params) {
    return request({
        url: '/manage/save_problem',
        params,
        method:'post'
    });
}

//删除答案
export async function delAnswer(params) {
    return request({
        url: '/manage/del_answer',
        params,
        method:'post'
    });
}

//删除问题
export async function delProblem(params) {
    return request({
        url: '/manage/del_problem',
        params,
        method:'post'
    });
}

//获取门店任务、问题、答案
export async function getShopProblem(params) {
    return request({
        url: '/manage/shop_problem',
        params,
        method:'get'
    });
}

//提交门店问题答案
export async function saveProblemAnswer(params) {
    return request({
        url: '/manage/save_problem_answer',
        params,
        method:'post'
    });
}

//更新门店流程节点
export async function updateNode(params) {
    return request({
        url: '/manage/update_node',
        params,
        method:'post'
    });
}

//门店工作留言板
export async function getStoreMessage(params) {
    return request({
        url: '/manage/store_message',
        params,
        method:'get'
    });
}

//添加工作留言板
export async function addMessage(params) {
    return request({
        url: '/manage/add_message',
        params,
        method:'post'
    });
}

//删除工作留言板
export async function delMessage(params) {
    return request({
        url: '/manage/del_message',
        params,
        method:'post'
    });
}

