import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    Image,
    message,
    Spin,
    Radio,
    Button,
    Input
} from 'antd'

import {shopApplyDetailRequest,storeAuthConfirmReview} from '../../../../services/merchantService'

import './authDetail.scss'
const AuthDetail = (props) =>{
    const history = useHistory()
    const [isLoading,setIsLoading] = useState(false)
    const statusList = {
        0:'待审核',
        1:'后台人员审核完成待提交到乐刷',
        2:'媒体文件上传完成',
        3:'审核提交到乐刷',
        4:'通过乐刷审核',
        5:'审核失败',
        6:'提交微信认证',
        7:'微信认证完成',
        10:'审核成功',
    }
    const [shopInfo,setShopInfo] = useState({
        status: 0,
        shop_name: '', // 门店名称
        shop_adress: '', // 地址  --> 手动处理
        address: '', //详细地址
        manage_type: '', // 经营类型 --> 手动处理
        catering_type: '', // 餐饮类型
        service_phone: '', // 门店客服联系方式
        contact_name: '', // 联系人姓名
        contact_phone: '', // 联系人手机号
        id_card_front: '', // 身份证正面照片
        id_card_reverse: '', // 身份证反面照片
        hand_id_card: '', // 手持身份证照片
        contact_id_card: '', // 身份证号码
        bank_front: '', // 银行卡正面照片
        contact_bank_card: '', // 银行卡号
        contact_bank_name: '', // 银行卡名称
        contact_bank_branch_name: '', // 支行名称
        shop_exterior_pic: '', // 门店门头照片
        shop_inner_pic: '', // 店内环境照片
        cashier_desk_pic: '', //收银台照片
        business_license: '', // 营业执照照片
        business_license_num: '', // 营业执照号
        business_license_name: '', // 营业执照名称
        food_license: '', // 食品经营许可证
        base_info:''
    })
    useEffect(() =>{
        if(history.location.state){
            getShopInfo(history.location.state.id)
        }else{
            history.goBack()
        }
    },[])

    // 获取 商户详情
    const getShopInfo = (id) =>{
        shopApplyDetailRequest({id}).then(res =>{
            setShopInfo({
                ...shopInfo,
                ...res,
                shop_adress: res.province + res.city + res.district,
                //经营类型. 1小微商户.   2 个体工商户.   3 企业.  4其他
                manage_type: res.manage_type ===  1 ? '小微商户':
                             res.manage_type ===  2 ? '个体工商户':
                             res.manage_type ===  3 ? '企业': '其他',
                base_info:JSON.parse(res.base_info),
                id_card_info:JSON.parse(res.id_card_info)
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    const getShopStatus = (status) =>{
        return statusList[status]
    }


    const createViewForm = (title,value,type = "text") => {
        return  <div className="view_form">
        <div className="form_item_title">
            {title} :
        </div>
        <div className={`form_item_value ${type === 'text' && 'border'}`}>
                {
                    type === 'pic' ? (
                        value&&<Image preview src={value} alt="loading"/>
                    ):(
                        type === 'radio' ? (
                            <Radio.Group value={value} disabled>
                                <Radio value={true}>有</Radio>
                                <Radio value={false}>没有</Radio>
                            </Radio.Group>
                        ) : (
                            value
                        )
                    ) 

                }
        </div>
        
    </div>
    }
    
    const onSubmit = ()=>{
        let params = {
            appid: shopInfo.subAppid,
            wx: shopInfo.userWx,
            id: shopInfo.id
        }
        storeAuthConfirmReview(params).then((res)=>{
            getShopInfo(shopInfo.id)
            message.success('提交成功')
        }).catch((error)=>{
            message.error(error)
        })
    }

    const changeField = (e, field) => {
        shopInfo[field] = e
        setShopInfo(JSON.parse(JSON.stringify(shopInfo)))
    }

    return <Spin spinning={isLoading}>
        <div className="auth_detail">
        <h4 className="fz17">状态:{getShopStatus(shopInfo.status)}</h4>
        {
            shopInfo.status!==0&&shopInfo.status!==10&&shopInfo.tips&&<h4 className="mb10 fz17" style={{color:'red'}}>进度信息:{shopInfo.tips}</h4>
        }
        {
          shopInfo.qrcode_data&&shopInfo.status===6&&<div className="detail_auth">
            <h4 className="mb10">商家授权码</h4>
            <Image className="qrcode" preview={true} src={shopInfo.qrcode_data}></Image>
          </div>
        }

        {
            shopInfo.base_info&&(
                <div className="detail_auth">
                    <h4 className="mb10">门店配置</h4>
                    {createViewForm('门店Wi-Fi有没有良好并覆盖所有位置',shopInfo.base_info.is_wifi,'radio')}
                    {createViewForm('目前使用哪家点餐收银系统',shopInfo.base_info.is_system)}
                    {createViewForm('有没有使用打印机',shopInfo.base_info.is_print,'radio')}        
                    {shopInfo.base_info.is_print&&createViewForm('打印机型号',shopInfo.base_info.print_version)}        
                    {createViewForm('收银台有没有电脑',shopInfo.base_info.cashier_desk_computer,'radio')}
                    {createViewForm('门店有没有公众号',shopInfo.base_info.is_tencent_isux,'radio')}
                    {createViewForm('门店有没有点餐小程序',shopInfo.base_info.is_wechat,'radio')}
                </div>
            )
        }        
        <div className="detail_auth">
            <h4>门店信息</h4>
            {createViewForm('门店名称',shopInfo.shop_name)}
            {createViewForm('所在省市',shopInfo.shop_adress)}
            {createViewForm('门店地址',shopInfo.address)}
            {createViewForm('经营类型',shopInfo.manage_type)}
            {createViewForm('餐饮类型',shopInfo.catering_type)}
            {createViewForm('门店客服联系方式',shopInfo.service_phone)}
        </div>
        <div className="detail_auth">
            <h4>场景信息</h4>
            {createViewForm('门店门头照片',shopInfo.shop_exterior_pic,'pic')}
            {createViewForm('店内环境照片',shopInfo.shop_inner_pic,'pic')}
            {createViewForm('收银台照片',shopInfo.cashier_desk_pic,'pic')}
        </div>
        <div className="detail_auth">
            <h4>经营信息</h4>
            {createViewForm('营业执照照片',shopInfo.business_license,'pic')}
            {createViewForm('营业执照号',shopInfo.business_license_num)}
            {createViewForm('营业执照名称',shopInfo.business_license_name)}
            {createViewForm('食品经营许可证',shopInfo.food_license,'pic')}
        </div>
        <div className="detail_auth">
            <h4>结算信息</h4>
            {createViewForm('法人姓名',shopInfo.contact_name)}
            {createViewForm('法人手机号',shopInfo.contact_phone)}
            {createViewForm('法人身份证正面照片',shopInfo.id_card_front,'pic')}
            {createViewForm('法人身份证反面照片',shopInfo.id_card_reverse,'pic')}
            {shopInfo.hand_id_card&&createViewForm('法人手持身份证照片',shopInfo.hand_id_card,'pic')}
            {createViewForm('法人身份证号码',shopInfo.contact_id_card)}
            {createViewForm('法人身份证有效期',shopInfo.id_card_info&&(shopInfo.id_card_info.idCardStart.replace(new RegExp('-','g'),'/')+'-'+shopInfo.id_card_info.idCardEnd.replace(new RegExp('-','g'),'/')))}

            {shopInfo.account_type===0&&createViewForm('结算人姓名',shopInfo.open_acount_name_bak)}
            {shopInfo.account_type===0&&createViewForm('结算授权书',shopInfo.authorization_paper_bak,'pic')}

            {createViewForm(shopInfo.account_type===0?'结算银行卡正面照片':'银行卡正面照片',shopInfo.bank_front,'pic')}
            {createViewForm(shopInfo.account_type===0?'结算银行卡号':'银行卡号',shopInfo.contact_bank_card)}
            {createViewForm(shopInfo.account_type===0?'结算银行卡名称':'银行卡名称',shopInfo.contact_bank_name)}
            {createViewForm(shopInfo.account_type===0?'结算支行名称':'支行名称',shopInfo.contact_bank_branch_name)}

            {shopInfo.account_type===0&&createViewForm('结算人身份证正面',shopInfo.id_card_front_bak,'pic')}
            {shopInfo.account_type===0&&createViewForm('结算人身份证反面',shopInfo.id_card_reverse_bak,'pic')}
            {shopInfo.account_type===0&&createViewForm('结算人身份证号码',shopInfo.id_card_number_bak)}
        </div>
        <div className="detail_auth">
            <div className="view_form">
                <div className="form_item_title">
                    微信号 :
                </div>
                <div >
                    <Input className='form_item_value' value={shopInfo.userWx} placeholder="请输入微信号" onChange={(e)=>{changeField(e.currentTarget.value,'userWx')}}/>
                </div>
            </div>
            <div className="view_form">
                <div className="form_item_title">
                    小程序appid :
                </div>
                <div >
                    <Input className='form_item_value' value={shopInfo.subAppid} placeholder="请输入小程序appid"  onChange={(e)=>{changeField(e.currentTarget.value,'subAppid')}}/>
                </div>
            </div>
        </div>
        {
            (shopInfo.status===5||shopInfo.status===0)&&(
                <div className="footer">
                    <Button type="primary" size="large" onClick={()=>{onSubmit()}}>提交</Button>
                </div>
            )
        }
    </div>
    </Spin> 
}

export default AuthDetail