import React, { useEffect, useState } from 'react'
import {
    Input,
    Button,
    Table,
    message
} from 'antd'
import {IconFont} from '../../../utils/common'
import {agentEarningListRequest} from '../../../services/agentService'
import './agentProfit.scss'

const {Search} = Input

const AgentProfit = (props) =>{
    const [isLoading,setIsLoading] = useState(false)
    const [ downloadLoading, setDownloadLoading] = useState(false)
    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])

      // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        agentEarningListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }


    const columns = [
        {
            title: '代理商ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '代理商名称',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '本月收益',
            dataIndex: 'current_month_balance',
            key: 'current_month_balance',
        },
        {
            title: '账户余额',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: '订单数量',
            dataIndex: 'order_num',
            key: 'order_num',
            render: text => <div className="action_item">{text}</div>
        }
    ]

    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        agentEarningListRequest({page:1,pageSize:3000}).then(response=>{
            import("../../../utils/Export2Excel").then((excel) => {
                const tHeader = ["代理商ID", "代理商名称", "手机号码", "本月收益", "账户余额", "订单数量"];
                const filterVal = ["id", "nickname", "mobile", "current_month_balance", "balance", "order_num"];
                const dataSourse = formatJson(filterVal, response.data);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '代理商收益', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }

    return<div className="agentProfit">
        <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />} onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
        <div className="content_body">
            <div className="screen-dart">
                <Search
                    placeholder="输入姓名、ID、手机号搜索"
                    className="input_view"
                    onSearch={(e) => handleSerachForm(e,'search')}
                    onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
                />
            </div>

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={pageInfo.list}
                rowKey='id'
                pagination={pagination}
            />
        </div>
    </div>
}

export default AgentProfit