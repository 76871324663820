import React, { useState,useEffect } from 'react'
import { useHistory } from "react-router-dom";
import './mealManage.scss'
import {IconFont} from '../../../utils/common'
import {
    Button,
    Input,
    Space,
    Table,
    Image,
    Tooltip,
    message
} from 'antd'
import delete_icon from '../../../assets/images/delete_icon.png'
import edit_icon from '../../../assets/images/edit_icon.png'
import {mealListRequest,meaDelRequest} from '../../../services/systemService'
const {Search} = Input


const MealManage = (props) =>{
    let history = useHistory();

    const [isLoading,setIsLoading] = useState(false)

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])

      // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        mealListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '套餐名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '套餐内容',
            dataIndex: 'content',
            key: 'content',
            render: (text) => {
               return  <div>
                    { JSON.parse(text).map((item,index) => {
                       return index <  JSON.parse(text).length - 1 ? item.name+ '、': item.name
                    }) }
                </div>
            }
        },
        {
            title: '收费标准',
            dataIndex: 'money',
            key: 'money',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '更新时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: '操作人',
            render: (text,record) => record.operation_name + '-' + record.operation_id
        },
        {
            title: '操作',
            render: (text,record) => <div>
                <Space>
                    <Tooltip placement="top" title={'详情'}>
                        <Image src={edit_icon} className="action_icon" preview={false}
                        onClick={() => {
                            history.push("/sidebar/systemStore/mealManage/detail",{id: record.id})
                    }}></Image>
                    </Tooltip>
                    <Tooltip placement="top" title={'删除'}>
                        <Image  onClick={() => delMeal(record.id)} src={delete_icon} className="action_icon"  preview={false}></Image>
                    </Tooltip>
                    
                </Space>
            </div>,
        },
    ]

    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    const delMeal = (id) =>{
        meaDelRequest({id}).then(res =>{
            getPageList(pageParams)
            message.success('删除成功')
        }).catch((error)=>{
            message.error(error)
          })
    }

    return <div className='mealManage'>
        <Space>
            <Button type="default" onClick={() => {
                    history.push("/sidebar/systemStore/mealManage/detail")
            }} icon={<IconFont type={'iconadd_icon'}  style={{fontSize:16}} />}>新增</Button>
        </Space>

        <div className="content_body">
            <div className="screen-dart">
                <Search
                    placeholder="输入套餐名称"
                    className="input_view"
                    onSearch={(e) => handleSerachForm(e,'search')}
                    onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
                    />
            </div>
            <Table
                 loading={isLoading}
                 columns={columns}
                 dataSource={pageInfo.list}
                 rowKey='id'
                 pagination={pagination}
            />
        </div>
        
    </div>

    
}

export default MealManage