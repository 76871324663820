import React, { useEffect,useState } from 'react'
import {
    Button,
    Input,
    Image, Space,message
} from 'antd'
import './detail.scss'
import { PlusOutlined,PlusCircleOutlined,MinusCircleOutlined } from '@ant-design/icons';

import {incrementAddRequest,incrementDetailRequest} from '../../../../services/systemService'
import { useHistory } from 'react-router-dom';
import {getQiniuToken} from '../../../../services/commonService'
import {qiniu_upload} from '../../../../utils/qiniuUpload'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const ServicesManageDetail = (props) =>{
    let history = useHistory()
		const [isLoading,setIsLoading] = useState(false)
		const [currentId,setCurrentId] = useState(0)
    const [formParams,setFormParams] = useState({
        name: '', //增值服务
        desc: '', // 描述
        unit: '',  // 使用周期
        price: '', // 价格
        service_content: [''], //服务内容
        img: '', // 海报

        version: '屮',
        service_type: 2,
        detail: {'text': ''},
      })
    
      useEffect(() =>{
        if(history.location.state){
					setCurrentId(history.location.state.id)
					getIncrementDetail(history.location.state.id)
        }
      },[])

      // 获取增值服务详情
      const getIncrementDetail = (id) =>{
        incrementDetailRequest({id}).then(res =>{
          setFormParams({
            ...formParams,
            name: res.name,
            desc: res.desc,
            price: res.price,
            service_content: JSON.parse(res.service_content),
            img: res.img,
            unit: res.unit,
          })
        }).catch((error)=>{
          setIsLoading(false)
          message.error(error)
      })
      }

       // 提交数据
      const submitParams = () =>{
        let flag = validatorParams()
        if(flag){
          setIsLoading(true)
          let formParamsTemp = JSON.parse(JSON.stringify(formParams))
          formParamsTemp.service_content = JSON.stringify( formParamsTemp.service_content)
        //   formParamsTemp.unit = JSON.stringify(formParamsTemp.unit)
          formParamsTemp.detail = JSON.stringify(formParamsTemp.detail)
          // 整理提交数据
          if(history.location.state){
            setIsLoading(false)
            // 编辑
            if(formParams.img.indexOf("http") == -1){
            //   customRequest((res) =>{
            //     if(res.status === 200){
            //       formParamsTemp.img = res.url
            //       mealEditRequest(formParamsTemp).then(res =>{
            //         message.success('编辑套餐成功')
            //       }).catch((error)=>{
            //         setIsLoading(false)
            //         message.error(error)
            //       })
            //     }
            //   }).catch(err =>{
            //     setIsLoading(false)
            //   })
            }else{
              // mealEditRequest(formParamsTemp).then(res =>{
              //   message.success('编辑套餐成功')
              // }).catch((error)=>{
              //   setIsLoading(false)
              //   message.error(error)
              // })
            }
          }else{
            // 新建
            customRequest((res) =>{
              if(res.status === 200){
                formParamsTemp.img = res.url
                incrementAddRequest(formParamsTemp).then(res =>{
                  message.success('添加增值服务成功')
                  history.goBack()
                  setIsLoading(false)
                }).catch((error)=>{
                  setIsLoading(false)
                  message.error(error)
                })
              }
            }).catch(err =>{
              setIsLoading(false)
            })
          }
        }
      }

      // 校验提交数据
      const validatorParams = () =>{
        if(!formParams.name){
          message.error('请填写增值服务名称')
          return false
        }
        if(!formParams.desc){
          message.error('请填服务描述')
          return false
        }
        if(!formParams.price){
          message.error('请填写标准价格')
          return false
        }
        let index = formParams.service_content.findIndex(item => !item)
        if(index != -1){
          message.error('请填服务内容')
          return false
        }
        if(!formParams.img){
          message.error('请选择项目海报')
          return false
        }
        return true
      }


      // 监听提交表单变化
      const handleFormParams = (e,type,index = -1) =>{
        let formParamsTemp = JSON.parse(JSON.stringify(formParams))
        switch(type){
          case 'name':
            setFormParams({...formParams,name: e.target.value})
						break;
          case 'unit':
            setFormParams({...formParams,unit: e.target.value})
            break;
          case 'desc':
            setFormParams({...formParams,desc: e.target.value})
            break;
          case 'price':
            formParamsTemp.price = checkNumber(e.target.value)
            setFormParams({...formParamsTemp})
            break;
          case 'service_content':
            formParamsTemp.service_content[index] = e.target.value
            setFormParams({...formParams,service_content: formParamsTemp.service_content})
            break;
        }
      }

      const checkNumber = (value) => {
          value = value.replace(/[^\d.]/g,"")
          value = value.replace(/\.{2,}/g,".")
          value = value.replace(".","$#$").replace(/\./g,"").replace("$#$",".")
          value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
          if(value.indexOf(".")< 0 && value !=""){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
              value= parseFloat(value);  
          }
          return value
      }

      // 获取key
      const getQiniuKey = () =>{
        return `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`; // 生成随机key
      }
      const customRequest = async (callBack) =>{
        let files = document.querySelector('#fileImage').files;
        if(files && files.length > 0){
          getQiniuToken({type: 'food'}).then( async res =>{
                    let imageKey = getQiniuKey()
                    let observer = {
                        complete: (res) => {
                            callBack({status: 200,url:res.url})
                            console.log('上传成功',res)
                        },
                        error: (error) =>  {
                            message.error(error || '上传资源失败');
                            callBack({status: 500,error})
                            return
                        },
                        next: (next) => {
                            console.log('上传进度',next.total.percent)
                            return
                        }
                    }
                    qiniu_upload(files[0],imageKey,res.upload_token,observer,{customVars: {'x:type': 'food'}})
                    
                }).catch(error => {
                    message.error(error || '获取七牛token失败')
                })
        }
      }
      // 显示选择图片
      const chooseFile = () =>{
        let files = document.querySelector('#fileImage').files;
        if(files && files.length > 0){
          getBase64(files[0]).then(res =>{
            setFormParams({ ...formParams,img: res})
          })
        }
      }
      const createUpload = () =>{
        function clickFile(){
          document.getElementById("fileImage").click(); 
        }
        return <>
            <input id="fileImage" multiple accept=".png,.jpeg,.jpg" style={{display: 'none'}} type="file" onChange={chooseFile}></input>
            <div className="poster_view" >
            <div className="upload_view flex-center">
              {
                formParams.img ?
                <Image preview={false} onClick={clickFile} src={formParams.img} className="upload_img"/>
                :
                <PlusOutlined onClick={clickFile} style={{fontSize: 32,color: '#F0F0F0'}} />
              }
              </div>
              <div>(建议上传尺寸为1:1)</div>
            </div>
          </>
      }

      const addContent =() =>{
        let formParamsTemp = JSON.parse(JSON.stringify(formParams))
        formParamsTemp.service_content.push('')
        setFormParams(formParamsTemp)
      }

      const delContent =(index) =>{
        console.log(index)
        let formParamsTemp = JSON.parse(JSON.stringify(formParams))
        formParamsTemp.service_content.splice(index,1)
        setFormParams(formParamsTemp)
      }

      return <div className="systemPriceDetail">
        <div className="detail_body_info">
        <div className="title">详细信息</div>
          <div className="form_item">
              <div className="item_title">增值服务项目:</div>
              <Input className="item_value" value={formParams.name} onInput={e => handleFormParams(e,'name')} placeholder="请输入增值服务项目" />
          </div>
          <div className="form_item">
              <div className="item_title">描述:</div>
              <Input className="item_value" value={formParams.desc} onInput={e => handleFormParams(e,'desc')} placeholder="请输入描述" />
          </div>
          <div className="form_item">
              <div className="item_title">使用周期:</div>
              <Input className="item_value" placeholder="请输入使用周期，例1/年、1/篇" value={formParams.unit} onInput={e => handleFormParams(e,'unit')} />
          </div>
          <div className="form_item">
              <div className="item_title">标准价格:</div>
              <Input className="item_value" value={formParams.price} onInput={e => handleFormParams(e,'price')} placeholder="请输入标准价格" />
          </div>

          <div className="form_item align-start">
              <div className="item_title">服务内容:</div>
              <div>
              {
                formParams.service_content.map((item,index) =>{
                  return  <div className="service_content" key={index}>
                            <Input className="item_value" value={item} onInput={e => handleFormParams(e,'service_content',index)}  />
                            <Space className="add_del">
                              {index == 0 && <PlusCircleOutlined  style={{fontSize: 18,color: 'red'}} onClick={() => addContent()}/>}
                              {
                                index != 0 && <MinusCircleOutlined style={{fontSize: 18}} onClick={() => delContent(index)}/>
                              }
                            </Space>
                          </div>
                })
              }
              </div>
              
          </div>
          <div className="form_item align-start">
              <div className="item_title">项目海报:</div>
            <div>{createUpload()}</div>
          </div>
        </div>
				{
					currentId==0&&<div className='action_button'>
						<Button type="primary" onClick={() => submitParams()} loading={isLoading}>确定</Button>
					</div>
				}
    </div>
}

export default ServicesManageDetail;