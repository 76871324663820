import React, { useEffect, useState } from 'react'
import { Descriptions } from 'antd';
import {orderDetailRequest} from '../../../services/orderService'
import { useLocation, useHistory } from 'react-router-dom';

import './detail.scss'

const OrderDetail = (props)=>{

    const location = useLocation()
    const history = useHistory()
    const [ info, setInfo] = useState({})

    useEffect(() =>{
        getMemberDetail();
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps            
    },[]);

    const getMemberDetail = async()=>{
        if(!location.state||!location.state.id){
            history.goBack()
            return false
        }
        const res = await orderDetailRequest({id: location.state.id});
        res.commodity_detail = JSON.parse(res.commodity_detail)
        setInfo(res)       
    }

    return(
        <div className="customer_detail">
            <div className="detail_body_info">
            <Descriptions title="基本信息" bordered >
                <Descriptions.Item label="订单号">{info.platform_code}</Descriptions.Item>
                <Descriptions.Item label="下单时间">{info.created_at}</Descriptions.Item>
                <Descriptions.Item label="支付时间">{info.pay_time}</Descriptions.Item>
                <Descriptions.Item label="购买人姓名" span={3}>{info.real_name}</Descriptions.Item>
                <Descriptions.Item label="手机号码">{info.mobile}</Descriptions.Item>
                <Descriptions.Item label="业务员">{info.salesman_name}-{info.salesman_id} </Descriptions.Item>
                <Descriptions.Item label="门店名称">{info.shop_name} </Descriptions.Item>
                <Descriptions.Item label="门店ID">{info.shop_id} </Descriptions.Item>
                <Descriptions.Item label="-">-</Descriptions.Item>
            </Descriptions>
            </div>
            <div className="detail_body_info">
            <Descriptions title="购买信息" bordered>
                <Descriptions.Item label="购买金额"  span={1}>{info.pay_money}</Descriptions.Item>
                <Descriptions.Item label="购买数量" span={1}>{info.commodity_detail&&info.commodity_detail.length}</Descriptions.Item>
                <Descriptions.Item label="-">-</Descriptions.Item>
            </Descriptions>   
            {
                info.commodity_detail&&info.commodity_detail.map((item,index)=>{
                    return <Descriptions title="" bordered key={index}>
                        <Descriptions.Item label="服务项目"  span={1}>{item.name}</Descriptions.Item>
                        <Descriptions.Item label="购买金额" span={1}>{item.price}</Descriptions.Item>
                        <Descriptions.Item label="使用期" span={1}>{item.unit}</Descriptions.Item>
                    </Descriptions>  
                })
            }
            </div>        
        </div>
    )

}

export default OrderDetail