import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom";
import { 
    Button,
    Input,
    DatePicker,
    Select,
    Space,
    Table,
    Tooltip,
    Image,
    message,
    Modal
} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {IconFont} from '../../../utils/common'
import detail_icon from '../../../assets/images/detail_icon.png'
import dongjie_icon from '../../../assets/images/dongjie_icon.png'
import jiedong_icon from '../../../assets/images/jiedong_icon.jpg'
import qrcode_icon from '../../../assets/images/qrcode_icon.png'
import './salesmanManage.scss'

import {saleManListRequest,saleManEditStatusRequest} from '../../../services/salesManService'
import {roleListRequest} from '../../../services/roleService'

const { Option } = Select;
const {Search} = Input;
const { RangePicker } = DatePicker;

const selectData = [{id: -1,name:'全部'},{id: 0,name:'正常'},{id: 1,name:'冻结'}]

const SalesmanManage = (props) => {
    let history = useHistory();
    let location = useLocation();

    const [isLoading,setIsLoading] = useState(false)
    const [roleData,setRoleData] = useState([])
    const [ downloadLoading, setDownloadLoading] = useState(false)

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        status: -1, // 0正常 1冻结 
        timer: [],
        role_id: -1
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    const columns = [
        {
            title: '业务员ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '姓名',
            dataIndex: 'real_name',
            key: 'real_name',
        },
        {
            title: '角色',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '开通时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '上级代理商',
            dataIndex: 'agent_name',
            key: 'agent_name',
        },
        {
            title: '上级代理商ID',
            dataIndex: 'parent_id',
            key: 'parent_id',
        },
        {
            title: '本月收益',
            dataIndex: 'current_month_money',
            key: 'current_month_money',
        },
        {
            title: '总收益',
            dataIndex: 'total_balance',
            key: 'total_balance',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            render: text => text == 0 ? '正常': '已冻结'
        },
        {
            title: '操作',
            render: (text,record) => <div>
                <Space>
                    <Tooltip placement="top" title={record.status == 0 ? '冻结':'解冻'}>
                        <Image src={record.status == 0 ? dongjie_icon:jiedong_icon} className="action_icon" onClick={() => disableOrEnable(record)}  preview={false}></Image>
                    </Tooltip>
                    <Tooltip placement="top" title={'详情'}>
                        <Image src={detail_icon} className="action_icon"  preview={false} onClick={() => {
                            history.push("/sidebar/salesmanManage/detail",{saleInfo: record})
                        }}></Image>
                    </Tooltip>
                    <Tooltip placement="top" title={'推广二维码'}>
                        <Image src={qrcode_icon} className="action_icon"  preview={false}></Image>
                    </Tooltip>
                </Space>
            </div>
        },
    ]

    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        saleManListRequest({page:1,pageSize:3000}).then(response=>{
            import("../../../utils/Export2Excel").then((excel) => {
                const tHeader = ["业务员ID", "姓名", "角色", "手机号", "开通时间", "上级代理商", "上级代理商ID", "本月收益", "总收益", "状态"];
                const filterVal = ["id", "real_name", "name", "mobile", "created_at", "agent_name", "parent_id", "current_month_money", "total_balance", "status_str"];
                let dataArr = response.data.map((item,index)=>{
                    item.status_str = item.status == 0 ? '正常': '已冻结'
                    return item
                })
                const dataSourse = formatJson(filterVal, dataArr);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '业务员列表', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }

    useEffect(() =>{
        getRoleList()
        if(history.location.state){
            // let user_id = history.location.state.user_id
            // let role_id = history.location.state.role_id
            // let roleId = history.location.state.roleId
            if(history.location.state.user_id) {
                serachParams.user_id = history.location.state.user_id
                pageParams.user_id = history.location.state.user_id
            }
            if(history.location.state.role_id) {
                serachParams.role_id = history.location.state.role_id
                pageParams.role_id = history.location.state.role_id
            }
            setSerachParams({...serachParams})
            getSaleManList({...pageParams})
        }else{
            getSaleManList(pageParams)
        }
    },[])

    // 获取角色列表
    const getRoleList = () =>{
        roleListRequest({}).then(res =>{
            setRoleData(res)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 获取代理商列表
    const getSaleManList = (params) =>{
        setIsLoading(true)
        saleManListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.status != -1) params.status = serachParams_temp.status
        if(serachParams_temp.role_id != -1) params.role_id = serachParams_temp.role_id
        if(serachParams_temp.timer.length > 0) {
            params.created_start = serachParams_temp.timer[0]
            params.created_end = serachParams_temp.timer[1]
        }
        getSaleManList(params)
    }

    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'status':
                serachParams_temp = {...serachParams,status: e}
                break;
            case 'timer':
                serachParams_temp = {...serachParams,timer: e}
                break;
            case 'role_id':
                serachParams_temp = {...serachParams,role_id: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }

    const disableOrEnable =  (item) =>{
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: item.status ? '是否解冻该业务员账户':'是否冻结该业务员账户',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
              saleManEditStatusRequest({id: item.id,status: item.status ? 0: 1}).then(res =>{
                message.success('操作成功')
                collectParams(pageParams.page,serachParams)
              }).catch((error)=>{
                message.error(error)
            })
            }
        });
    }
    return <div className="salesmanManage">
        <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />} onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
        <div className="content_body">
        <div className="screen-dart">
            <Search
            placeholder="输入姓名、ID、手机号搜索"
            onSearch={(e) => handleSerachForm(e,'search')}
            onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
            className="input_view" />
          
            <div className="screen-item">
                <div className="item-title">状态:</div>
                <Select
                    onChange={() =>{}}
                    value={serachParams.status}
                    onChange={(e) => handleSerachForm(e,'status')}
                    className="select-css"
                  >
                    {selectData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                  </Select>
            </div>
            <div className="screen-item">
                <div className="item-title">角色:</div>
                <Select
                   onChange={(e) => handleSerachForm(e,'role_id')}
                    value={serachParams.role_id}
                    className="select-css"
                  >
                      <Option key={-1} value={-1}>全部</Option>
                    {roleData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                  </Select>
            </div>
            <div className="screen-item">
                <div className="item-title">开通时间:</div>
                <RangePicker
                    timer={serachParams.timer}
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'timer')}
                    />
            </div>
        </div>
        <Table
            loading={isLoading}
            columns={columns}
            dataSource={pageInfo.list}
            rowKey='id'
            pagination={pagination}
        />
        </div>
    </div>
}

export default SalesmanManage