import React, { useState, useEffect } from 'react';
import { Input, message} from 'antd'
import { getWorkList, saveCircuitNode } from '../../services/task'
import { stores } from '../../stores/store'
import { Observer } from 'mobx-react';
import WorkList from './components/indexList';

import './index.scss'

const TaskIndex = (props) => {

	const [ list, setList ] = useState([])

  useEffect(() => {
		_init()
  }, [])
	
	const _init = () => {
		stores.userStore.getAdminInfo();
		getWorkList().then(res=>{
			setList(res)
		}).catch(err=>{
			message.error(err||'工作台信息获取失败')
		})
	}
	
  const changeEditList = (key) => {
		if(list[key].isEdit){
			let params = {
				name: list[key].name , //名称
				id: list[key].id , //节点id（修改时传				
			}
			saveCircuitNode(params).then(res=>{
				console.log(res)
			}).catch(err=>{
				message.error(err||'修改任务名称失败')
				_init()
				return false
			})
		}
    let arr = list.map((item,index)=>{
      if(index == key){
        item.isEdit = !item.isEdit
      }
      return item
    })
    setList(arr)
	}
	
	const changeInput = (e,key)=>{
		let arr = JSON.parse(JSON.stringify(list))
		arr[key].name = e.currentTarget.value
		setList(arr)
	}

	const callBack = () => {
		_init()
	}

  return <Observer render={() => <div className="task_index">
      <div className="task_lsit">
					{
						list.map((item,index)=>{
							return (
								<div className="task_item" key={item.id}>
										<div className="task_item_title">
											<div className="task_item_title_l">
													{
															item.isEdit?(
																	<Input value={item.name} placeholder="请输入任务名称" className="mb10" onBlur={(e)=>{changeEditList(index)}} onChange={(e)=>{changeInput(e,index)}}/>
															):(
																	<div>{item.name}</div>
															)
													}
													<div>{item.rows.total}家门店</div>
											</div>
											{/* {
												stores.userStore.adminInfo.group_operate_id === 0 && <div className="task_item_title_r">
													<Image src={edit_icon} onClick={()=>{changeEditList(index)}} preview={false}/>
												</div>
											} */}
										</div>
										<WorkList total={item.rows&&item.rows.total?item.rows.total:0} id={item.id} callBack={()=>{callBack()}} pIndex={index} initData={item.rows&&item.rows.data?item.rows.data:[]} />
								</div>
							)
						})
					}
      </div>
  </div>
	}/>
}
export default TaskIndex