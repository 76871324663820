import React, { useEffect, useState } from 'react';
import {
    Button,
    message,
    Modal,
    Table,
    Tag,
} from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import Column from '../column';
import Pagination from '../pagination';
import { fetchTemplates, deleteTemplate } from '../../../services/miniProgramService';

/**
 * 小程序-模板列表页面 (目前微信API接口尚未提供出分页接口,只能使用本地分页方式)
 * @param {*} props 
 */
const TemplatePage = (props) => {
    // 加载状态以及设置函数
    const [isLoading, setIsLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        totalPage: 0, // 总页数
        total: 0,  // 总条数
        currentPage: 1,  // 当前显示页
        originList: [],  // 原始数据
        currentList: []  // 当前显示的分页数据
    });

    const [dialogInfo, setDialogInfo] = useState({
        visible: false,
        template: null,
        confirmLoading: false,
    });

    useEffect(() => {
        initData();
    }, []);

    const initData = () => {
        setIsLoading(true);
        fetchTemplates().then((result) => {
            setIsLoading(false);
            const originList = Pagination.formatWithTime(result.template_list);
            paginationHandler(originList, pageInfo.currentPage);
        }).catch((ex) => {
            console.error(ex);
            setIsLoading(false);
        })
    }

    const paginationHandler = (totalList, currentPage) => {
        const currentList = Pagination.pagination(totalList, currentPage, Pagination.PAGE_SIZE);
        if (currentList.length) {
            const totalPage = (totalList.length % Pagination.PAGE_SIZE) > 0 ? (totalList.length / Pagination.PAGE_SIZE + 1) : totalList.length / Pagination.PAGE_SIZE;
            setPageInfo({
                currentList: currentList,
                originList: totalList,
                total: totalList.length,
                totalPage: totalPage,
                currentPage: currentPage,
            });
        }
    }

    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: Pagination.PAGE_SIZE,
        current: pageInfo.currentPage,
        showTotal: (total, _) => `共${total}条数据`,
        onChange: (page) => {
            paginationHandler(pageInfo.originList, page);
        }
    }

    // 删除模板库
    const handleDeleteTemplate = () => {
        setDialogInfo({ visible: true, confirmLoading: true, template: dialogInfo.template });
        const templateID = dialogInfo.template.template_id
        deleteTemplate(templateID).then(result => {
            const index = pageInfo.originList.findIndex(item => { return item.template_id === templateID });
            pageInfo.originList.splice(index, 1);

            paginationHandler(pageInfo.originList, pageInfo.currentPage);
            setDialogInfo({ visible: false, confirmLoading: false });

            message.success('删除成功');
        }).catch(ex => {
            // console.error(ex);
            setDialogInfo({ visible: false, confirmLoading: false });
            message.error('模板删除失败');
        });
    }


    const dialog = () => {
        const btnStyle = { backgroundColor: '#4F5CFF', color: 'white' };
        return <Modal
            title="信息"
            closable={false}
            visible={dialogInfo.visible}
            confirmLoading={dialogInfo.confirmLoading}
            footer={[
                <Button key="back" onClick={() => { setDialogInfo({ visible: false, confirmLoading: false }) }}>
                    取消
                </Button>,
                <Button key="submit" type="primary" style={btnStyle} loading={dialogInfo.confirmLoading} onClick={handleDeleteTemplate}>
                    确定
                </Button>,
            ]}
        >
            <p>{`是否删除该模板?`}</p>
        </Modal>
    }

    const renderName = (_, item) => {
        return <a style={{ fontSize: '16px', color: '#4F5CFF' }}>{item.source_miniprogram}</a>;
    }
    const tagStyle = { fontSize: '15px', border: '1px', padding: '5px' };
    const renderVersion = (_, item) => {
        return <Tag color='red' style={tagStyle}>{item.user_version}</Tag>;
    }

    return <div>
        <Table
            loading={isLoading}
            columns={Column.templateColumn(renderName, renderVersion, (text, item) => {
                return <div>
                    <Button type="text" danger icon={<DeleteFilled />} onClick={() => { setDialogInfo({ visible: true, template: item }) }}></Button>
                </div>
            })}
            dataSource={pageInfo.currentList}
            rowKey='template_id'
            pagination={pagination}
        />
        {dialogInfo.visible && dialog()}
    </div>
};

export default TemplatePage;