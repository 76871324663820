import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, Select, Modal, Space, Tooltip, Image, message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom';
import {IconFont} from '../../../utils/common'
import dongjie_icon from '../../../assets/images/dongjie_icon.png'
import edit_icon from '../../../assets/images/edit_icon.png'
import jiedong_icon from '../../../assets/images/jiedong_icon.jpg'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import delete_icon from '../../../assets/images/delete_icon.png'
import { getUserList, getRoleSelectGroup, saveUserStatus, delUser } from '../../../services/permissions'
import './index.scss'

const { Option } = Select;
const { Search } = Input

const PermissionsUserIndex = ()=>{

    const history = useHistory()
    const location = useLocation()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ typeList, setTypeList ] = useState([])
    const [paramsObj, setParamsObj] = useState({
        page: 1,
        page_size: 10,
        group_id: '0' ,
    })
    const statusList = ['正常', '冻结' ]

    useEffect(() => {
        getGroupList()
        if(location.state&&location.state.id) paramsObj.group_id = location.state.id
        getList({...paramsObj})
    }, [])

    const getGroupList = () => {
        getRoleSelectGroup().then((res)=>{
            setTypeList(res)
        })
    }

    const getList = async (data = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType==='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(el==='status'){
                if(param[el]===-1) delete param[el]
            }else{
                if(!param[el]) delete param[el]
            }
        })
        setParamsObj({...param})
        setLoading(true)
        getUserList(param).then((res)=>{
            setLoading(false)
            setData(res.data);
            setTotal(res.total)
        }).catch((error)=>{
            setLoading(false)
            message.error(error)
        })
    }
        
    const handleTableChange = (pagination, filters, sorter) => {
        getList({
            ...paramsObj,
            page:pagination.current,
            page_size:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const creatTable = ()=>{
            
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '姓名',
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: '手机号码',
                dataIndex: 'mobile',
                key: 'mobile',
            },
            {
                title: '权限分组',
                dataIndex: 'group_name',
                key: 'group_name',
            },
            {
                title: '状态',
                dataIndex: 'status',
                key: 'status',
                render: (text,record) => statusList[text]
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text, record, index )=>
                    <>
                        <Space>
                            <Tooltip placement="top" title={'编辑'}>
                                <Image src={edit_icon} className="action_icon" preview={false} onClick={()=>{salesmanEidt(record)}}></Image>
                            </Tooltip>
                            <Tooltip placement="top" title={record.operate_id!==0&&(record.status === 0 ? '冻结':'解冻')}>
                                {
                                    record.operate_id!==0&&<Image src={record.status === 0 ? dongjie_icon:jiedong_icon} className="action_icon"  preview={false} onClick={() => frozen(record)}></Image>
                                }
                            </Tooltip>                            
                            <Tooltip placement="top" title={record.operate_id!==0&&'删除'}>
                                {
                                    record.operate_id!==0&&<Image src={delete_icon} className="action_icon"  preview={false} onClick={() => deleteUser(record.id)}></Image>
                                }
                            </Tooltip>        
                        </Space>
                    </>
            },
        ];

        return (
            <Table 
                dataSource={data} 
                columns={columns} 
                onChange={handleTableChange}   
                rowKey={'id'}  
                loading={loading}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.page_size,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => `共${total}条`,
                }}   
            />
        )
    }

    const salesmanAdd = ()=>{
        history.push('/sidebar/permissions/user/add')
    }

    const salesmanEidt = (record)=>{
        history.push('/sidebar/permissions/user/add',{...record})
    }
    
    //冻结
    const frozen = (item)=>{
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: item.status === 1 ? '是否解冻该账号':'是否冻结该账号',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
              saveUserStatus({id: item.id,status: item.status === 0  ? 1:0}).then(res =>{
                message.success('操作成功')
                getList({...paramsObj})
              }).catch((error)=>{
                message.error(error||'操作失败')
            })
            }
        });
    }

    //删除
    const deleteUser = (id)=>{
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除该账号',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                delUser({id: id}).then(res =>{
                    message.success('删除成功')
                    getList({...paramsObj})
                }).catch((error)=>{
                    message.error(error||'删除失败')
                })
            }
        });
    }

    return(
        <div className="permissions_user_index">
            <Space>
                <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}}/>}  onClick={()=>salesmanAdd()}>新增</Button>
            </Space> 
            <div className="content_body">
                <Row type="flex" align="middle" className="search mb30">
                    <Search
                        placeholder="输入姓名、手机号搜索"
                        onSearch={value => {
                            getList({...paramsObj,page:1,keyword:value})
                        }}
                        style={{width:300}}
                    />
                    <span className="ml10 mr10">分组类型</span>
                    <Select value={paramsObj.group_id} className="mr10" style={{width:150}} onChange={(e)=>{getList({...paramsObj,page:1,group_id:e})}}>
                        <Option value={'0'}>全部</Option>
                        {
                            typeList.map((item,index)=>{
                                return <Option value={item.id} key={'typeList'+index}>{item.group_name}</Option>
                            })
                        }
                    </Select>                              
                    <span className="ml10 mr10">状态</span>
                    <Select defaultValue={-1} className="mr10" style={{width:150}} onChange={(e)=>{getList({...paramsObj,page:1,status:e})}}>
                        <Option value={-1}>全部</Option>
                        {
                            statusList.map((item,index)=>{
                                return <Option value={index} key={'statusList'+index}>{item}</Option>
                            })
                        }
                    </Select>                              
                </Row>
                {
                    creatTable()
                }
            </div>           
        </div>
    )
}

export default PermissionsUserIndex