//  草稿箱TableColumn定义
const draftColumn = (renderName, renderVersion, render) => {
    return [
        {
            title: '草稿ID',
            dataIndex: 'draft_id',
            key: 'draft_id',
        },
        {
            title: '来源小程序',
            dataIndex: 'source_miniprogram',
            key: 'source_miniprogram',
            render: renderName,
        },
        {
            title: '版本',
            dataIndex: 'user_version',
            key: 'user_version',
            render: renderVersion,
        },
        {
            title: '草稿描述',
            dataIndex: 'user_desc',
            key: 'user_desc',
        },
        {
            title: '上传开发者',
            dataIndex: 'developer',
            key: 'developer',
        },
        {
            title: '上传时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            render: render,
        },
    ];
};

// 模板TableColumn定义
const templateColumn = (renderName, renderVersion, render) => {
    return [
        {
            title: '模板ID',
            dataIndex: 'template_id',
            key: 'template_id',
        },
        {
            title: '来源小程序',
            dataIndex: 'source_miniprogram',
            key: 'source_miniprogram',
            render: renderName,
        },
        {
            title: '版本',
            dataIndex: 'user_version',
            key: 'user_version',
            render: renderVersion,
        },
        {
            title: '模板描述',
            dataIndex: 'user_desc',
            key: 'user_desc',
        },
        {
            title: '上传开发者',
            dataIndex: 'developer',
            key: 'developer',
        },
        {
            title: '添加时间',
            dataIndex: 'create_time',
            key: 'create_time',
        },
        {
            title: '操作',
            render: render,
        },
    ];
};

// 小程序Table Column定义
const miniProgramColumn = (renderName, renderVersion, renderCommitCode, renderAudit, renderAuditUsedNum,renderRelease,renderReleaseTime, renderOperate) => {
    return [
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: renderName,
        },
        {
            title: '版本号',
            dataIndex: 'version',
            key: 'version',
            render: renderVersion,
        },
        {
            title: '上传状态',
            dataIndex: 'commit_code_status',
            key: 'commit_code_status',
            render: renderCommitCode,
        },
        {
            title: '审核状态',
            dataIndex: 'submit_audit_status',
            key: 'submit_audit_status',
            render: renderAudit,
        },
        {
            title: '已提审次数',
            dataIndex: 'submit_audit_used_num',
            key: 'submit_audit_used_num',
            render:renderAuditUsedNum
        },
        {
            title: '发布状态',
            dataIndex: 'release_status',
            key: 'release_status',
            render: renderRelease,
        },
        {
            title: '发布时间',
            dataIndex: 'latest_release_time',
            key: 'latest_release_time',
            render: renderReleaseTime,
        },
        {
            title: '原因',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: '操作',
            render: renderOperate,
        },
    ];
};

export default {
    draftColumn,
    templateColumn,
    miniProgramColumn,
};
