
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './style.css';

/**
 * 密码输入框
 */
class InputPassword extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: '',
            curIndex: 0
        }
    }
    onInputChange(e) {
        this.setState({
            value: e.target.value
        })
    }
    onInputKeyUp(e) {
        const {size} = this.props
        let _value = e.target.value
        if (!(_value.length <= size)) {
            _value = _value.substring(0, size)
        }
        this.setState({
            value: _value
        })
        let _curIndex = -1
        if (_value.length <= (size - 1)){
            _curIndex = _value.length
        }
        this.setState({
            curIndex: _curIndex
        })
        this.refs['inputCore'].value = _value
        this.props.onChange(_value)
    }
    onClickWrap() {
        this.refs['inputCore'].focus()
    }

    render() {
        const {onInputChange, onInputKeyUp, onClickWrap} = this;
        const {size, styles} = this.props;
        const {value, curIndex} = this.state;
        let inputItems = [];
        let _value = JSON.parse(JSON.stringify(value)).split('');
        let wrapStyle = styles.wrapStyle || {
            width: `calc( (${55 * size}px) + ${size + 1}px)`,
        }
        const dotActiveStyle = styles.dotActiveStyle || {};

        for(let i = 0; i < size; i++) {
            inputItems.push(
            (
            <li key={i} style={dotActiveStyle} className={`show-dot ` + (curIndex === i ? 'active ' : '') + ''}>
                {_value[i] && <span className='dot-core'>{_value[i]}</span>}
            </li>
            ));
        }

        return (
            <ul className={'pwd-list'} style={wrapStyle} onClick={onClickWrap.bind(this)}>
                {inputItems}
                <li className='input-wrap'>
                    <input className="input-core" 
                        ref='inputCore'
                        autoFocus='autoFocus'
                        style={styles.input}
                        maxLength={size}
                        type='number'
                        placeholder=''
                        onKeyUp={onInputKeyUp.bind(this)} 
                        onChange={onInputChange.bind(this)}/>
                </li>
            </ul>
        )
    }
}

InputPassword.propTypes = {
    size: PropTypes.number,
    type: PropTypes.string,
    onChange: PropTypes.func,
    styles: PropTypes.object,
}

InputPassword.defaultProps = {
    size: 5,
    onChange: ()=>{},
    styles: {
        wrap: {},
        input: {},
        dotActiveStyle: {}
    }
}

export default InputPassword;