import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    Image,
    message,
    Spin,
    Radio,
    Button,
    Input,
    Select,
    Cascader,
    AutoComplete
} from 'antd'

import {shopApplyDetailRequest,storeAuthConfirmReview,shopAuth,getBaiduBusinessLicense,getBaiduIdcard,getBaiduBankcard,searchUnionpay, shopApplyEdit} from '../../../../services/merchantService'
import cityData from '../../../../utils/cityData';
import UploadImg from './uploadImg/index'

import './index.scss'

const { Option } = Select

const EditAuth = (props) =>{
    const history = useHistory()
    const [isLoading,setIsLoading] = useState(false)
    const [ bankList, setBankList ] = useState([]) //支行列表
    const [ manageTypeList, setManageTypeList ] = useState({
        '2': '个体工商户',
        '3': '企业'
    })

    const [ isEditList, setIsEditList ] = useState({
        //门店
        shop_name:true,
        city_arr:true, //所在省市
        address:true,
        manage_type:true,
        catering_type:true,
        service_phone:true,
        userWx:true,
        subAppid:true,
        //场景
        // shop_exterior_pic:true,
        // shop_inner_pic:true,
        // cashier_desk_pic:true,
        //经营
        // business_license:true,
        business_license_num:true,
        business_license_name:true,
        business_license_info_licenseAddress:true,
        business_license_info_licenseStart:true,
        business_license_info_licenseEnd:true,
        // food_license:true,
        //结算
        contact_name:true,
        contact_phone:true,
        // id_card_front:true,
        // id_card_reverse:true,
        hand_id_card:true,
        contact_id_card:true,
        id_card_info:true,
        // bank_front:true,
        contact_bank_card:true,
        contact_bank_name:true,
        contact_bank_branch_name:true,
        open_acount_name_bak:true, //(非法人结算字段)
        // authorization_paper_bak:true,//(非法人结算字段)
        // id_card_front_bak:true,//(非法人结算字段)
        // id_card_reverse_bak:true,//(非法人结算字段)
        id_card_number_bak:true,//(非法人结算字段)
    })

    const [shopInfo,setShopInfo] = useState({
        shop_name: '', // 门店名称
        province: '',
        city: '',
        district: '',
        province_code: '',
        city_code: '',
        district_code:'',
        shop_adress: '', // 地址  --> 手动处理
        address: '', //详细地址
        manage_type: '', // 经营类型 --> 手动处理
        catering_type: '', // 餐饮类型
        service_phone: '', // 门店客服联系方式
        userWx: '',
        subAppid: '',
        contact_name: '', // 联系人姓名
        contact_phone: '', // 联系人手机号
        id_card_front: '', // 身份证正面照片
        id_card_reverse: '', // 身份证反面照片
        hand_id_card: '', // 手持身份证照片
        contact_id_card: '', // 身份证号码
        bank_front: '', // 银行卡正面照片
        contact_bank_card: '', // 银行卡号
        contact_bank_name: '', // 银行卡名称
        contact_bank_branch_name: '', // 支行名称
        shop_exterior_pic: '', // 门店门头照片
        shop_inner_pic: '', // 店内环境照片
        cashier_desk_pic: '', //收银台照片
        business_license: '', // 营业执照照片
        business_license_num: '', // 营业执照号
        business_license_name: '', // 营业执照名称
        business_license_info:{},
        food_license: '', // 食品经营许可证
        base_info:'',
        id_card_info:'',
        business_license_info_licenseAddress:'',
        business_license_info_licenseStart:'',
        business_license_info_licenseEnd:'',
    })

    useEffect(() =>{
        if(history.location.state){
            getShopInfo(history.location.state.id)
        }else{
            history.goBack()
        }
    },[])

    // 获取 商户详情
    const getShopInfo = (id) =>{
        setIsLoading(true)
        shopApplyDetailRequest({id}).then(res =>{
            let id_card_info = res.id_card_info!=='null'&&res.id_card_info?JSON.parse(res.id_card_info):''
            setShopInfo({
                ...shopInfo,
                ...res,
                shop_adress: res.province + res.city + res.district,
                business_license_info:JSON.parse(res.business_license_info),
                business_license_info_licenseAddress:res.business_license_info!=='null'&&res.business_license_info?JSON.parse(res.business_license_info).licenseAddress:'',
                business_license_info_licenseStart:res.business_license_info!=='null'&&res.business_license_info?JSON.parse(res.business_license_info).licenseStart:'',
                business_license_info_licenseEnd:res.business_license_info!=='null'&&res.business_license_info?JSON.parse(res.business_license_info).licenseEnd:'',
                //经营类型. 1小微商户.   2 个体工商户.   3 企业.  4其他
                // manage_type: res.manage_type ===  1 ? '小微商户':
                //              res.manage_type ===  2 ? '个体工商户':
                //              res.manage_type ===  3 ? '企业': '其他',
                base_info:JSON.parse(res.base_info),
                id_card_info:id_card_info.idCardStart.replace(new RegExp('-','g'),'/')+'-'+id_card_info.idCardEnd.replace(new RegExp('-','g'),'/')
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    const eidtItem = (field) => {
        if(!isEditList[field]){
            let params = {
                id:history.location.state.id
            }
            switch(field){
                case 'contact_bank_branch_name':
                    params['contact_bank_branch_name'] =  shopInfo.contact_bank_branch_name
                    params['bank_card_info'] =  shopInfo.bank_card_info
                    break;
                case 'id_card_info':
                    params['id_card_info'] =  JSON.stringify({
                        idCardStart: shopInfo.id_card_info.split('-')[0].replace(new RegExp('/','g'),'-'), 
                        idCardEnd: shopInfo.id_card_info.split('-')[1].replace(new RegExp('/','g'),'-')
                    })
                    break;
                case 'business_license_info_licenseAddress':
                case 'business_license_info_licenseStart':
                case 'business_license_info_licenseEnd':
                    params['business_license_info'] =  JSON.stringify({
                        licenseStart: shopInfo.business_license_info_licenseStart, 
                        licenseEnd: shopInfo.business_license_info_licenseEnd, 
                        licenseAddress: shopInfo.business_license_info_licenseAddress
                    })
                    break;
                case 'city_arr':
                    params.province= shopInfo.province
                    params.city= shopInfo.city
                    params.district= shopInfo.district
                    params.province_code= shopInfo.province_code
                    params.city_code= shopInfo.city_code
                    params.district_code= shopInfo.district_code
                    break;
                default:
                    params[field] = shopInfo[field]
            }
            //提交编辑信息
            shopApplyEdit(params).then((res)=>{
                message.success('修改成功')
            }).catch(error=>{
                message.error(error||'修改失败')
            })
        }
        let isEditListTemp = JSON.parse(JSON.stringify(isEditList))
        isEditListTemp[field] = !isEditListTemp[field]
        setIsEditList({
            ...isEditListTemp,
        })
    }

    const createViewForm = (title,value,field,tip='') => {
        return  <div className="view_form">
            <div className="form_item_title">
                {title} :
            </div>
            <Input disabled={isEditList[field]} className="form_item_input" value={value} onChange={(e)=>{changeFieldVal(e.currentTarget.value,field)}}/>  
            <Button type="primary" onClick={()=>{eidtItem(field)}}>{isEditList[field]?'编辑':'保存'}</Button>  
            {tip?tip:''}           
        </div>
    }

    const createUploadImg = (title,value,field,callBack=()=>{}) => {
        return  <div className="view_form">
            <div className="form_item_title">
                {title} :
            </div>
            <div className="form_item_value">
                    <UploadImg
                        onChange={(e)=>{changeFieldVal(e,field)}}
                        imgUrl={value}
                        // callBack={(e,imgUrl)=>{uploadCallBack(field,imgUrl,e)}}
                        callBack={(e,imgUrl)=>{uploadCallBack(field,imgUrl,e)}}
                        field={field}
                    />            
            </div>
            
        </div>
    }
    
    const uploadCallBack = (field,imgUrl,e) => {
        console.log('----uploadCallBack---')
        console.log(field)
        console.log(imgUrl)
        let params = {
            id:history.location.state.id
        }
        params[field] = imgUrl
        //提交编辑信息
        shopApplyEdit(params).then((res)=>{
            message.success('修改成功')
        }).catch(error=>{
            message.error(error||'修改失败')
        })
    }

    const changeFieldVal = (e, field) => {
        console.log('changeFieldVal----')
        console.log(e)
        console.log(field)
        let fieldObj = { [field]: e }
        if(field==='cityArr'){
            fieldObj = {
                province:e[0]?e[0].name:'',
                city:e[1]?e[1].name:'',
                district:e[2]?e[2].name:'',
                province_code:e[0]?e[0].code:'',
                city_code:e[1]?e[1].code:'',
                district_code:e[2]?e[2].code:'',
            }
        }
        console.log('shopInfo----------',shopInfo)
        setShopInfo({
            ...shopInfo,
            ...fieldObj
        })
    }
    //营业执照识别（备用）
    const getBaiduBusinessLicense = async(field,imgUrl,base64) => {      
        console.log('------getBaiduBusinessLicense---')
        console.log(field)
        console.log(imgUrl)
    }
    //身份证正面识别（备用）
    const getBaiduIdcardFront = async(field,imgUrl,base64) => {      
        console.log('------getBaiduIdcardFront---')
        console.log(field)
        console.log(imgUrl)
    }
    //身份证反面识别（备用）
    const getBaiduIdcardBack = async(field,imgUrl,base64) => {      
        console.log('------getBaiduIdcardBack---')
        console.log(field)
        console.log(imgUrl)
    }
    //非法人身份证正面识别（备用）
    const getBaiduIdcardFrontBak = async(field,imgUrl,base64) => {      
        console.log('------getBaiduIdcardFrontBak---')
        console.log(field)
        console.log(imgUrl)
    }
    //银行卡正面识别（备用）
    const getBaiduBankcard = async(field,imgUrl,base64) => {      
        console.log('------getBaiduBankcard---')
        console.log(field)
        console.log(imgUrl)
    }

    //级联值重置格式
    const displayRender = (labels, selectedOptions) =>
        labels.map((label, i) => {
            const option = selectedOptions[i];
            if (i === labels.length - 1) {
                return option&&(
                <span key={option.code}>
                    {label}
                </span>
                );
            }
            return option&&<span key={option.code}>{label}  </span>;
        });

    let timer
    //获取支行列表
    const getBankList = (e) => {
        if(!e){
            setBankList([])
            return false
        }
        timer && clearTimeout(timer)
        //500毫秒后请求
        timer = setTimeout(async()=>{
            searchUnionpay({name: e }).then(res=>{
                setBankList(res.data)
            })
        },500)
    }

    //选择支行
    const onSelectBranch = (e, option, field ) => {
        let fieldObj = { [field]: e }
        fieldObj.bank_card_info = JSON.stringify({
            sub_bank_code:option.key,
            sub_bank_name:e
        })
        setShopInfo({
            ...shopInfo,
            ...fieldObj
        })
    }
    
    return <Spin spinning={isLoading}>
        <div className="auth_edit">
        <div className="detail_auth">
            <h4>门店信息</h4>
            {createViewForm('门店名称',shopInfo.shop_name,'shop_name')}
            <div className="view_form">
                <div className="form_item_title">
                所在省市 :
                </div>
                <Cascader 
                    value={[shopInfo.province_code, shopInfo.city_code, shopInfo.district_code]} 
                    displayRender={displayRender} 
                    options={cityData} 
                    placeholder="请选择" 
                    // style={{ width: 400 }} 
                    className="form_item_cascader"
                    onChange={(e,selectedOptions)=>{changeFieldVal(selectedOptions,'cityArr')}} 
                    fieldNames={{ label: 'name', value: 'code', children: 'children' }}
                    disabled={isEditList['city_arr']}
                />
                <Button type="primary" onClick={()=>{eidtItem('city_arr')}}>{isEditList['city_arr']?'编辑':'保存'}</Button>         
            </div>
            {createViewForm('门店地址',shopInfo.address,'address')}
            <div className="view_form">
                <div className="form_item_title">
                    经营类型 :
                </div>
                <Select value={shopInfo.manage_type+''} className="form_item_select" onChange={(e)=>{changeFieldVal(e,'manage_type')}} disabled={isEditList['manage_type']}>
                    {
                        Object.keys(manageTypeList).map((item)=>{
                            return <Option value={item+''} key={item}>{manageTypeList[item]}</Option>
                        })
                    }
                </Select>
                <Button type="primary" onClick={()=>{eidtItem('manage_type')}}>{isEditList['manage_type']?'编辑':'保存'}</Button>          
            </div>            
            {createViewForm('餐饮类型',shopInfo.catering_type,'catering_type')}
            {createViewForm('门店客服联系方式',shopInfo.service_phone,'service_phone')}
            {createViewForm('微信号',shopInfo.userWx,'userWx')}
            {createViewForm('小程序appid',shopInfo.subAppid,'subAppid')}
        </div>
        <div className="detail_auth">
            <h4>场景信息</h4>
            {createUploadImg('门店门头照片',shopInfo.shop_exterior_pic,'shop_exterior_pic')}
            {createUploadImg('店内环境照片',shopInfo.shop_inner_pic,'shop_inner_pic')}
            {createUploadImg('收银台照片',shopInfo.cashier_desk_pic,'cashier_desk_pic')}
        </div>
        <div className="detail_auth">
            <h4>经营信息</h4>
            {createUploadImg('营业执照照片',shopInfo.business_license,'business_license',getBaiduBusinessLicense)}
            {createViewForm('营业执照号',shopInfo.business_license_num,'business_license_num')}
            {createViewForm('营业执照名称',shopInfo.business_license_name,'business_license_name')}
            {createViewForm('经营场所',shopInfo.business_license_info_licenseAddress,'business_license_info_licenseAddress')}
            {createViewForm('注册日期',shopInfo.business_license_info_licenseStart,'business_license_info_licenseStart','（格式：2020-02-20）')}
            {createViewForm('有效期',shopInfo.business_license_info_licenseEnd,'business_license_info_licenseEnd','（格式：2020-02-20或者长期）')}
            {createUploadImg('食品经营许可证',shopInfo.food_license,'food_license')}
        </div>
        <div className="detail_auth">
            <h4>结算信息</h4>
            {createViewForm('法人姓名',shopInfo.contact_name,'contact_name')}
            {createViewForm('法人手机号',shopInfo.contact_phone,'contact_phone')}
            {createUploadImg('法人身份证正面照片',shopInfo.id_card_front,'id_card_front',getBaiduIdcardFront)}
            {createUploadImg('法人身份证反面照片',shopInfo.id_card_reverse,'id_card_reverse',getBaiduIdcardBack)}
            {shopInfo.hand_id_card&&createUploadImg('法人手持身份证照片',shopInfo.hand_id_card,'hand_id_card')}
            {createViewForm('法人身份证号码',shopInfo.contact_id_card,'contact_id_card')}
            {createViewForm('法人身份证有效期',shopInfo.id_card_info,'id_card_info','（格式：2015/04/24-2035/04/24）')}

            {shopInfo.account_type===0&&createViewForm('结算人姓名',shopInfo.open_acount_name_bak,'open_acount_name_bak')}
            {shopInfo.account_type===0&&createUploadImg('结算授权书',shopInfo.authorization_paper_bak,'authorization_paper_bak')}

            {createUploadImg(shopInfo.account_type===0?'结算银行卡正面照片':'银行卡正面照片',shopInfo.bank_front,'bank_front',getBaiduBankcard)}
            {createViewForm(shopInfo.account_type===0?'结算银行卡号':'银行卡号',shopInfo.contact_bank_card,'contact_bank_card')}
            {createViewForm(shopInfo.account_type===0?'结算银行卡名称':'银行卡名称',shopInfo.contact_bank_name,'contact_bank_name')}
            {/* {createViewForm(shopInfo.account_type===0?'结算支行名称':'支行名称',shopInfo.contact_bank_branch_name,'contact_bank_branch_name')} */}
            <div className="view_form">
                <div className="form_item_title">
                    {shopInfo.account_type===0?'结算支行名称':'支行名称'} :
                </div>
                {
                    shopInfo.contact_bank_branch_name&&
                    <AutoComplete
                        placeholder="请输入支行名称"
                        defaultValue={shopInfo.contact_bank_branch_name}
                        onChange={(e) => getBankList(e)}
                        onSelect={(e, option) => onSelectBranch(e, option, 'contact_bank_branch_name')}
                        className="form_item_autoComplete"
                        disabled={isEditList['contact_bank_branch_name']}
                    >
                        {
                            bankList.map((subItem,index)=>{
                                return <AutoComplete.Option key={subItem.sub_bank_code} value={subItem.sub_bank_name}>{subItem.sub_bank_name}</AutoComplete.Option>
                            })
                        }
                    </AutoComplete>
                }
                <Button type="primary" onClick={()=>{eidtItem('contact_bank_branch_name')}}>{isEditList['contact_bank_branch_name']?'编辑':'保存'}</Button>     
            </div> 
            {shopInfo.account_type===0&&createUploadImg('结算人身份证正面',shopInfo.id_card_front_bak,'id_card_front_bak',getBaiduIdcardFrontBak)}
            {shopInfo.account_type===0&&createUploadImg('结算人身份证反面',shopInfo.id_card_reverse_bak,'id_card_reverse_bak')}
            {shopInfo.account_type===0&&createViewForm('结算人身份证号码',shopInfo.id_card_number_bak,'id_card_number_bak')}
        </div>
    </div>
    </Spin> 
}

export default EditAuth