import React, { useState, useEffect } from 'react';
import { Image, Button, Modal, List, Spin, message } from 'antd'
import { findCircuitNodeStore, updateNode } from '../../../services/task'
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import task_icon from '../../../assets/images/task_icon.png'
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import './workList.scss'

const ManageList = (props) => {

	const history = useHistory()
	const { id, initData, callBack, pIndex, total, listLength, list } = props
	const [ loading, setLoading ] = useState(false)
	const [ hasMore, setHasMore ] = useState(true)
  const [ data, setData ] = useState([])
	const [ paramsObj, setParamsObj ] = useState({})
	
  useEffect(() => {
    _init()
    //以下注释可以屏蔽依赖警告
    // eslint-disable-next-line react-hooks/exhaustive-deps      
	}, [total])
  
  const _init = () => {
    setParamsObj({
      id:id,
      page:2,
      page_size:15
    })
	  let tempData = JSON.parse(JSON.stringify(initData))
    setData(tempData)
  }
  
  const loadMore = () => {
		setLoading(true)
    findCircuitNodeStore(paramsObj).then(res=>{
      if(res.data.length === 0){
        message.warning('没有更多数据了');
        setLoading(false)
        setHasMore(false)        
        return false
      }
      let dataTemp = data.concat(res.data)
      setParamsObj({...paramsObj,page:paramsObj.page+1})
      setData(dataTemp)
      setLoading(false)
    }).catch(err=>{
      setLoading(false)
      message.error(err||'请求异常');
      console.log(err)
    })
	};
	
	const goDetail = (id) => {
		history.push('/sidebar/task/detail/index',{id:id})
	}

	//更新流程节点（驳回/确认完成）
	const updateNodeFunc = (e,item,type) => {
      e.stopPropagation()
      if(type===1&&item.finished_problem_total<item.problem_total){
        message.error('请先完成任务填写')
        return false
      }
      //查找临近的上下节点
      let index = list.findIndex(item=>id==item.id)
      let next_id
      let prev_id
      if(type===1){
        next_id = list[index+1].id
      }else{
        prev_id = list[index-1].id
      }
      Modal.confirm({
          title: '操作提醒',
          icon: <ExclamationCircleOutlined />,
          content: type===1?'是否确认完成':'是否确定驳回',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
						updateNode({apply_id:item.id,circuit_node_id:type===1?next_id:prev_id}).then((res)=>{
									message.success(type===1?'确认成功':'驳回成功')
									callBack&&callBack()
              }).catch((error)=>{
                  message.error(error||(type===1?'确认失败':'驳回失败'))
              })
          }
      });   		
	}

  return (
		<div className="task_sub_list">
			<InfiniteScroll
					initialLoad={false}
					pageStart={0}
					loadMore={loadMore}
					hasMore={!loading && hasMore}
					useWindow={false}
					className="task_sub_list_scroll"
					key={Math.random().toString(36).substr(2)}
			>
			<List
					dataSource={data}
					renderItem={(item,index) => (
						<div className="task_sub_item">
							<div className="task_sub_item_content" onClick={(e)=>{goDetail(item.id)}}>
								{/* <Image src={item.shop_exterior_pic} className="task_sub_item_left"/> */}
								<img src={item.shop_exterior_pic+'?imageView2/2/w/100'} className="task_sub_item_left" />
								<div className="task_sub_item_center">
									<div className="task_sub_item_center_name">{item.shop_name}</div>
									<div className="task_sub_item_center_role">
										<div className="task_sub_item_center_role_left">业务员 ：</div>
										<div className="task_sub_item_center_role_center"><div>{item.salesman_name}</div></div>
									</div>
									<div className="task_sub_item_center_role">
										<div className="task_sub_item_center_role_left">客服 ：</div>
										<div className="task_sub_item_center_role_center">
											{
													item.responsibles&&item.responsibles.findIndex((item)=>item.role===1)!==-1?(
															item.responsibles.map((pItem,pIndex)=>{
																	return pItem.role===1&&(pItem.items.length===0?<div key={pIndex}>未分配</div> :pItem.items.map((item,index)=>{
																			return <div key={'service'+pIndex+'-'+index}>{item.name}</div>
																	}))
															})
													):<div>未分配</div>                    
											}                                      
										</div>
									</div>
									<div className="task_sub_item_center_role">
										<div className="task_sub_item_center_role_left">技术IT ：</div>
										<div className="task_sub_item_center_role_center">
											{
													item.responsibles&&item.responsibles.findIndex((item)=>item.role===2)!==-1?(
															item.responsibles.map((pItem,pIndex)=>{
																	return pItem.role===2&&(pItem.items.length===0?<div key={pIndex}>未分配</div> :pItem.items.map((item,index)=>{
																			return <div key={'it_'+pIndex+'-'+index}>{item.name}</div>
																	}))
															})
													):<div>未分配</div>                    
											}                                      
										</div>
									</div>
									<div className="task_num"><Image src={task_icon}/>{item.finished_problem_total}/{item.problem_total}</div>
								</div>
								{
									item.circuit_node_reject&&(
										<div className="task_sub_item_status">
											<span>被驳回</span>
										</div>
									)
								}
							</div>
							<div className="task_sub_item_footer">
								{
									(listLength-3)>=pIndex&&pIndex!==0&&<Button onClick={(e)=>{updateNodeFunc(e,item,2)}}>驳回</Button>
								}
								{
									(listLength-2)>=pIndex&&<Button className="btn_r" onClick={(e)=>{updateNodeFunc(e,item,1)}}>确认完成</Button>
								}
							</div>
						</div>
        
					)}
			>
					{loading && hasMore && (
							<div className="list_loading">
									<Spin />
							</div>
					)}
			</List>
			</InfiniteScroll>		
		</div>
	)
}
export default ManageList