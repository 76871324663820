global.FILE_HOST = 'https://ydn-siyu.cdn.shuachi.com/'

const urls = {
    dev: 'https://debug-siyu.shuachi.com',
    prod: 'https://siyu.shuachi.com',
    test: 'http://150.158.6.92',
}
  //环境导出
const env = 'prod'
// // const env = 'dev'
// const env = 'test';


// const server_host = `${urls[env]}/siyusuperadmin`
const server_host = `${urls[env]}/siyusuperadmin`

const FILE_HOST = 'https://ydn-siyu.cdn.shuachi.com/'
const QINIU_SERVER = "https://up-z2.qiniup.com";  // 七牛上传地址

export {
    env,
    server_host,
    FILE_HOST,
    QINIU_SERVER
}
  