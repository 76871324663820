import axios from 'axios'
import { message } from 'antd';
import { server_host } from './config';
import { getLocalStorage, TokenKey, removeToken } from '../utils/storage'

const http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 15000,
  headers: {
    "Accept": 'application/json',
    // 'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json;charset=UTF-8',
  }
});


http.interceptors.request.use(function (config) {
  // 添加头部信息等 --config.headers.Authorization = `Bearer ` + token、
  const token = getLocalStorage(TokenKey)
  if(token){
    config.headers.Authorization =`Bearer ` +  token
  }
  return config;
}, (error) => Promise.reject(error));


http.interceptors.response.use(
  response => {
    const { status, data } = response;
    if (Object.is(status, 200)) {
      const { code } = data;
      switch (code) {
        case 1:
          return Promise.resolve(data);
        case 304:
          // 请求过期,跳转到首页
            window.location.href = '/';
          break;
        case 401:
          // 没有权限
            window.location.href = '/error/401';
          break;
        case 403:
          message.error(`登录已过期，请重新登录！`);
          setTimeout(() => {
            //登录过期清除缓存
            removeToken()
            window.location.href = '/login';
          }, 1000);
          break;
        case 404:
          return Promise.reject('请求错误：Request Not Find！');
        case 500:
          return Promise.reject('请求错误：' + data.message);
        default:
          return Promise.reject(data.message);
      }
    } else {
      return Promise.reject(data.message?data.message:new Error());
    }
  },
  error => {
    console.log('请求状态码1：', error.response);
    if (error.response&&error.response.status === 403) {
      message.error(`登录已过期，请重新登录！`);
      setTimeout(() => {
        removeToken()
        window.location.href = '/login';
      }, 1000);
      return Promise.reject('请求错误：' + error);
    }
    if (error.response&&error.response.status === 404) {
      // message.error(`请求错误：`+error.response.data&&error.response.data.message?error.response.data.message:error.response.statusText);
      return Promise.reject(error.response.data&&error.response.data.message?error.response.data.message:error.response.statusText)
    }

    if(error.response&&error.response.status === 422) {
      // message.error(`请求错误：`+error.response.data&&error.response.data.message?error.response.data.message:error.response.statusText);
      return Promise.reject(error.response.data&&error.response.data.message?error.response.data.message:error.response.statusText)
    }
    if(error.response){
      return Promise.reject(error.response.data&&error.response.data.message?error.response.data.message:error.response.statusText)
    }else{
      return Promise.reject('服务器请求异常')
    }
  }
);

const request = ({url, params = {}, method='get',useForm = true}) => {
  // const gid = getLocalStorage('userGid')
  // const userInfo = getLocalStorage('userInfo')
  // if(gid) params.group_id = gid
  // if(userInfo) params.user_id = userInfo.id
  switch(method){
    case 'get':
          return getRequest({url, params});
    case 'post':
          return useForm?postRequest({url, params}):requestWithJson(method,url,params);
    case 'put':
          return useForm?putRequest({url, params}):requestWithJson(method,url,params);
    case 'delete':
          return useForm?delRequest({url, params}):requestWithJson(method,url,params);
    }
}

const getRequest = ({url, params = {}}) => {
 return new Promise((resolve, reject) =>{
   http.get(server_host+url, {
     params: params,
   })
   .then(res => {
     resolve(res.data);
   })
   .catch(err => {
    console.log('请求错误', err);
     reject(err)
   })
 })
}

const postRequest = ({url, params = {}}) => {
  return new Promise((resolve, reject) => {
    http.post(server_host+url, params)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      // reject(err.data)
      console.log('请求错误', err);
      reject(err);
    })
  })
}

const putRequest = ({url, params = {}}) => {
  return new Promise((resolve, reject) => {
    http.put(server_host+url, params)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      // reject(err.data)
      console.log('请求错误', err);
      reject(err);
    })
  })
}

const delRequest = ({url, params = {}}) => {
  return new Promise((resolve, reject) => {
    http.delete(server_host+url, params)
    .then(res => {
      resolve(res.data);
    })
    .catch(err => {
      // reject(err.data)
      console.log('请求错误', err);
      reject(err);
    })
  })
}


// json 扩展 post,delete,put 
const requestWithJson= (method,url, params = {})=> {
    return new Promise((resolve, reject) => {
        http({method:method,url:server_host+url,data:params}).then(res => {
            resolve(res.data);
        }).catch(err => {
            console.log('请求错误', err);
            reject(err);
        })
    })
}



export { request, postRequest, getRequest, putRequest, delRequest, http }