import React from 'react';
import { Router, Route, Switch, Redirect} from "react-router-dom";
import { createBrowserHistory } from "history";
import Main from './pages/main'
import Login from './pages/login'
import { getLocalStorage, TokenKey } from './utils/storage'

import './App.css';

const history = createBrowserHistory();

const App = () => {
  // return <Router history={history}>
  //   <Switch>
  //     <Route path="/" exact component={Main}/>
  //     <Route path="/login" exact component={Login}/>
  //     <Route path="/siyu_admin/:name" exact component={Main} />
  //   </Switch>
  // </Router>

  //查询token是否存在
  const getToken = (type)=>{
    const token = getLocalStorage(TokenKey)
    if (!token) {
      return <Redirect to="/login" />;
    } else {
      return <Main />;
    }
  }

  //查询token是否存在
  const login = ()=>{
    const token = getLocalStorage(TokenKey)
    if (!token) {
      return <Login />;
    } else {
      return <Redirect to="/" />;
    }
  }

  return <Router history={history}>
    <Switch>
      <Route path="/login" exact render={login} />
      <Route 
        path="/" 
        render={getToken}
      />
    </Switch>
  </Router>

}

export default App;
