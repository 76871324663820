import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    message,
    Modal,
    Select,
    Table,
    Tag
} from 'antd';
import moment from 'moment';
import InputPassword from '../components/InputPassword';
import { QrcodeOutlined } from '@ant-design/icons';
import Column from '../column';
import Pagination from '../pagination';
import Converter from '../converter';
import Filter from '../filter';
import { stores } from '../../../stores/store'
import { fetchMiniProgram, fetchExperienceCode, fetchTemplates, batchCommitCode, batchSubmitAudit, undoAudit, batchRelease, batchRevertRelease } from '../../../services/miniProgramService';
import './MiniProgramsPage.scss';

/**
 * 小程序列表页面
 */
const MiniProgramsPage = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState({
        totalPage: 0, // 总页数
        total: 0,  // 总条数
        currentPage: 1,  // 当前显示页
        currentList: [],  // 当前显示的分页数据
        serverTimeStamp:'' // 服务器时间
    });
    // 当月提审限额
    const [submitAuditQuotaInfo,setSubmitAuditQuotaInfo] = useState({
        limit:0,
        used:0
    })
    // 多选
    const [selectedInfo, setSelectedRowKeys] = useState({
        selectedRowKeys: [],
        selectedRows: [],
    });

    // 通用对话框
    const [dialogInfo, setDialogInfo] = useState({
        visible: false,  // 显示状态
        miniProgramList: [],  // 当前操作的数据集合
        prompting: '',  // 提示
        onOkCallback: () => { }  // 回调函数
    });

    // 上传代码对话框
    const [commitDialogInfo, setCommitDialogInfo] = useState({
        visible: false,
        miniProgramList: [],  // 当前操作的数据集合
        templateList: [],
        callbackWith: () => { }  // 回调函数
    })
    const [buttonLoading, setButtonLoading] = useState({
        btnLoading: false,
        confirmLoading: false // 加载进度条
    })

    // 用户选择模板以及输入版本号
    const [templateInfo, setTemplateInfo] = useState({
        template: {}
    })

    const SUBMIT_AUDIT_PASSWD_SIZE = 6;
    const [submitAuditPasswd,setSubmitAuditPasswd] = useState({
        passwd:''
    })
    const [inputPasswordInfo, setInputPasswdDialog] = useState({
        visible: false,  // 是否展示输入框
        onCallback: (passwd) => { }  // 回调函数
    })

    const btnStyle = { marginLeft: '8px', backgroundColor: '#4F5CFF', color: 'white' };
    const batchBtnStyle = { marginRight: '24px', color: '#4F5CFF', width: '120px' };
    const tagStyle = { fontSize: '16px', border: '1px' };

    useEffect(() => {
        initData(pageInfo.currentPage, Pagination.PAGE_SIZE);
    }, []);

    const initData = (page, psize) => {
        setIsLoading(true);
        fetchMiniProgram(page, psize).then((result) => {
            setIsLoading(false);
            const quota = {
                limit:result.quota_limit,
                used:result.quota_used
            }
            setSubmitAuditQuotaInfo(quota);
            stores.userStore.setMpQuota(quota);
            paginationHandler(page,result.mini_program_list, result.total,result.timestamp);
        }).catch((ex) => {
            console.error(ex);
            setIsLoading(false);
        });
    }
    const lazyLoadTemplate = (callback) => {
        fetchTemplates().then((result) => {
            if (result.template_list.length == 0) {
                message.success('暂无模板可选');
                return;
            }
            callback(Pagination.sortByCreateTime(result.template_list));
        }).catch((ex) => {
            message.error('获取模板失败');
            console.error(ex);
        });
    }

    const paginationHandler = (currentPage,currentList, total,timestamp) => {
        if (currentList.length == 0) {
            message.info('暂无更多数据');
            return;
        }
        const totalPage = (total % Pagination.PAGE_SIZE) > 0 ? (total / Pagination.PAGE_SIZE + 1) : total / Pagination.PAGE_SIZE;
        setPageInfo({
            currentList: currentList,
            total: total,
            currentPage: currentPage,
            totalPage: totalPage,
            serverTimeStamp:timestamp
        });
    }
    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: Pagination.PAGE_SIZE,
        current: pageInfo.currentPage,
        showTotal: (total, _) => `共${total}条数据`,
        onChange: (page) => {
            initData(page, Pagination.PAGE_SIZE);
        }
    }

    // 组件自带数据选中/取消回调函数调用
    const handleRowOnChange = (selectedRowKeys, selectedRows) => {
        setSelectedRowKeys({ selectedRowKeys: selectedRowKeys, selectedRows: selectedRows });
    }

    // 上传代码
    const handleUploadCoding = (list = []) => {
        const next = (template) => {
            setButtonLoading({ confirmLoading: true });  // 取消加载进度条
            batchCommitCode(Converter.commitCodeRequest(template, list)).then(result => {
                message.success('上传成功');
                const newList = Converter.commitCodeResponse(result.commit_list, pageInfo.currentList);
                paginationHandler(pageInfo.currentPage,newList, pageInfo.total,pageInfo.serverTimeStamp);
            }).catch(ex => {
                console.error(ex);
                message.error('上传失败');
            }).finally(() => {
                setSelectedRowKeys({ selectedRowKeys: [], selectedRows: [] }); // 取消选中数据
                setCommitDialogInfo({ visible: false });  // 关闭上传对话框
                setButtonLoading({ confirmLoading: false });  // 取消加载进度条
                setTemplateInfo({ template: {} });
            })
        }
        lazyLoadTemplate((templateList) => {
            setCommitDialogInfo({
                visible: true,  // 显示状态
                miniProgramList: list,  // 当前操作的数据集合
                templateList: templateList,
                callbackWith: next
            });
        });
    }

    // 体验码
    const handleExperienceQRCode = (item) => {
        const content = (url) => {
            return <div style={{ textAlign: 'center' }}>
                <img src={url} style={{ width: 200, height: 200 }} />
            </div>
        }
        setButtonLoading({ btnLoading: true });
        fetchExperienceCode(item.appid).then(result => {
            setButtonLoading({ btnLoading: false });
            Modal.info({
                okText: '确认',
                icon: '',
                title: `${item.name ? item.name : ""} 体验二维码`,
                centered: true,
                className: 'experience_code',
                content: content(result.qrcode_url)
            });
        }).catch(ex => {
            console.error(ex);
            setButtonLoading({ btnLoading: false });
            message.error('获取体验码失败');
        });
    }
    // 提交审核
    const handleSubmitAudit = (selectedList = []) => {
        const list = Filter.submitAuditFilter(selectedList);
        if (list.length === 0) {
            message.info('暂无需要提审的小程序版本');
            return;
        }
        if (submitAuditQuotaInfo.limit - submitAuditQuotaInfo.used < list.length){
            message.info('本月提审次数已不足');
            return;
        }
        if(Filter.isMustInputPasswdOfAudit(pageInfo.serverTimeStamp,list)){
            setInputPasswdDialog({
                visible:true,
                onCallback:(passwd)=>{auditNextStep(list,passwd);}
            });
            return;
        }
        auditNextStep(list);
    }

    const auditNextStep = (list,passwd = '') => {
        setInputPasswdDialog({visible:false});
        showPromptingDialog(`是否要提交审核?`, list, () => {
            return new Promise((resolve, reject) => {
                batchSubmitAudit(Converter.submitAuditRequest(list),passwd).then(result => {
                    stores.userStore.setMpQuota({
                        limit:submitAuditQuotaInfo.limit,
                        used:submitAuditQuotaInfo.used+result.quota_reduce
                    });
                    resolve(Converter.submitAuditResponse(result.audit_list, pageInfo.currentList));
                }).catch(ex => {
                    message.error(ex);
                    reject(ex);
                });
            })
        });
    }
    

    // 撤销
    const handleUndoAudit = (selectedList = []) => {
        const list = Filter.undoAuditFilter(selectedList);
        if (list.length === 0) {
            message.info('暂无可撤销的小程序审核版本');
            return;
        }
        const item = list[0]; // 目前只需要支持单个撤销
        showPromptingDialog('每天仅能撤回一次,是否需要撤销审核?', list, () => {
            return new Promise((resolve, reject) => {
                undoAudit(item.id, item.appid).then(result => {
                    message.success('撤销成功');
                    resolve(Converter.undoAuditResponse(item.appid, pageInfo.currentList));
                }).catch(ex => {
                    message.error(ex);
                    reject(ex);
                });
            })
        });
    }
    // 发布版本
    const handleRelease = (selectedList = []) => {
        const list = Filter.releaseFilter(selectedList);
        if (list.length === 0) {
            message.info('暂无可发布的小程序版本');
            return;
        }
        showPromptingDialog('是否要发布版本?', list, () => {
            return new Promise((resolve, reject) => {
                batchRelease(Converter.releaseRequest(list)).then(result => {
                    resolve(Converter.releaseResponse(result.release_list, pageInfo.currentList));
                }).catch(ex => {
                    reject(ex);
                });
            })
        });
    }

    // 线上版本回滚
    const handleRevertRelease = (selectedList = []) => {
        if (!Filter.enableRevertReleaseAction(selectedList[0])) {
            message.info('暂无可回退的小程序线上版本');
            return;
        }
        showPromptingDialog(`是否确认回滚至上一版本(${selectedList[0].previous_release_version})`, selectedList, () => {
            return new Promise((resolve, reject) => {
                batchRevertRelease(Converter.revertReleaseRequest(selectedList)).then(result => {
                    resolve(Converter.revertReleaseResponse(result.revert_release_list, pageInfo.currentList));
                }).catch(ex => {
                    reject(ex);
                });
            })
        });
    }

    const showPromptingDialog = (prompting, list, callback) => {
        setDialogInfo({
            visible: true,  // 显示状态
            miniProgramList: list,  // 当前操作的数据集合
            prompting: prompting,  // 提示
            onOkCallback: () => {  // 回调函数
                callback().then((result) => {
                    message.success('操作已完成');
                    paginationHandler(pageInfo.currentPage,result, pageInfo.total,pageInfo.serverTimeStamp);
                }).finally(() => { // 统一处理关闭对话框以及清除数据
                    setSelectedRowKeys({ selectedRowKeys: [], selectedRows: [] });
                    setButtonLoading({ confirmLoading: false })
                    setDialogInfo({ visible: false })
                });
            }
        })
    }

    // 适用于提交审核，撤销,发布,回滚
    const commonDialog = () => {
        return <Modal
            title="信息"
            closable={false}
            visible={dialogInfo.visible}
            footer={[
                <Button key='cancel' onClick={() => { setButtonLoading({ confirmLoading: false }); setDialogInfo({ visible: false }); }}>取消</Button>,
                <Button key='submit' style={{ backgroundColor: '#4F5CFF', color: 'white' }} loading={buttonLoading.confirmLoading} onClick={() => { setButtonLoading({ confirmLoading: true }); dialogInfo.onOkCallback() }}>
                    确认
                </Button>,
            ]}
        >
            <p>{dialogInfo.prompting}</p>
        </Modal>
    }
    // 选择模板
    const handleSelectedTemplate = (index) => {
        setTemplateInfo({
            template: commitDialogInfo.templateList[index]
        })
    }
    // 版本输入
    const handleInputOnChange = (e) => {
        const template = Object.assign(templateInfo.template, { user_version: e.target.value });
        setTemplateInfo({
            template: template
        })
    }

    // 上传代码对话框
    const commitCodeDialog = (template) => {
        const layout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        const btnStyle = { backgroundColor: '#4F5CFF', color: 'white', };
        return <Modal
            title="上传代码"
            visible={commitDialogInfo.visible}
            closable={true}
            width={600}
            onCancel={() => {
                setCommitDialogInfo({ visible: false });
                setTemplateInfo({ template: {} });
            }}
            confirmLoading={buttonLoading.confirmLoading}
            footer={[
                <Button key='submit' style={btnStyle} loading={buttonLoading.confirmLoading} onClick={() => { commitDialogInfo.callbackWith(template) }}>
                    确认
                </Button>,
            ]}>
            <Form  {...layout} name="basic" >
                <Form.Item label="选择模板">
                    <Select onChange={handleSelectedTemplate}>
                        {
                            commitDialogInfo.templateList.map((v, index) => {
                                return <Select.Option key={index}>{`${v.source_miniprogram} [ ${moment(new Date(v.create_time * 1000)).format('YYYY-MM-DD HH:mm:ss')} ] [ 上传者:${v.developer} ]`}</Select.Option>
                            })
                        }
                    </Select>
                </Form.Item>
                <Form.Item label="版本号">
                    <Input maxLength={64} allowClear={true} value={templateInfo.template.user_version == null ? '' : templateInfo.template.user_version} onChange={handleInputOnChange} />
                </Form.Item>
            </Form>
        </Modal>
    }


    const inputPasswdDialog = () => {
        return <Modal
            title="输入提审密码"
            visible={inputPasswordInfo.visible}
            closable={false}
            width={400}
            footer={[
                <Button key='cancel' onClick={() => { setInputPasswdDialog({visible:false}); }}>取消</Button>,
                <Button key='submit' style={{ backgroundColor: '#4F5CFF', color: 'white' }} onClick={() => { submitAuditPasswd.passwd.length == SUBMIT_AUDIT_PASSWD_SIZE && inputPasswordInfo.onCallback(submitAuditPasswd.passwd) }}>
                    确认
                </Button>,
            ]}>
            <div>
                <InputPassword size={SUBMIT_AUDIT_PASSWD_SIZE} onChange={(v)=>{setSubmitAuditPasswd({passwd:v});}} />
            </div>
            

        </Modal>
    }

    const renderName = (_, item) => { return <a style={{ fontSize: '17px', color: '#4F5CFF' }}>{item.name}</a> }
    const renderVersion = (_, item) => { return <a style={{ fontSize: '16px', color: '#4F5CFF' }}>{item.version}</a> }

    // 上传代码渲染
    const renderCommitCode = (_, item) => {
        return <Tag color={Converter.renderStatusColorOfTag(item.commit_code_status)} style={tagStyle}>{Converter.commitCodeStatusToText(item.commit_code_status)}</Tag>
    }

    // 提审状态渲染
    const renderAudit = (_, item) => {
        return <Tag color={Converter.renderStatusColorOfTag(item.submit_audit_status)} style={tagStyle}>{Converter.auditStatusToText(item.submit_audit_status)}</Tag>
    }
    const renderAuditUsedNum  = (_,item) => {
        return <a style={{fontSize:16,color:'black'}}>{item.submit_audit_used_num}</a>
    }
    // 发布状态渲染
    const renderRelease = (_, item) => {
        return <Tag color={Converter.renderStatusColorOfTag(item.release_status)} style={tagStyle}>{Converter.releaseStatusToText(item.release_status)}</Tag>
    }
    const renderReleaseTime = (_,item) => {
        return <a style={{fontSize:16,color:'black'}}>{item.latest_release_time}</a>
    }
    // 操作状态渲染
    const renderOperate = (_, item) => {
        return <div>
            <Button size="small" style={btnStyle} onClick={() => { handleUploadCoding([item]) }}>上传代码</Button>
            <Button size="small" style={btnStyle} icon={<QrcodeOutlined />} onClick={() => { handleExperienceQRCode(item) }}>体验二维码</Button>
            <Button size="small" style={btnStyle} disabled={!Filter.enableSubmitAuditAction(item)} onClick={() => { handleSubmitAudit([item]) }}>提交审核</Button>
            <Button size="small" style={btnStyle} disabled={!Filter.enableUndoAction(item)} onClick={() => { handleUndoAudit([item]) }}>撤回审核</Button>
            <Button size="small" type="primary" style={{marginLeft:8}} danger disabled={!Filter.enableReleaseAction(item)} onClick={() => { handleRelease([item]) }}>发布</Button>
            <Button size="small" style={btnStyle} onClick={() => { handleRevertRelease([item]) }}>版本回滚</Button>
        </div>
    }

    // 底部批量操作渲染
    const renderBatchGetButton = (selectedRows = []) => {
        const disabled = selectedRows.length == 0;
        return <div>
            <Button size="large" disabled={disabled} style={batchBtnStyle} onClick={() => { handleUploadCoding(selectedRows) }}>批量上传代码</Button>
            <Button size="large" disabled={disabled} style={batchBtnStyle} onClick={() => { handleSubmitAudit(selectedRows) }}>批量提交审核</Button>
            <Button size="large" disabled={disabled} style={batchBtnStyle} onClick={() => { handleRelease(selectedRows) }}>批量发布</Button>
        </div>
    }

    return <div>

        <div style={{marginBottom:4}}>
            <span style={{ marginLeft: 8,fontSize:17}}>
                {selectedInfo.selectedRowKeys.length > 0 ? `已选中${selectedInfo.selectedRowKeys.length}项` : ''}
            </span>
        </div>
        <Table
            loading={isLoading}
            rowSelection={{
                selectedRowKeys: selectedInfo.selectedRowKeys,
                onChange: handleRowOnChange
            }}
            columns={Column.miniProgramColumn(renderName, renderVersion, renderCommitCode, renderAudit,renderAuditUsedNum, renderRelease, renderReleaseTime,renderOperate)}
            dataSource={pageInfo.currentList}
            rowKey='id'
            pagination={pagination}
        />
        {renderBatchGetButton(selectedInfo.selectedRows)}
        {dialogInfo.visible && commonDialog()}
        {commitDialogInfo.visible && commitCodeDialog(templateInfo.template)}
        {inputPasswordInfo.visible && inputPasswdDialog()}
    </div>
};

export default MiniProgramsPage;