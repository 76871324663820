import React, { useState, useEffect } from 'react';
import { Image, Input, Button, message, Modal } from 'antd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getCircuitNodeStore, saveCircuitNode, delCircuitNode, sortCircuitNode } from '../../../services/task'
import { IconFont } from '../../../utils/common'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { stores } from '../../../stores/store'
import { Observer } from 'mobx-react';
import edit_icon from '../../../assets/images/edit_icon.png'
import delete_icon from '../../../assets/images/delete_icon.png'
import ManageList from '../components/manageList'

import './index.scss'

const TaskManageIndex = (props) => {

  const [ allChecked, setAllChecked ] = useState(false)
  const [ list, setList ] = useState([])
  const [ currentIndex, setCurrentIndex ] = useState(0)

  useEffect(() => {
    _init()
  }, [])
  
  const _init = () => {
    stores.userStore.getAdminInfo();
    getCircuitNodeStore().then(res=>{
      let arr = res.map((item,index)=>{
        item.checked = true
        return item
      })
      setList(arr)
      setAllChecked(true)
    }).catch(err=>{
      message.error(err||'节点获取失败')
    })
  }

  const selectProgress = (key) => {
    let flag = true

    let arr = list.map((item,index)=>{
      if(index == key){
        item.checked = !item.checked
      }
      if(!item.checked){
        flag = false
      }
      
      return item
    })
    setList(arr)
    setAllChecked(flag)
  }

  const selectAllProgress = () => {
    let arr = list.map((item,index)=>{
        item.checked = !allChecked
      return item
    })
    setList(arr)
    setAllChecked(!allChecked)
  }

  const changeEditList = (e,key) => {  
    e.stopPropagation()
    setCurrentIndex(key)
		if(list[key].isEdit){
      saveTaskName(key)
      return false
    }

    let arr = list.map((item,index)=>{
      if(index == key){
        item.isEdit = !item.isEdit
      }
      return item
    })
    setList(arr)
  }

  //保存名称
  const saveTaskName = (index) => {
    let key = index!==undefined?index:currentIndex
    let params = {
      name: list[key].name , //名称
    }
    if(list[key].id) params.id = list[key].id
    console.log('params',params)
    saveCircuitNode(params).then(res=>{
      // let arr = list.map((item,index)=>{
      //   if(index == key){
      //     item.isEdit = !item.isEdit
      //   }
      //   return item
      // })
      // setList(arr)
      _init()
    }).catch(err=>{
      message.error(err||'修改任务名称失败')
      _init()
    })
  }
  
	const changeInput = (e,key)=>{
		let arr = JSON.parse(JSON.stringify(list))
		arr[key].name = e.currentTarget.value
		setList(arr)
	}

  const deleteTask = (id,index) => {
      if(!id){
        let tempList = JSON.parse(JSON.stringify(list))
        tempList.splice(index,1)
        setList(tempList)
        return false
      }
      Modal.confirm({
          title: '操作提醒',
          icon: <ExclamationCircleOutlined />,
          content: '是否删除任务',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
              delCircuitNode({id:id}).then((res)=>{
                  message.success('删除成功')
                  let tempList = JSON.parse(JSON.stringify(list))
                  tempList.splice(index,1)
                  setList(tempList)
              }).catch((error)=>{
                  message.error(error||'删除失败')
              })
          }
      });    
  }

  const addTask = () => {
    if(list.findIndex(item=>item.id===undefined)!==-1){
      message.error('已有新增节点待添加')
      return false
    }
    let tempList = JSON.parse(JSON.stringify(list))
    tempList.push({name:'',checked:true,isEdit:true,})
    console.log(tempList)
    setList(tempList)
  }

  //在拖动结束时
  const onDragEnd = (result) => {
    if(stores.userStore.adminInfo.group_operate_id === 0 ){
      if (!result.destination) {
        return;
      }
      const items = reorder(
        list,
        result.source.index,
        result.destination.index
      );
      let id_arr = items.map((item,index)=>{
        if(item&&item.id) return item.id
      })
      console.log('result',result)
      setList(items)
      sortCircuitNode({ids:id_arr.join(',')}).then(res=>{
        // _init()
      }).catch(err=>{
        _init()
        console.log(err||'排序失败')
      })
    }else{
      message.error('没有权限拖拽排序')
    }
  }

  // //在捕获之前
  // const onBeforeCapture = (result) => {
  //   console.log('result',result)
  // }
  // //在拖动开始之前
  // const onBeforeDragStart = (result) => {
  //   console.log('result',result)
  // }
  // //在拖动开始时
  // const onDragStart = (result) => {
  //   console.log('result',result)
  // }
  // //在拖动变化时
  // const onDragUpdate = (result) => {
  //   console.log('result',result)
  // }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
  };
  
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    marginBottom: 20 ,
    marginRight: 32 ,
    // change background colour if dragging
    // styles we need to apply on draggables
    ...draggableStyle,
  });
  
  const getListStyle = isDraggingOver => ({
    // background: isDraggingOver ? 'lightblue' : 'lightgrey',
    display: 'flex',
    // padding: grid,
    overflow: 'auto',
  });

  const callBack = () => {
    _init()
  }

  return <Observer render={() => <div className="task_manage_index">
      {
        stores.userStore.adminInfo.group_operate_id === 0 && <Button type="default" icon={<IconFont type={'iconadd_icon'}  style={{fontSize:16}} />} className="mb20" onClick={()=>{addTask()}}>新增流程节点</Button> 
      }
      <div className="progress mb20">
        <div className="progress_item" onClick={()=>{selectAllProgress()}}>
          <span className={`progress_item_icon ${allChecked?'active':''}`}/> 全部
        </div>
        {
          list.map((item,index)=>{
            return item&&<div className="progress_item" key={index} onClick={()=>{selectProgress(index)}}>
              <span className={`progress_item_icon ${item.checked?'active':''}`}/> {item.name}
            </div>
          })
        }
      </div> 
      <div className="task_lsit">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
                onClick={()=>{list[currentIndex]&&list[currentIndex].isEdit&&saveTaskName()}} 
              >
                {list.map((item, index) => (
                  item.checked&&<Draggable key={index} draggableId={'item_'+index} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <div className="task_item" key={index}>
                          <div className="task_item_title">
                            <div className="task_item_title_l">
                              {
                                  item.isEdit?(
                                      <Input value={item.name} placeholder="请输入任务名称" className="mb10" onChange={(e)=>{changeInput(e,index)}} onClick={(e)=>{e.stopPropagation();setCurrentIndex(index)}}/>
                                  ):(
                                      <div>{item.name}</div>
                                  )
                              }
                                  <div>{item.rows&&item.rows.total?item.rows.total:0}家门店</div>
                            </div>
                            {
                              stores.userStore.adminInfo.group_operate_id === 0 && <div className="task_item_title_r row">
                                  <Image src={edit_icon} onClick={(e)=>{changeEditList(e,index)}} preview={false}/>
                                  <Image src={delete_icon} onClick={(e)=>{deleteTask(item.id,index)}} preview={false}/>
                              </div>
                            }
                          </div>
                          <ManageList total={item.rows&&item.rows.total?item.rows.total:0} initData={item.rows&&item.rows.data?item.rows.data:[]} id={item.id} callBack={()=>{callBack()}} pIndex={index} listLength={list.length} list={list}/>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>        
      </div>
  </div>
  }/>
}
export default TaskManageIndex