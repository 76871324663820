import React, { useEffect, useState } from 'react'
import { Row, Input } from 'antd';

import './add.scss'
import { useHistory } from 'react-router-dom';

const SalesmanAdd = ()=>{

    const history = useHistory()

    const [ formVal, setFormVal ] = useState({
        name: '',
        real_name: '',
        mobile: '',
        
    })

    useEffect(() =>{
        initComState()
    },[])

    const initComState = () =>{
        if(history.location.state){
            setFormVal({...formVal,...history.location.state.saleInfo})
        }else{
            history.goBack()
        }
    }

    return(
        <div className="salesman_add">
            <div className="detail_body_info">
                <div className="title">详情信息</div>
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">真实姓名：</div>
                    <Input className="row_input" value={formVal.real_name} disabled />
                </Row>
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">手机号：</div>
                    <Input className="row_input" value={formVal.mobile} disabled/>
                </Row>
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">角色等级：</div>
                    <Input className="row_input" value={formVal.name} disabled/>
                </Row>
            </div>
        </div>
    )
}

export default SalesmanAdd


