import { request } from '../api/http';

//订单列表
export async function orderListRequest(params) {
    return request({
        url: '/order/list',
        params,
        method:'get'
    });
}
//订单详情
export async function orderDetailRequest(params) {
    return request({
        url: '/order/index',
        params,
        method:'get'
    });
}