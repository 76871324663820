import React, { useState, useEffect } from 'react';
import { Image, Button, Tabs, message } from 'antd'
import { getStoreDetail } from '../../../services/task'
import { useLocation, useHistory } from 'react-router-dom';
import Join from './tabItem/join'
import Remark from './tabItem/remark'
import Role from './tabItem/role'
import './index.scss'

const { TabPane } = Tabs;

const TaskDetailIndex = (props) => {
    const location = useLocation()
    const history = useHistory()
    const [ info, setInfo ] = useState({})    

    useEffect(()=>{
        if(location.state&&location.state.id){
            // setParams({...params,apply_id:location.state.id})
            _init()
        }else{
            message.error('门店编号不正确', 2000, ()=>{
                history.goBack()
            })  
        }
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps          
    },[])

    const _init = () => {
        const params = {
            apply_id: location.state.id
        }
        getStoreDetail(params).then(res=>{
            setInfo(res)
        }).catch(err=>{
            message.error(err||'获取信息失败')
        })
    }

    return <div className="task_detail">
        <div className="card">
            <div className="card_title">
                详情信息
            </div>
            <div className="card_content row-start">
                <Image className="store_img" src={info.shop_exterior_pic}/>
                <div className="card_store_info">
                    <div>{info.shop_name}</div>
                    {/* <div><span>接入阶段：</span>{info.circuit_node_name}</div> */}
                    <div><span>地址： </span>{info.address}</div>
                    <div><span>电话： </span>{info.contact_name}</div>
                    <div><span>阶段：</span><Button>{info.circuit_node_name}</Button></div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card_title">
                门店对接人
            </div>
            <div className="card_content">
                <div className="card_content_item">
                    <div className="card_content_item_left">姓名 ：</div>
                    <div>{info.contact_name}</div>
                </div>
                <div className="card_content_item">
                    <div className="card_content_item_left">电话 ：</div>
                    <div>{info.contact_phone}</div>
                </div>
                <div className="card_content_item">
                    <div className="card_content_item_left">微信号 ：</div>
                    <div>{info.userWx}</div>
                </div>
            </div>
        </div>
        <div className="card">
            <Tabs type="card">
                <TabPane tab="参与人" key="1">
                    <Join info={info} id={location.state.id}/>
                </TabPane>
                <TabPane tab="客服" key="2">
                   <Role id={location.state.id} node_id={info.circuit_node_id} role={1}/>
                </TabPane>
                <TabPane tab="技术IT" key="3">
                    <Role id={location.state.id} node_id={info.circuit_node_id} role={2}/>
                </TabPane>
                {/* <TabPane tab="业务" key="4">
                    <Role id={location.state.id}/>
                </TabPane> */}
                <TabPane tab="备注" key="5">
                    <Remark id={location.state.id}/>
                </TabPane>
            </Tabs>            
        </div>     
  </div>
}
export default TaskDetailIndex