import { request } from '../api/http';


/**
 * 获取草稿箱列表
 */
export async function fetchDrafts() {
    return request({
        url: '/mini-programs/templates/drafts',
        method:'get'
    });
}

/**
 * 创建模板
 * @param {草稿箱ID} draftID 
 */
export async function createTemplate(draftID) {
    return request({
        url: '/mini-programs/templates',
        params:{draft_id:draftID},
        method:'post',
        useForm:false
    });
}

/**
 * 获取模板列表
 */
export async function fetchTemplates() {
    return request({
        url: '/mini-programs/templates',
        method:'get'
    });
}


/**
 * 删除模板
 * @param {模板ID} templateID 
 */
export async function deleteTemplate(templateID) {
    return request({
        url: '/mini-programs/templates',
        params:{
            template_id:templateID
        },
        method:'delete',
        useForm:false
    });
}


/**
 * 分页获取小程序列表
 * @param {页码} page 
 * @param {页条数} psize 
 */
export async function fetchMiniProgram(page,psize) {
    return request({
        url: '/mini-programs',
        params:{
            page,
            psize
        },
        method:'get'
    });
}

/**
 * 获取体验码
 * @param {小程序APPID} appID 
 */
export async function fetchExperienceCode(appID){
    return request({
        url: '/mini-programs/experience-qrcode',
        params:{
            appid:appID
        },
        method:'get'
    });
}



/**
 * 批量提交小程序代码
 * @param {提交审核的小程序列表} appList 
 */
export async function batchCommitCode(appList) {
    return request({
        url: '/mini-programs/code-commit',
        params:{
            commit_list:appList
        },
        method:'post',
        useForm:false
    });
}

/**
 * 批量提交小程序审核
 * @param {提交审核的小程序列表} appList 
 * @param  密码 passwd  默认不填
 */
export async function batchSubmitAudit(appList=[],passwd=''){
    return request({
        url: '/mini-programs/audit/submit',
        params:{
            audit_list:appList,
            audit_passwd:passwd
        },
        method:'post',
        useForm:false
    });
}

/**
 * 单个撤销小程序审核状态
 * @param {小程序APPID} appID 
 */
export async function undoAudit(id,appID){
    return request({
        url: '/mini-programs/audit/undo',
        params:{
            id:id,
            appid:appID
        },
        method:'post',
        useForm:false
    });
}

/**
 * 获取小程序审核状态
 * @param {小程序APPID} appID 
 * @param {审核ID} auditID 
 */
export async function getAuditStatus(appID,auditID){
    return request({
        url: '/mini-programs/audit/status',
        params:{
            appid:appID,
            audit_id:auditID
        },
        method:'get'
    });
}

/**
 * 批量发布小程序
 * @param {小程序发布列表} appList 
 */
export async function batchRelease(appList){
    return request({
        url: '/mini-programs/releases',
        params:{
            release_list:appList
        },
        method:'post',
        useForm:false
    });
}


/**
 * 支持批量撤回线上版本
 * @param {小程序撤回线上版本列表} appList 
 */
export async function batchRevertRelease(appList){
    return request({
        url: '/mini-programs/releases/revert',
        params:{
            revert_release_list:appList
        },
        method:'post',
        useForm:false
    });
}






