import { request } from '../api/http';
// 接龙圈住管理
export async function jielongManage(params) {
    return request({
        url: '/manage/sassList',
        params,
        method:'get'
    });
}
// 接龙圈住管理详情
export async function jielongDetail(params) {
    return request({
        url: '/manage/sassIndex',
        params,
        method:'get'
    });
}

