import React, { useEffect, useState } from 'react'
import { Descriptions, message} from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { jielongDetail } from '../../../services/jielongService'
import './detail.scss'

const LeaderDetail = (props)=>{

    const location = useLocation()
    const history = useHistory()
    const [ info, setInfo] = useState({
        log_list:[]
    })
    
    useEffect(() =>{
        getDetail()
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps            
    },[]);

    const getDetail = ()=>{
        if(!location.state||!location.state.id){
            history.goBack()
            return false
        }
        let params = {
            id: location.state.id
        }
        jielongDetail(params).then((res)=>{
            setInfo(res)
        }).catch((error)=>{
            message.error(error||"信息获取失败");
        })                
    }

    return(
        <div className="merchants_detail">
            <div className="detail_body_info">
                <Descriptions title="基本信息" bordered>
                    <Descriptions.Item label="ID">{info.id?info.id:'--'}</Descriptions.Item>
                    <Descriptions.Item label="圈主名称">暂缺字段</Descriptions.Item>
                    <Descriptions.Item label="手机号">{info.contact_phone?info.contact_phone:'--'}</Descriptions.Item>
                    <Descriptions.Item label="购买时间">{info.created_at?info.created_at:'--'}</Descriptions.Item>
                    <Descriptions.Item label="地区">{info.province}{info.city}{info.district}</Descriptions.Item>
                    <Descriptions.Item label="门店地址">{info.address?info.address:'--'} </Descriptions.Item>
                    <Descriptions.Item label="餐饮类型">{info.catering_type?info.catering_type:'--'}</Descriptions.Item>
                    <Descriptions.Item label="推荐人">{info.saleman_id?info.salesman_name+'-'+info.saleman_id:'--'}</Descriptions.Item>
                    <Descriptions.Item label="-">--</Descriptions.Item>
                </Descriptions>    
            </div>
            <div className="detail_body_info">
            <Descriptions title="系统信息" bordered>
                <Descriptions.Item label="系统购买次数">{info.pay_system_num?info.pay_system_num:'--'}</Descriptions.Item>
                <Descriptions.Item label="系统开始时间">{info.system_start_time?info.system_start_time:'--'}</Descriptions.Item>
                <Descriptions.Item label="距截止日期剩余天数">{info.date_part?info.date_part:'--'}天</Descriptions.Item>
                <Descriptions.Item label="购买总金额">{info.pay_total?info.pay_total:'--'}</Descriptions.Item>
                <Descriptions.Item label="系统结束时间">{info.system_end_time?info.system_end_time:'--'}</Descriptions.Item>
                <Descriptions.Item label="--">--</Descriptions.Item>
            </Descriptions>   
            </div>
            <div className="detail_body_info">
                {
                    info.log_list&&info.log_list.map((item,index)=>{
                        return <Descriptions title={index===0?"购买记录":''} bordered key={index}  className={index===0?"mt20":''}>
                            <Descriptions.Item label="购买时间">{item.pay_time}</Descriptions.Item>
                            <Descriptions.Item label="购买金额">{item.pay_money}</Descriptions.Item>
                            <Descriptions.Item label="商品类型">{item.system_name+(item.system_version?'-'+item.system_version:'')}</Descriptions.Item>
                        </Descriptions>   
                    })
                }           
            </div>
                    
                  
        </div>
    )

}

export default LeaderDetail