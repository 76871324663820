import React, { useState,useEffect } from 'react'
import { useHistory } from "react-router-dom";
import './Print.scss'
import {IconFont} from '../../../utils/common'
import {
    Button,
    Input,
    Space,
    Table,
    Image,
    Tooltip,
    message,
    Modal,
    Spin,
} from 'antd'
import look_icon from '../../../assets/images/look_icon.png'
import {shopPrintListRequest,shopPrintIndexCheckRequest} from '../../../services/unitService'
const {Search} = Input


const Print = (props) =>{
    let history = useHistory();

    const [isLoading,setIsLoading] = useState(false)

    const [shopName,setshopName] = useState('')

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
        
    })

    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        
    },[])

      // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        shopPrintListRequest({...params}).then(res =>{
            console.log(res)
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    const columns = [
        {
          title: '门店名',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '打印机名',
          dataIndex: 'print_name',
          key: 'print_name',
        },
        {
            title: '操作',
            render: (text,record) => <div>
                <Space>
                    <Tooltip placement="top" title={'检查状态'}>
                        <Image  onClick={() => {printCheck(record)}} src={look_icon} className="action_icon"  preview={false}></Image>
                    </Tooltip>
                    
                </Space>
            </div>,
        },
      ];

    const onSearch = (e)=>{
        console.log('onSearch',e)
        setshopName(e)
        getPageList({...pageParams,name:e})
    }

    const [printCheckText,setprintCheckText] = useState([])

    const printCheck = (print)=> {
        console.log(print)
        setprintCheckText([])
        setlodingVisibel(true)
        setmodalVisible(true)
        
        shopPrintIndexCheckRequest({id:print.print_id}).then((res) => {
            setlodingVisibel(false)
            console.log('asdfasdfasdf',res.data);
            setprintCheckText(res.data)
            console.log('asdfasdfasdf',res.data.length);
        }).catch((error)=>{
            console.log('...................',error);
            setlodingVisibel(false)
            message.error(error)
        })
    }

    const [lodingVisibel,setlodingVisibel] = useState(true)

    const [modalVisible,setmodalVisible] = useState(false)

    const handleCancel = (e) => {
        console.log(e);
        setmodalVisible(false)
      };

    return <div className='print'>
        <div className="search">
            <Search placeholder="input search text" onSearch={onSearch} style={{ width: 200 }} />
        </div>
        <div className='table'>
            <Table dataSource={pageInfo.list} columns={columns} />;
        </div>

        <Modal
          title="打印机状态"
          visible={modalVisible}
          onOk={()=>{}}
          onCancel={handleCancel}
        >
            <div>
                {
                    lodingVisibel ?
                    <Spin tip="Loading...">
                        加载中...
                    </Spin>
                    :
                    <div>
                        {
                            Array.isArray(printCheckText) && printCheckText.map((item,key) => {
                                return (
                                    <div>{key}:{item}</div>
                                    
                                );
                            })
                        }
                    </div>
                }


                
            </div>
        </Modal>

    </div>

    
}

export default Print