import { request } from '../api/http';
// 代理商添加
export async function agentAddRequest(params) {
    return request({
        url: '/agent/add',
        params,
        method:'post'
    });
}
// 代理商编辑
export async function agentEditRequest(params) {
    return request({
        url: '/agent/edit',
        params,
        method:'post'
    });
}

// 代理商编辑
export async function agentDetailRequest(params) {
    return request({
        url: '/agent/index',
        params,
        method:'get'
    });
}
// 代理商冻结
export async function agentAbleRequest(params) {
    return request({
        url: '/agent/edit_status',
        params,
        method:'post'
    });
}

// 代理商列表
export async function agentListRequest(params) {
    return request({
        url: '/agent/list',
        params,
        method:'get'
    });
}

// 获取推广收益配置列表
export async function agentConfigListRequest(params) {
    return request({
        url: '/agent/addList',
        params,
        method:'get'
    });
}

// 代理商收益列表
export async function agentEarningListRequest(params) {
    return request({
        url: '/finance/agentEarningsList',
        params,
        method:'get'
    });
}

// 提现列表
export async function withdrawListRequest(params) {
    return request({
        url: '/finance/withdrawLogList',
        params,
        method:'get'
    });
}

// 提现操作
export async function withdrawEditStatus(params) {
    return request({
        url: '/finance/withdrawEditStatus',
        params,
        method:'post'
    });
}

// 业务员推广二维码
export async function getShareQrCode(params) {
    return request({
        url: '/share_qr_code',
        params,
        method:'get'
    });
}

// 代理商推广二维码
export async function getAgentQrCode(params) {
    return request({
        url: '/agent_qr_code',
        params,
        method:'get'
    });
}


