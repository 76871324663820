import React, { useState, useEffect } from 'react';
import { Image, Modal, AutoComplete, Button, message, Input } from 'antd'
import { getStoreDetail, getAllRoleMember, saveResponsible } from '../../../services/task'
import { PlusOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { stores } from '../../../stores/store'
import { Observer } from 'mobx-react';

import './index.scss'

const IndexDetail = (props) => {
    const history = useHistory()
    const location = useLocation()

    const [ info, setInfo ] = useState({})
    const [ visible, setVisible ] = useState(false)
    const [ options, setOptions ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ params, setParams ] = useState({
        id: '',//管理员ID
        apply_id: '',//商家申请ID
        role: '',//工作角色 1客服 2技术
    }); //提交参数

    useEffect(()=>{
        if(location.state&&location.state.id){
            setParams({...params,apply_id:location.state.id})
            _init()
        }else{
            message.error('门店编号不正确', 2000, ()=>{
                history.goBack()
            })  
        }
    },[])

    const _init = () => {
        stores.userStore.getAdminInfo();
        const params = {
            apply_id: location.state.id
        }
        getStoreDetail(params).then(res=>{
            setInfo(res)
        }).catch(err=>{
            message.error(err||'获取信息失败')
        })
    }

    const renderTitle = (title) => {
        return (
            <span>
                {title}
            </span>
        );
    };

    const renderItem = (title, id) => {
        return {
            value: title,
            key: id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {title}
                </div>
            ),
        };
    };

    const openModal = (role)=>{
        setVisible(true)
        setParams({...params,role:role})
    }

    const handleOk = ()=>{
        if(!params.id){
            message.error('请选择成员')
            return false
        }
        setLoading(true)
        saveResponsible(params).then((res)=>{
            message.success('分配成功')
            setVisible(false)
            setLoading(false)
            _init()
        }).catch(err=>{
            message.error(err||'分配失败')
            setLoading(false)
        })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const onSelect = (data,option) => {
        setParams({...params,id:option.key})
    }

    let timer
    const onChange = (e) => {
        if(!e){
            setOptions([])
            setParams({...params,id:''})
            return false
        }
        timer && clearTimeout(timer)
        //500毫秒后请求
        timer = setTimeout(async()=>{
            var res = await getAllRoleMember({ keyword: e })
            let arrTemp = []
            res.map((item,index)=>{
                arrTemp.push(        {
                    label: renderTitle(item.group_name),
                    // options: [renderItem('AntDesign', 10000,1), renderItem('AntDesign UI', 10600,2)],
                    options: item.items.map((subItem)=>{
                        return renderItem(subItem.name,subItem.id)
                    }),
                })
            })
            setOptions(arrTemp)
        },500)
    }
    
    return <Observer render={() => <div className="index_detail">
        <div className="card">
            <div className="card_title">
                详情信息
            </div>
            <div className="card_content row">
                <Image src={info.shop_exterior_pic} className="store_img"/>
                <div className="card_store_info">
                    <div>{info.shop_name}</div>
                    <div>接入阶段：{info.circuit_node_name}</div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card_title">
                门店对接人
            </div>
            <div className="card_content">
                <div className="card_content_item">
                    <div className="card_content_item_left">姓名 ：</div>
                    <div>{info.contact_name}</div>
                </div>
                <div className="card_content_item">
                    <div className="card_content_item_left">电话 ：</div>
                    <div>{info.contact_phone}</div>
                </div>
                <div className="card_content_item">
                    <div className="card_content_item_left">微信号 ：</div>
                    <div>{info.userWx}</div>
                </div>
            </div>
        </div>
        <div className="card">
            <div className="card_title">
                接入负责人
            </div>
            <div className="card_content">
                <div className="card_content_item">
                    <div className="card_content_item_left">业务员 ：</div>
                    <div className="card_content_item_center">{info.salesman_name}</div>
                </div>
                <div className="card_content_item">
                    <div className="card_content_item_left">客服 ：</div>
                    {
                        info.responsibles&&info.responsibles.findIndex((item)=>item.role===1)!==-1?(
                            info.responsibles.map((pItem,pIndex)=>{
                                return pItem.role==1&&(pItem.items.length===0?<div className="card_content_item_center">未分配</div> :pItem.items.map((item,index)=>{
                                    return <div key={pIndex+'-'+index} className="card_content_item_center">{item.name}</div>
                                }))
                            })
                        ):<div className="card_content_item_center">未分配</div>                    
                    }
                    {
                        stores.userStore.adminInfo.group_operate_id === 0 && <div className="card_content_item_right" onClick={()=>{openModal(1)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
                    }
                </div>
                <div className="card_content_item">
                    <div className="card_content_item_left">技术IT ：</div>
                    {
                        info.responsibles&&info.responsibles.findIndex((item)=>item.role===2)!==-1?(
                            info.responsibles.map((pItem,pIndex)=>{
                                return pItem.role==2&&(pItem.items.length===0?<div className="card_content_item_center">未分配</div> :pItem.items.map((item,index)=>{
                                    return <div key={pIndex+'-'+index} className="card_content_item_center">{item.name}</div>
                                }))
                            })
                        ):<div className="card_content_item_center">未分配</div>                       
                    }     
                    {
                        stores.userStore.adminInfo.group_operate_id === 0 && <div className="card_content_item_right" onClick={()=>{openModal(2)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
                    }               
                </div>
            </div>
        </div>
        <Modal
            title="添加成员"
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            className="task_add_user_modal"
            footer={null}
            width={343}
        >
            <AutoComplete
                dropdownClassName="certain-category-search-dropdown"
                dropdownMatchSelectWidth={'100%'}
                style={{ width: '100%' }}
                options={options}
                onChange={onChange}
                onSelect={onSelect}
            >
                <Input.Search size="large" placeholder="搜索成员" />
            </AutoComplete>           
            <div className="footer">
                <Button onClick={()=>{handleOk()}} loading={loading}>确认分配</Button>
            </div>
        </Modal>      
  </div>
  }/>
}
export default IndexDetail