import {upload} from 'qiniu-js'

/**
 * 
 * @param {*} file 文件
 * @param {*} key 唯一key
 * @param {*} token 七牛token
 * @param {-} observer 上传监听 回调
 */
export function qiniu_upload(file,key,token,observer,putExtra=null){
    const observable = upload(file,key,token,putExtra)
    observable.subscribe(observer)
    // console.log('observer',observer)
    // const subscription = observable.subscribe(observer)
}