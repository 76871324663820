import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    Button,
    Input,
    Select,
    Table,
    Image,
    Tooltip,
    message
} from 'antd'
import detail_icon from '../../../assets/images/detail_icon.png'
import {IconFont} from '../../../utils/common'
import {orderListRequest} from '../../../services/orderService'
import './orderManage.scss'

const { Option } = Select;
const {Search} = Input
const selectData = [{id: 0,name:'全部'},{id: 1,name:'待支付'},{id: 2,name:'已支付'},{id: 3,name:'支付失败'}]
// const selectGoodData = [{id: 0,name:'全部'},{id: 1,name:'基础版'},{id: 2,name:'专业版'},{id: 3,name:'尊贵版'},{id: 4,name:'连锁版'}]

const OrderManage =(props) =>{
    let history = useHistory();
    const [isLoading,setIsLoading] = useState(false)
    const [ downloadLoading, setDownloadLoading] = useState(false)

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        pay_status: 0, // 0待审核 5 审核失败 10 审核成功
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])


    // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        orderListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.pay_status) params.pay_status = serachParams_temp.pay_status
        // if(serachParams_temp.timer.length > 0) {
        //     params.created_start = serachParams_temp.timer[0]
        //     params.created_end = serachParams_temp.timer[1]
        // }
        // if(serachParams_temp.auth_timer.length > 0) {
        //     params.auth_start = serachParams_temp.auth_timer[0]
        //     params.auth_end = serachParams_temp.auth_timer[1]
        // }
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'pay_status':
                serachParams_temp = {...serachParams,pay_status: e}
                break;
            // case 'timer':
            //     serachParams_temp = {...serachParams,timer: e}
            //     break;
            // case 'auth_timer':
            //     serachParams_temp = {...serachParams,auth_timer: e}
            //     break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }
    const columns = [
        {
            title: '订单号',
            dataIndex: 'platform_code',
            key: 'platform_code',
        },
        {
            title: '下单时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '支付时间',
            dataIndex: 'pay_time',
            key: 'pay_time',
        },
        // {
        //     title: '门店名称',
        //     dataIndex: 'real_name',
        //     key: 'real_name',
        // },
        {
            title: '门店ID',
            dataIndex: 'shop_id',
            key: 'shop_id',
        },
        {
            title: '真实姓名',
            dataIndex: 'real_name',
            key: 'real_name',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        // {
        //     title: '商品类型',
        //     dataIndex: 'col8',
        //     key: 'col8',
        // },
        {
            title: '业务员',
            dataIndex: 'salesman_id',
            key: 'salesman_id',
            render: (text,record) => !record.salesman_id?'':`${record.salesman_name}-${record.salesman_id}`,
        },
        {
            title: '支付金额',
            dataIndex: 'pay_money',
            key: 'pay_money',
        },
        {
            title: '支付方式',
            dataIndex: 'pay_type',
            render: text => text===1?'微信公众号支付':'未知',
        },
        {
            title: '订单状态',
            dataIndex: 'pay_status',
            key: 'pay_status',
            render: text => text===1?'待支付':text===2?'已支付':text===3?'支付失败':'未知',
        },
        {
            title: '操作',
            fixed: 'right',
            width: 100,
            render: (text,record) =>  <Tooltip placement="top" title={'详情'}>
            <Image src={detail_icon} className="action_icon"  preview={false} onClick={() => {
                history.push("/sidebar/orderManage/list/detail",{id:record.id})
            }}></Image>
         </Tooltip>,
        },
    ]
   
    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        orderListRequest({page:1,pageSize:3000}).then(response=>{
            import("../../../utils/Export2Excel").then((excel) => {
                const tHeader = ["订单号", "下单时间", "支付时间", "门店ID", "真实姓名", "手机号", "业务员", "支付金额", "支付方式", "订单状态"];
                const filterVal = ["platform_code", "created_at", "pay_time", "shop_id", "real_name", "mobile", "salesman_str", "pay_money", "pay_type_str", "pay_status_str"];
                let dataArr = response.data.map((item,index)=>{
                    item.salesman_str = !item.salesman_id?'':`${item.salesman_name}-${item.salesman_id}`
                    item.pay_type_str = item.pay_type === 1 ? '微信公众号支付' : '未知'
                    item.pay_status_str = item.pay_status === 1 ? '待支付' : item.pay_status === 2 ? '已支付' : item.pay_status === 3 ? '支付失败' : '未知'
                    return item
                })
                const dataSourse = formatJson(filterVal, dataArr);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '订单列表', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }

    return <div className="orderManage">
        <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />} onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
        <div className="content_body">
        <div className="screen-dart">
            <Search placeholder="输入姓名、手机号、门店名称" className="input_view" onSearch={(e) => handleSerachForm(e,'search')}/>
            <div className="screen-item">
                <div className="item-title">订单状态:</div>
                <Select
                    value={serachParams.pay_status}
                    className="select-css"
                    onChange={(e) => handleSerachForm(e,'pay_status')}
                  >
                    {selectData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                  </Select>
            </div>
            {/* <div className="screen-item">
                <div className="item-title">商品类型:</div>
                <Select
                    onChange={() =>{}}
                    value={0}
                    className="select-css"
                  >
                    {selectGoodData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                  </Select>
            </div> */}
        </div>
        <Table
            loading={isLoading}
            columns={columns}
            dataSource={pageInfo.list}
            rowKey='id'
            pagination={pagination}
        />
        </div>
       
    </div>
}

export default OrderManage