import React, { useEffect, useState } from 'react'
import { Row, Button, Input, Table, Modal, Space, Tooltip, Image, message } from 'antd'
import { useHistory } from 'react-router-dom';
import {IconFont} from '../../../utils/common'
import people_icon from '../../../assets/images/people_icon.png'
import edit_icon from '../../../assets/images/edit_icon.png'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import delete_icon from '../../../assets/images/delete_icon.png'
import { getRoleGroup, delRoleGroup } from '../../../services/permissions'
import './index.scss'

const { Search } = Input

const PermissionsRoleIndex = ()=>{

    const history = useHistory()
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [paramsObj, setParamsObj] = useState({
        page: 1,
        page_size: 10,
    })

    useEffect(() => {
        getList({...paramsObj})
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps  
    }, [])

    const getList = async (data = {}) => {
        let param = {
            ...data
        }
        if(param.sortType)param.sortType=param.sortType==='ascend'?'asc':'desc'
        Object.keys(param).map(el => {
            if(!param[el]) delete param[el]
        })
        setParamsObj({...param})
        setLoading(true)
        getRoleGroup(param).then((res)=>{
            setLoading(false)
            setData(res.data);
            setTotal(res.total)
        }).catch((error)=>{
            setLoading(false)
            message.error(error)
        })
    }
        
    const handleTableChange = (pagination, filters, sorter) => {
        getList({
            ...paramsObj,
            page:pagination.current,
            page_size:pagination.pageSize,
            sortField:sorter.field,
            sortType:sorter.order
        })
    }

    const creatTable = ()=>{
            
        const columns = [
            {
                title: 'ID',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: '权限分组',
                dataIndex: 'group_name',
                key: 'group_name',
            },
            {
                title: '人数',
                dataIndex: 'total_user',
                key: 'total_user',
            },
            {
                title: '创建时间',
                dataIndex: 'created_at',
                key: 'created_at',
            },
            {
                title: '操作',
                dataIndex: 'option',
                key: 'option',
                render: ( text, record, index )=>
                    <>
                        <Space>
                            <Tooltip placement="top" title={'编辑'}>
                                <Image src={edit_icon} className="action_icon" preview={false} onClick={()=>{history.push('/sidebar/permissions/role/add',{id:record.id})}}></Image>
                            </Tooltip>                         
                            <Tooltip placement="top" title={'查看人员'}>
                                <Image src={people_icon} className="action_icon" preview={false} onClick={()=>{history.push('/sidebar/permissions/user/index',{id:record.id})}}></Image>
                            </Tooltip>                         
                            <Tooltip placement="top" title={record.operate_id !== 0 && '删除'}>
                                {
                                    record.operate_id !== 0 ? <Image src={delete_icon} className="action_icon"  preview={false} onClick={() => deleteGroup(record.id,record.total_user>0)}></Image> : <div className="action_icon"></div>
                                }
                            </Tooltip>     
                        </Space>
                    </>
            },
        ];

        return (
            <Table 
                dataSource={data} 
                columns={columns} 
                onChange={handleTableChange}   
                rowKey={'id'}  
                loading={loading}
                pagination={{
                    defaultCurrent: 1,
                    current:paramsObj.page,
                    pageSize: paramsObj.page_size,
                    total: total,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    showTotal: (total) => `共${total}条`,
                }}   
            />
        )
    }

    const salesmanAdd = ()=>{
        history.push('/sidebar/permissions/role/add')
    }

    //删除
    const deleteGroup = (id,is_del_group_user)=>{
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: is_del_group_user?'该权限分组成员尚未清空,是否继续删除，删除将清空组内成员':'是否删除该权限分组',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                delRoleGroup({group_id: id, is_del_group_user: is_del_group_user?1:0}).then(res =>{
                    message.success('操作成功')
                    getList({...paramsObj})
                }).catch((error)=>{
                    message.error(error)
                })
            }
        });
    }

    return(
        <div className="permissions_role_index">
            <Space>
                <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}}/>}  onClick={()=>salesmanAdd()}>新增</Button>
            </Space> 
            <div className="content_body">
                <Row type="flex" align="middle" className="search mb30">
                    <Search
                        placeholder="输入分组名称搜索"
                        onSearch={value => {
                            getList({...paramsObj,page:1,keyword:value})
                        }}
                        style={{width:300}}
                    />                             
                </Row>
                {
                    creatTable()
                }
            </div>           
        </div>
    )
}

export default PermissionsRoleIndex