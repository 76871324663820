
import React, {useEffect} from 'react'
import { Badge ,Menu, Dropdown} from 'antd'
import { UserOutlined,DownOutlined } from '@ant-design/icons';
import { removeToken } from '../../utils/storage'
import { stores } from '../../stores/store'
import { Observer } from 'mobx-react';

import './head_label.scss'
const HeadLabel = (props) =>{

    useEffect(()=>{
      stores.userStore.getAdminInfo();
    },[])

    const loginOut = ()=>{
      removeToken()
      window.location.href = "/login"
    }
    const menu = (
      <Menu>
        <Menu.Item onClick={()=>{loginOut()}}>
          退出
        </Menu.Item>
        {/* <Menu.Item onClick={()=>{loginOut()}}>
          切换用户
        </Menu.Item> */}
      </Menu>
    );
    return <Observer render={() => 
      <div className="head_label">
              {/* <Badge count={5} size="small">
                  <div className="message_view"></div>
              </Badge> */}
              <Dropdown overlay={menu}>
                  <div><UserOutlined className="mr10"/>你好，{stores.userStore.adminInfo&&stores.userStore.adminInfo.name} <DownOutlined /></div>
              </Dropdown>
              
      </div>
    }/>
}

export default HeadLabel