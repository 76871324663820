import { request } from '../api/http';

//所有菜单
export async function getRoleMenu(params) {
    return request({
        url: '/role/menus',
        params,
        method:'get'
    });
}

//管理分组列表
export async function getRoleGroup(params) {
    return request({
        url: '/role/group',
        params,
        method:'get'
    });
}

//管理分组详情
export async function getRoleGroupDetail(params) {
    return request({
        url: '/role/group_detail',
        params,
        method:'get'
    });
}

//管理分组【选项框专用】
export async function getRoleSelectGroup(params) {
    return request({
        url: '/role/select_group',
        params,
        method:'get'
    });
}

//保存管理分组
export async function saveRoleGroup(params) {
    return request({
        url: '/role/save_group',
        params,
        method:'post'
    });
}

//删除管理分组
export async function delRoleGroup(params) {
    return request({
        url: '/role/del_group',
        params,
        method:'post'
    });
}


//管理员列表
export async function getUserList(params) {
    return request({
        url: '/user/list',
        params,
        method:'get'
    });
}

//保存管理员
export async function saveUser(params) {
    return request({
        url: '/user/save',
        params,
        method:'post'
    });
}

//当前管理员信息
export async function getUserInfo(params) {
    return request({
        url: '/info',
        params,
        method:'post'
    });
}

//设置管理员状态
export async function saveUserStatus(params) {
    return request({
        url: '/user/status_user',
        params,
        method:'post'
    });
}

//删除管理员
export async function delUser(params) {
    return request({
        url: '/user/del_user',
        params,
        method:'post'
    });
}
