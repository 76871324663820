import React, { useEffect, useState } from 'react'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import {
    Button,
    Input,
    Image,
    Row,
    InputNumber,
    Col,
    Spin,
    message
} from 'antd'
import './detail.scss'
import { PlusOutlined } from '@ant-design/icons';
import choose_icon from '../../../../assets/images/choose_icon.png'
import choose_active_icon from '../../../../assets/images/choose_active_icon.png'

import {serviceListRequest,mealAddRequest,mealEditRequest,mealDetailRequest} from '../../../../services/systemService'
import {getQiniuToken} from '../../../../services/commonService'
import {qiniu_upload} from '../../../../utils/qiniuUpload'
import { useHistory } from 'react-router-dom'


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const MealManageDetail = (props) =>{
    const history = useHistory()

    const [isLoading,setIsLoading] = useState(false)
    const [editorState,setEditorState] = useState( BraftEditor.createEditorState(null))
    
    const [formParams,setFormParams] = useState({
          name: '', // 套餐名称
          content: [], // 套餐内容
          money: '',  // 标准价格
          desc: '',   // 套餐描述
          img: '',   // 项目海报（封面图）
          content_text: '', // 服务详情
      })
    const [countParams,setCountParams] = useState({
      price: 0,
      chooseArrary: []
    })

    useEffect(() =>{
      getServicesList()
    },[])

    // 获取服务项目列表
    const getServicesList = () =>{
      setIsLoading(true)
      serviceListRequest({}).then(res =>{
        if(history.location.state){
          // 编辑
          getMealDetail(res)
        }else{
          collectFormParams(res)
          
        }
        
      }).catch((error)=>{
        setIsLoading(false)
        message.error(error)
      })
    }

    // 获取套餐详情
    const getMealDetail = (services) =>{
      mealDetailRequest({id: history.location.state.id}).then(res =>{
        // 整理 初始化form数据
        setFormParams({
          ...formParams,
          name: res.name,
          money: res.money,
          desc: res.desc,
          img: res.img,
          content: collectEditFormParams(JSON.parse(res.content),services)
        })
       
        setEditorState(BraftEditor.createEditorState(res.content_text))
        setIsLoading(false)
      }).catch((error)=>{
        setIsLoading(false)
        message.error(error)
      })
    }

    // 编辑 初始化 套餐内容数据
    const collectEditFormParams = (chooseServices,services) =>{
      console.log('chooseServices',chooseServices)
      console.log('services',services)
      let tempArray = []; // 服务项目 最终数组
      services.map(item =>{
        let tempValue = []; // 服务项目item数组
        item.value.map(child =>{
          let childObj = {}
          let index = chooseServices.findIndex(_item => _item.id == child.id)
          if(index != -1){
            childObj =  {id: child.id,name: child.name,price:child.price,num: chooseServices[index].num,isActive: true, version: child.version,}
          }else{
            childObj =  {id: child.id,name: child.name,price:child.price,num: 1,isActive: false, version: child.version,}
          }
          tempValue.push(childObj)
        })
        let obj = {name: item.name,value: tempValue}
        tempArray.push(obj)
      })
      compuetContPrice(tempArray)
      return tempArray
    }

    // 整理 初始化 表单数据
    const collectFormParams = (res) =>{
      let tempArray = []; // 服务项目 最终数组
      res.map(item =>{
        let tempValue = []; // 服务项目item数组
        item.value.map(child =>{
          let childObj =  {id: child.id,name: child.name,price:child.price,num: 1,isActive: false, version: child.version,}
          tempValue.push(childObj)
        })
        let obj = {name: item.name,value: tempValue}
        tempArray.push(obj)
      })
      setFormParams({ ...formParams,content: tempArray})
      setIsLoading(false)
    }


    // 监听提交表单变化
    const handleFormParams = (e,type) =>{
      switch(type){
        case 'name':
          setFormParams({...formParams,name: e.target.value})
          break;
        case 'money':
          setFormParams({...formParams,money: e.target.value})
          break;
        case 'desc':
          setFormParams({...formParams,desc: e.target.value})
          break;
      }
    }

    // 监听套餐 内容变化  choose 选中事件  value 更改值事件
    const handleContent = (index,cIndex,type,value=1) =>{
      let contentTemp = formParams.content
      switch(type){
        case 'choose':
          let findIndex = contentTemp[index].value.findIndex((item)=>{ return item.isActive&&item.name==contentTemp[index].value[cIndex].name})
          if(findIndex!==-1){
            //存在相同名称的只能选一个
            let isActive =  contentTemp[index].value[cIndex].isActive
            let findIsActive =  contentTemp[index].value[findIndex].isActive 
            contentTemp[index].value[cIndex].isActive = !isActive
            contentTemp[index].value[findIndex].isActive = !findIsActive
            setFormParams({...formParams,content: contentTemp})
          }else{
            let isActive =  contentTemp[index].value[cIndex].isActive
            contentTemp[index].value[cIndex].isActive = !isActive
            setFormParams({...formParams,content: contentTemp})
          }
          break;
        case 'value':
          contentTemp[index].value[cIndex].num = value
          setFormParams({...formParams,content: contentTemp})
          break;
      }
      compuetContPrice(contentTemp)
    }
    // 计算价格
    const compuetContPrice = (content) =>{
      let price = 0
      let chooseArrary = []
      content.map(item =>{
        item.value.map(child =>{
          if(child.isActive){
            chooseArrary.push(child.name+(child.version?'-'+child.version:''))
            price += child.num * child.price
          }
        })
      })
      price = Math.floor(price).toFixed(2)
      setCountParams({price,chooseArrary})
    }


    // 上传海报创建
    const createUpload = () =>{
      function clickFile(){
        document.getElementById("fileImage").click(); 
      }
      return <>
          <input id="fileImage" multiple accept=".png,.jpeg,.jpg" style={{display: 'none'}} type="file" onChange={chooseFile}></input>
          <div className="poster_view" >
          <div className="upload_view flex-center">
            {
              formParams.img ?
              <Image preview={false} onClick={clickFile} src={formParams.img} className="upload_img"/>
              :
              <PlusOutlined onClick={clickFile} style={{fontSize: 32,color: '#F0F0F0'}} />
            }
             </div>
            <div>(建议上传尺寸为1:1)</div>
          </div>
        </>
    }
    // 显示选择图片
    const chooseFile = () =>{
      let files = document.querySelector('#fileImage').files;
      if(files && files.length > 0){
        getBase64(files[0]).then(res =>{
          setFormParams({ ...formParams,img: res})
        })
      }
    }
    // 获取key
    const getQiniuKey = () =>{
      return `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`; // 生成随机key
    }
    const customRequest = async (callBack) =>{
      let files = document.querySelector('#fileImage').files;
      if(files && files.length > 0){
        getQiniuToken({type: 'food'}).then( async res =>{
                  let imageKey = getQiniuKey()
                  let observer = {
                      complete: (res) => {
                          callBack({status: 200,url:res.url})
                      },
                      error: (error) =>  {
                          message.error(error || '上传资源失败');
                          callBack({status: 500,error})
                          return
                      },
                      next: (next) => {
                          console.log('上传进度',next.total.percent)
                          return
                      }
                  }
                  qiniu_upload(files[0],imageKey,res.upload_token,observer,{customVars: {'x:type': 'food'}})
                  
              }).catch(error => {
                  message.error(error || '获取七牛token失败')
              })
      }
    }


    // 提交数据
    const submitParams = () =>{
    
      let flag = validatorParams()
      if(flag){
        let formParamsTemp = JSON.parse(JSON.stringify(formParams))
        // 整理提交数据
        formParamsTemp.content_text = editorState.toHTML()
        let arrayTemp = []
        formParamsTemp.content.map(item =>{
          item.value.map(_item =>{
            if(_item.isActive){
              let obj = {id: _item.id,name: _item.name,num: _item.num}
              arrayTemp.push(obj)
            }
          })
        })
        if(arrayTemp.length < 2){
          message.error('套餐内容至少选择两项')
          return
        }
        setIsLoading(true)
        formParamsTemp.content = JSON.stringify(arrayTemp)
        if(history.location.state){
          formParamsTemp.id = history.location.state.id
          // 编辑
          if(formParams.img.indexOf("http") == -1){
            customRequest((res) =>{
              if(res.status === 200){
                formParamsTemp.img = res.url
                mealEditRequest(formParamsTemp).then(res =>{
                  message.success('编辑套餐成功')
                  history.goBack()
                }).catch((error)=>{
                  setIsLoading(false)
                  message.error(error)
                })
              }
            }).catch(err =>{
              setIsLoading(false)
            })
          }else{
            mealEditRequest(formParamsTemp).then(res =>{
              message.success('编辑套餐成功')
              history.goBack()
            }).catch((error)=>{
              setIsLoading(false)
              message.error(error)
            })
          }
        }else{
          // 新建
          customRequest((res) =>{
            if(res.status === 200){
              formParamsTemp.img = res.url
              mealAddRequest(formParamsTemp).then(res =>{
                message.success('添加套餐成功')
                history.goBack()
              }).catch((error)=>{
                setIsLoading(false)
                message.error(error)
              })
            }
          }).catch(err =>{
            setIsLoading(false)
          })
        }
      }
    }

    // 校验提交数据
    const validatorParams = () =>{
      if(!formParams.name){
        message.error('请填写套餐名称')
        return false
      }
      if(!formParams.money){
        message.error('请填写标准价格')
        return false
      }
      if(!formParams.desc){
        message.error('请填写套餐描述')
        return false
      }
      if(!formParams.img){
        message.error('请选择项目海报')
        return false
      }
      return true
    }

    
    // 服务项目表格创建
    const createServices = () => {
      let list = formParams.content
      return <Spin spinning={isLoading}>
        {
          list.map((item,index) =>{
            return <Row  justify="end" className="row_item boborder" key={index+ item.name} >
              <Col span={5} className="child_title">{item.name}</Col>
                <Col span={19}>
                  {
                    item.value.map((_item,_index) =>{
                      return <Row
                          align="middle"
                          className={`child_row ${_index < item.value.length - 1 && 'boborder'}`}
                          key={_item.id}
                          onClick ={() => handleContent(index,_index,'choose')}
                      >
                      <Col  span={2} ><Image preview={false}  src={_item.isActive ? choose_active_icon:choose_icon} className="choose_icon" /></Col>
                    <Col  span={12} >{_item.name}{_item.version?'-'+_item.version:''}</Col>
                      <Col  span={5}  onClick={e => e.stopPropagation()}>
                        {
                          item.name === '系统' ? _item.num :
                          <InputNumber min={1} max={10} onChange={e => handleContent(index,_index,'value',e)} value={_item.num} className="num_input" />
                        }
                      </Col>
                      <Col  span={5} >{_item.price}</Col>
                    </Row>
                    })
                  }
                </Col>
            </Row>
          })
        }
      </Spin>
    }

    const  handleEditorChange = (editorState) => {
      setEditorState(editorState)
    }
    
    // 图片上传、视频上传
    const UploadFun = (param) =>{
      console.log('上传文件',param)
      if(param.file.type.indexOf('video') !== -1){
          console.log('视频上传')
          if(param.file.type.indexOf('mp4') !== -1){
              const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.mp4`; // 生成随机key
              startUpload(key,'video')

          }else{
              message.error('视频格式只支持mp4')
          }
      }else{
          const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`; // 生成随机key
          console.log('图片上传')
          startUpload(key,'image')
      }
     
     function startUpload (key,type){
          // 获取七牛 token
        getQiniuToken({type: 'food'}).then(res =>{
          console.log(res)
            let okParam = {}
            if(type === 'video') okParam.meta = {controls: true}
            let observer = {
                complete: (res) => param.success({...okParam, url: res.url}),
                error: (error) =>  {message.error('上传资源失败'); param.error(error.message)},
                next: (next) => param.progress(next.total.percent)
            }
            qiniu_upload(param.file,key,res.upload_token,observer,{customVars: {'x:type': 'food'}})
            
        }).catch(error => {
            message.error(error || '获取七牛token失败')
        })
     }
  }

    return <div className="mealManageDetail">
       <div className="detail_body_info">
       <div className="title">详细信息</div>
        <div className="form_item">
            <div className="item_title">套餐名称:</div>
            <Input className="item_value" value={formParams.name} onInput={e => handleFormParams(e,'name')} placeholder="请输入套餐名称" />
        </div>
        <div className="form_item  align-start">
            <div className="item_title">套餐内容:</div>
            <div className="item_value">
              <Row align="middle" className="row_head">
                <Col span={5} flex>服务类型</Col>
                <Col span={19} >
                  <Row>
                    <Col  span={14} flex>服务名称</Col>
                    <Col  span={5} flex>数量</Col>
                    <Col  span={5} flex>收费标准</Col>
                  </Row>
                </Col>
              </Row>
              {createServices()}
              <Row  justify="center" align="middle" className="row_item boborder count_row">
                <Col span={16}>
                  {
                    countParams.chooseArrary.length === 0 ? '未选择任何服务':  countParams.chooseArrary.join('、')
                  }
                </Col>
                <Col span={3} >合计</Col>
                <Col span={5} >¥{countParams.price}</Col>
              </Row>
            </div>
        </div>
        <div className="form_item">
            <div className="item_title">标准价格:</div>
            <Input type="number" className="item_value"  value={formParams.money} onInput={e => handleFormParams(e,'money')}  placeholder="请输入标准价格" />
        </div>
        <div className="form_item">
            <div className="item_title">套餐描述:</div>
            <Input className="item_value"  value={formParams.desc} onInput={e => handleFormParams(e,'desc')}  placeholder="请输入套餐描述" />
        </div>
        <div className="form_item align-start">
            <div className="item_title">项目海报:</div>
           <div>{createUpload()}</div>
        </div>
        <div className="form_item align-start">
            <div className="item_title">内容详情:</div>
           <div className="item_edit">
            <BraftEditor
                    media={{
                      uploadFn: UploadFun,
                      accepts: {
                          video: true,
                          image: true,
                          audio: false,
                          embed: false
                      }
                    }}              
                    value={editorState}
                    onChange={handleEditorChange}
                />
            </div>
        </div>
       </div>
      <div className='action_button'>
      <Button loading={isLoading} type="primary" onClick={submitParams}>确定</Button>
      </div>
    </div>
}

export default MealManageDetail;