import React, { useState, useEffect } from 'react'
// 引入编辑器组件
import BraftEditor from 'braft-editor'
// 引入编辑器样式
import 'braft-editor/dist/index.css'
import {
    Button,
    Input,
    Select,
    Image,
    message,
    Space,
} from 'antd'
import { PlusOutlined,PlusCircleOutlined,MinusCircleOutlined } from '@ant-design/icons';
import {qiniu_upload} from '../../../../utils/qiniuUpload'
import {incrementAddRequest, incrementDetailRequest} from '../../../../services/systemService'
import {getQiniuToken} from '../../../../services/commonService'
import { useHistory } from 'react-router-dom'

import './detail.scss'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const { Option } = Select;
const { TextArea } = Input;
const selectData = [{id: -1,name:'请选择服务类型'},{id: 0,name:'系统'},{id: 1,name:'硬件'}]
const unitList = [] 


const SystemPriceDetail = (props) =>{
    const [editorState,setEditorState] = useState( BraftEditor.createEditorState(null))
    const [formParams,setFormParams] = useState({
      name: '', //增值服务
      desc: '', // 描述
      unit: '',  // 使用周期
      unit_num: '', // 使用周期单位
      price: '', // 价格
      service_content: [''], //服务内容
      img: '', // 海报
      version: '',
      service_type: -1,
      detail: {'text': ''},
    })
    const [isLoading,setIsLoading] = useState(false)
    const [currentId,setCurrentId] = useState(0)
    const history = useHistory()

    useEffect(() =>{
      if(history.location.state){
        setCurrentId(history.location.state.id)
        getIncrementDetail(history.location.state.id)
      }
    },[])

    // 获取增值服务详情
    const getIncrementDetail = (id) =>{
      incrementDetailRequest({id}).then(res =>{
        setFormParams({
          ...formParams,
          name: res.name,
          version: res.version,
          unit: res.unit.split('/')[0],
          unit_num: res.unit.split('/')[1],
          desc: res.desc,
          price: res.price,
          service_content: JSON.parse(res.service_content),
          img: res.img,
          service_type:res.service_type
        })
        setEditorState(BraftEditor.createEditorState(res.content_text))
      }).catch((error)=>{
        setIsLoading(false)
        message.error(error)
      })
    }

    const addContent =() =>{
      let formParamsTemp = JSON.parse(JSON.stringify(formParams))
      formParamsTemp.service_content.push('')
      setFormParams(formParamsTemp)
    }
    
    const delContent =(index) =>{
      let formParamsTemp = JSON.parse(JSON.stringify(formParams))
      formParamsTemp.service_content.splice(index,1)
      setFormParams(formParamsTemp)
    }

    // 上传海报创建
    const createUpload = () =>{
      function clickFile(){
        document.getElementById("fileImage").click(); 
      }
      return <>
          <input id="fileImage" multiple accept=".png,.jpeg,.jpg" style={{display: 'none'}} type="file" onChange={chooseFile}></input>
          <div className="poster_view" >
          <div className="upload_view flex-center">
            {
              formParams.img ?
              <Image preview={false} onClick={clickFile} src={formParams.img} className="upload_img"/>
              :
              <PlusOutlined onClick={clickFile} style={{fontSize: 32,color: '#F0F0F0'}} />
            }
             </div>
            <div>(建议上传尺寸为1:1)</div>
          </div>
        </>
    }
    // 显示选择图片
    const chooseFile = () =>{
      let files = document.querySelector('#fileImage').files;
      if(files && files.length > 0){
        getBase64(files[0]).then(res =>{
          setFormParams({ ...formParams,img: res})
        })
      }
    }

    const  handleEditorChange = (editorState) => {
      setEditorState(editorState)
    }

    // 监听提交表单变化
    const handleFormParams = (e,type,index = -1) =>{
      let formParamsTemp = JSON.parse(JSON.stringify(formParams))
      switch(type){
        case 'service_content':
          formParamsTemp.service_content[index] = e.target.value
          setFormParams({...formParamsTemp,service_content: formParamsTemp.service_content})
          break;
        case 'price':
          formParamsTemp.price = checkNumber(e)
          setFormParams({...formParamsTemp})     
          break;
        case 'unit':
          formParamsTemp.unit = checkNumber(e)
          setFormParams({...formParamsTemp})     
          break;
        default:
          formParamsTemp[type] = e
          setFormParams({...formParamsTemp})     
          break
      }      
    }

    const checkNumber = (value) => {
        value = value.replace(/[^\d.]/g,"")
        value = value.replace(/\.{2,}/g,".")
        value = value.replace(".","$#$").replace(/\./g,"").replace("$#$",".")
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
        if(value.indexOf(".")< 0 && value !=""){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
            value= parseFloat(value);  
        }
        return value
    }

    // 校验提交数据
    const validatorParams = () =>{
      if(formParams.service_type==-1){
        message.error('请选择服务类型')
        return false
      }
      if(!formParams.name){
        message.error('请填写名称')
        return false
      }
      if(!formParams.version){
        message.error('请填写版本名称')
        return false
      }
      if(!formParams.desc){
        message.error('请输入描述')
        return false
      }
      if(!formParams.unit){
        message.error('请输入使用周期')
        return false
      }
      if(!formParams.unit_num){
        message.error('请输入使用周期单位')
        return false
      }
      if(!formParams.price){
        message.error('请输入标准价格')
        return false
      }
      let index = formParams.service_content.findIndex(item => !item)
      if(index != -1){
        message.error('请填服务内容')
        return false
      }      
      if(!formParams.img){
        message.error('请选择项目海报')
        return false
      }
      return true
    }
    
    // 提交数据
    const submitParams = () =>{
    
      let flag = validatorParams()
      if(flag){
        let formParamsTemp = JSON.parse(JSON.stringify(formParams))
        formParamsTemp.service_content = JSON.stringify( formParamsTemp.service_content)
        formParamsTemp.unit = formParamsTemp.unit+'/'+formParamsTemp.unit_num
        delete formParamsTemp.unit_num
        formParamsTemp.detail = JSON.stringify(formParamsTemp.detail)
        // 整理提交数据
        formParamsTemp.content_text = editorState.toHTML()
        if(history.location.state) formParamsTemp.id = history.location.state.id
        setIsLoading(true)
          // 新建
        customRequest((res) =>{
          if(res.status === 200){
            formParamsTemp.img = res.url
            incrementAddRequest(formParamsTemp).then(res =>{
              setIsLoading(false)
              message.success('添加套餐成功')
              history.goBack()
            }).catch((error)=>{
              setIsLoading(false)
              message.error(error)
            })
          }
        }).catch(err =>{
          setIsLoading(false)
        })
      }
    }

    // 获取key
    const getQiniuKey = () =>{
      return `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`; // 生成随机key
    }

    const customRequest = async (callBack) =>{
      let files = document.querySelector('#fileImage').files;
      if(files && files.length > 0){
        getQiniuToken({type: 'food'}).then( async res =>{
                  let imageKey = getQiniuKey()
                  let observer = {
                      complete: (res) => {
                          callBack({status: 200,url:res.url})
                          console.log('上传成功',res)
                      },
                      error: (error) =>  {
                          message.error(error || '上传资源失败');
                          callBack({status: 500,error})
                          return
                      },
                      next: (next) => {
                          console.log('上传进度',next.total.percent)
                          return
                      }
                  }
                  qiniu_upload(files[0],imageKey,res.upload_token,observer,{customVars: {'x:type': 'food'}})
                  
              }).catch(error => {
                  message.error(error || '获取七牛token失败')
              })
      }
    }

    // 图片上传、视频上传
    const UploadFun = (param) =>{
        console.log('上传文件',param)
        if(param.file.type.indexOf('video') !== -1){
            console.log('视频上传')
            if(param.file.type.indexOf('mp4') !== -1){
                const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.mp4`; // 生成随机key
                startUpload(key,'video')

            }else{
                message.error('视频格式只支持mp4')
            }
        }else{
            const key = `${Math.random().toString(36).slice(-8)}${new Date().valueOf()}.png`; // 生成随机key
            console.log('图片上传')
            startUpload(key,'image')
        }
       
       function startUpload (key,type){
            // 获取七牛 token
          getQiniuToken({type: 'food'}).then(res =>{
            console.log(res)
              let okParam = {}
              if(type === 'video') okParam.meta = {controls: true}
              let observer = {
                  complete: (res) => param.success({...okParam, url: res.url}),
                  error: (error) =>  {message.error('上传资源失败'); param.error(error.message)},
                  next: (next) => param.progress(next.total.percent)
              }
              qiniu_upload(param.file,key,res.upload_token,observer,{customVars: {'x:type': 'food'}})
              
          }).catch(error => {
              message.error(error || '获取七牛token失败')
          })
       }
    }


    return <div className="systemPriceDetail">
       <div className="detail_body_info">
       <div className="title">详细信息</div>
        <div className="form_item">
            <div className="item_title">服务类型:</div>
            <Select
              onChange={(e) =>{handleFormParams(e,'service_type')}}
              value={formParams.service_type}
              className="item_value"
            >
              {selectData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
            </Select>
        </div>
        <div className="form_item">
            <div className="item_title">服务项目:</div>
            <Input className="item_value" placeholder="请输入服务项目名称" value={formParams.name} onChange={(e)=>{handleFormParams(e.currentTarget.value,'name')}} />
        </div>
        <div className="form_item">
            <div className="item_title">版本:</div>
            <Input className="item_value" placeholder="请输入版本名称" value={formParams.version} onChange={(e)=>{handleFormParams(e.currentTarget.value,'version')}} />
        </div>
        <div className="form_item">
            <div className="item_title">描述:</div>
            <Input className="item_value" placeholder="请输入描述"  value={formParams.desc} onChange={(e)=>{handleFormParams(e.currentTarget.value,'desc')}}/>
        </div>
        <div className="form_item">
            <div className="item_title">使用周期:</div>
            <Input className="item_value" placeholder="请输入使用周期" value={formParams.unit} onChange={(e)=>{handleFormParams(e.currentTarget.value,'unit')}}/>
        </div>
        <div className="form_item">
            <div className="item_title">使用周期单位:</div>
            <Input className="item_value" placeholder="请输入使用周期单位，例年、篇" value={formParams.unit_num} onChange={(e)=>{handleFormParams(e.currentTarget.value,'unit_num')}}/>
        </div>
        <div className="form_item">
            <div className="item_title">标准价格:</div>
            <Input className="item_value" placeholder="请输入标准价格"  value={formParams.price} onChange={(e)=>{handleFormParams(e.currentTarget.value,'price')}}/>
        </div>
        <div className="form_item align-start">
            <div className="item_title">服务内容:</div>
            <div>
            {
              formParams.service_content.map((item,index) =>{
                return  <div className="service_content" key={index}>
                          <Input className="item_value" value={item} onInput={e => handleFormParams(e,'service_content',index)}  />
                          <Space className="add_del">
                            {index == 0 && <PlusCircleOutlined  style={{fontSize: 18,color: 'red'}} onClick={() => addContent()}/>}
                            {
                              index != 0 && <MinusCircleOutlined style={{fontSize: 18}} onClick={() => delContent(index)}/>
                            }
                          </Space>
                        </div>
              })
            }
            </div>
            
        </div>
        {/* <div className="form_item align-start">
            <div className="item_title">服务内容:</div>
            <TextArea allowClear counter={'200'} className="item_value" placeholder="请输入服务内容" maxLength={200} autoSize={{ minRows: 4, maxRows: 4 }} onChange={(e)=>{handleFormParams(e.currentTarget.value,'price')}}/>
        </div> */}
        <div className="form_item align-start">
            <div className="item_title">项目海报:</div>
           <div>{createUpload()}</div>
        </div>
        {/* <div className="form_item align-start">
            <div className="item_title">内容详情:</div>
           <div className="item_edit">
            <BraftEditor
                    media={{
                      uploadFn: UploadFun,
                      accepts: {
                          video: true,
                          image: true,
                          audio: false,
                          embed: false
                      }
                    }}            
                    value={editorState}
                    onChange={handleEditorChange}
                />
            </div>
        </div> */}
       </div>
       {
         currentId == 0 &&<div className='action_button'>
          <Button type="primary" loading={isLoading} onClick={()=>{submitParams()}}>确定</Button>
        </div>
       }

    </div>
}

export default SystemPriceDetail;