import React, {useEffect, useState} from "react";
import { Upload, Modal, message, Image } from 'antd';
import { getQiniuToken } from '../../../../../services/commonService'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
// import business_license from './../../../../assret/images/store_auth/business_license.png'
// import cashier_desk_pic from './../../../../assret/images/store_auth/cashier_desk_pic.png'
// import contact_bank_card from './../../../../assret/images/store_auth/contact_bank_card.png'
// import id_card_front from './../../../../assret/images/store_auth/id_card_front.png'
// import id_card_reverse from './../../../../assret/images/store_auth/id_card_reverse.png'
// import hand_id_card from './../../../../assret/images/store_auth/hand_id_card.jpg'
// import shop_inner_pic from './../../../../assret/images/store_auth/shop_inner_pic.png'
// import shop_exterior_pic from './../../../../assret/images/store_auth/shop_exterior_pic.png'


const UploadImg = (props) => {
    const { onChange, imgUrl, callBack, label, field } = props

    const [ updateObj, setUploadObj ] = useState({})
    const [ loading, setLoading ] = useState(false)
    const [ imageUrl, setImageUrl ] = useState('')
    const [ previewImage, setPreviewImage ] = useState('')
    const [ previewVisible, setpreviewVisible ] = useState(false)
    const [ fileList, setFileList ] = useState([])


    useEffect(()=>{
        console.log('field:',field)
        setImageUrl(imgUrl)
        if(imgUrl){
            setFileList([{
                uid: '-1',
                name: new Date()+'.png',
                status: 'done',
                url: imgUrl,
                thumbUrl: imgUrl,
            }])
        }
    },[imgUrl])

    const QINIU_SERVER = 'https://up-z2.qiniup.com';
    // 获取图片token
    const beforeUpload = async (file) => {
        try{
            const res = await getQiniuToken({ type: 'food' })
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
            const obj = { token: res.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
            setUploadObj(obj)
        }catch(err){
            message.error(err||'获取七牛token失败')
        }
    }
    const handleChange = async(info) => {
        setFileList(info.fileList)
        if(info.fileList.length<=0)setImageUrl('')
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            let file = await getBase64(info.file.originFileObj);
            setImageUrl(info.file.response.url)
            setLoading(false)
            onChange(info.file.response.url)
            if(callBack){
                callBack(file.replace(/data:image\/.*;base64,/,''),info.file.response.url)
            }
        }
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
        }
        setpreviewVisible(true)
        setPreviewImage(file.url || file.preview)
    };

    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }    

    const handleCancel = () => setpreviewVisible(false);

    // const examplesImg = ()=>{
    //     switch(field){
    //         case 'business_license':
    //             return <img src={business_license} /> 
    //             break;
    //         case 'cashier_desk_pic':
    //             return <img src={cashier_desk_pic} /> 
    //             break;
    //         case 'contact_bank_card':
    //             return <img src={contact_bank_card} /> 
    //             break;
    //         case 'id_card_front':
    //             return <img src={id_card_front} /> 
    //             break;
    //         case 'id_card_reverse':
    //             return <img src={id_card_reverse} /> 
    //             break;
    //         case 'hand_id_card':
    //             return <img src={hand_id_card} /> 
    //             break;
    //         case 'shop_inner_pic':
    //             return <img src={shop_inner_pic} /> 
    //             break;
    //         case 'shop_exterior_pic':
    //             return <img src={shop_exterior_pic} /> 
    //             break;
    //         }
    // }
    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传凭证</div>
        </div>        
    );

    return(
        <>
            <div className="upload-row">
                <Upload
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={!loading}
                    action={QINIU_SERVER}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    data={updateObj}
                    onPreview={handlePreview}
                    fileList={fileList}
                    >    
                    {
                        !imageUrl&&<div className="">
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                    }
                </Upload>  
                {/* {
                    field&&(
                        <>
                            <span className="examples_span">示例图：</span>
                            <span className="examples_img" onClick={()=>{}}>
                                {examplesImg()}
                            </span>                        
                        </>
                    )
                } */}

            </div>                 
            <Modal visible={previewVisible} footer={null} onCancel={handleCancel}>
                <Image preview={true} src={previewImage} alt="loading" style={{ width: '100%' }} ></Image>
            </Modal>
        </>
    )
}

export default UploadImg