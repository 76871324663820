import React, { useEffect, useState } from 'react'
import { Row, Descriptions,message,Spin} from 'antd';
import { useHistory } from 'react-router-dom';

import {intentionDetailRequest} from '../../../../services/merchantService'

import './detail.scss'

const CustomerDetail = (props)=>{
    const history = useHistory()
    const [ info, setInfo] = useState({
        name: '', //商户名称
        real_name: '', //真实姓名
        mobile: '', //手机号
        salesman: '', // 推荐人 名称 -id  -->手动拼接
        shop_address: '', // 省市区 -->手动拼接
        address: '', //详细地址
        intention_ratio: 0, //意向度
        system_name: '', //意向类型
        remark: '', //备注

    })
    const [isLoading,setIsLoading] = useState(false)
    useEffect(() =>{
        if(history.location.state){
            getInfo(history.location.state.id)
        }else{
            history.goBack()
        }
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps        
    },[]);

    const getInfo = (id) =>{
        intentionDetailRequest({id}).then(res => {
            setInfo({
                ...info,
                ...res,
                shop_address: res.province+'/'+res.city+ '/'+res.area,
                salesman: res.salesman_name + '-' + res.salesman_id
            })
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    return(
        <Spin spinning={isLoading}>
            <div className="customer_detail">
            <Row type="flex" align="middle">
                <span className="mb20 mr20">{info.name}</span>
            </Row>
            <div className="detail_body_info">
                <Descriptions title="基本信息" bordered>
                    <Descriptions.Item label="真实姓名">{info.real_name}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{info.mobile}</Descriptions.Item>
                    <Descriptions.Item label="地区">{info.shop_address}</Descriptions.Item>
                    <Descriptions.Item label="门店地址" span={3} style={{textAlign:"left"}}>{info.address}</Descriptions.Item>
                    <Descriptions.Item label="意向度">
                        {/* {info.intention_ratio/100}% */}
                        {info.intention_ratio ? info.intention_ratio === info.max_intention_ratio ? info.intention_ratio + '%' : info.intention_ratio  + '%-' + info.max_intention_ratio + '%' : ''}
                    </Descriptions.Item>
                    <Descriptions.Item label="推荐人">{info.salesman} </Descriptions.Item>
                    <Descriptions.Item label="意向类型">{info.system_name}</Descriptions.Item>
                </Descriptions> 
            </div>
            <div className="detail_body_info">
                <div className="remark">备注</div>
                <div className="remark_content">{info.remark ? info.remark: '未添加备注'}</div>             
            </div>
                              
        </div>
        </Spin>
    )

}

export default CustomerDetail