import { request } from '../api/http';
// 业务员列表
export async function saleManListRequest(params) {
    return request({
        url: '/salesman/list',
        params,
        method:'get'
    });
}
// 业务员冻结 解冻
export async function saleManEditStatusRequest(params) {
    return request({
        url: '/salesman/edit_status',
        params,
        method:'post'
    });
}
