import moment from 'moment';
import Convert from './converter';
// 设置局部环境
moment.locale('en');
// 提交审核满足条件:已上传代码以及不在审核状态,已提交成功状态
const submitAuditFilter = (list = []) => {
    return list.filter((item) => {
        return enableSubmitAuditAction(item);
    });
};

const enableSubmitAuditAction = (item) => {
    return (
        item.commit_code_status === Convert.MP_COMMIT_CODE_SUCCESS &&
        item.submit_audit_status !== Convert.MP_AUDIT_SUCCESS &&
        item.submit_audit_status !== Convert.MP_AUDIT_ING
    );
};

// 撤销:当前属于审核中的状态
const undoAuditFilter = (list = []) => {
    return list.filter((item) => {
        return enableUndoAction(item);
    });
};

// 撤销
const enableUndoAction = (item) => {
    return item.submit_audit_status === Convert.MP_AUDIT_ING;
};

// 发布满足条件:审核成功,并且还未发布成功
const releaseFilter = (list = []) => {
    return list.filter((item) => {
        return enableReleaseAction(item);
    });
};

// 控制按钮是否可以继续下一步操作
const enableReleaseAction = (item) => {
    return item.submit_audit_status === Convert.MP_AUDIT_SUCCESS && item.release_status !== Convert.MP_RELEASE_SUCCESS;
};

// previous_release_version 不为空表示最少有发布过两次版本
const enableRevertReleaseAction = (item) => {
    return item.previous_release_version;
};

const getMonthWeek = (year, month, day) => {
    const date = new Date(year, parseInt(month) - 1, day);
    return Math.ceil((date.getDate() + 6 - date.getDay()) / 7);
};

const isSameWeek = (time1, time2) => {
    const m1 = time1.split(' ')[0].split('-');
    const m2 = time2.split(' ')[0].split('-');
    // 同年 同月 同周
    return m1[0] === m2[0] && m1[1] === m2[1] && getMonthWeek(m1[0], m1[1], m1[2]) == getMonthWeek(m2[0], m2[1], m2[2]);
};

// (年月不同也需要输入)不同周 需要输入密码
const isMustInputPasswdOfAudit = (serverTimeStamp, list = []) => {
    for (let index = 0; index < list.length; index++) {
        const { latest_submit_audit_time } = list[index];
        if (isSameWeek(serverTimeStamp, latest_submit_audit_time)) {
            return true;
        }
    }
    return false;
};

export default {
    submitAuditFilter,
    undoAuditFilter,
    releaseFilter,
    enableSubmitAuditAction,
    enableUndoAction,
    enableReleaseAction,
    enableRevertReleaseAction,
    isMustInputPasswdOfAudit,
};
