import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    Button,
    Input,
    DatePicker,
    Table,
    Tooltip,
    Image,
    message
} from 'antd'
import {IconFont} from '../../../utils/common'
import detail_icon from '../../../assets/images/detail_icon.png'

import {authIntentionRequest} from '../../../services/merchantService'
import './purposeClient.scss'
const {Search} = Input
const { RangePicker } = DatePicker;
const PurposeClient = (props) => {

    let history = useHistory();

    const [isLoading,setIsLoading] = useState(false)
    const [ downloadLoading, setDownloadLoading] = useState(false)
    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        timer: []
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })
    useEffect(() =>{
        getPageList(pageParams)
    },[])
   // 获取页面列表
   const getPageList = (params) =>{
        setIsLoading(true)
        authIntentionRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
       
        if(serachParams_temp.timer.length > 0) {
            params.created_start = serachParams_temp.timer[0]
            params.created_end = serachParams_temp.timer[1]
        }
        getPageList(params)
    }


    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'timer':
                serachParams_temp = {...serachParams,timer: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }

    const columns = [
        {
            title: '商户名称',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '真实姓名',
            dataIndex: 'real_name',
            key: 'real_name',
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '地区',
            render: (text,record) => record.province + '/' + record.city + (record.area?'/' + record.area:'')
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '意向度',
            dataIndex: 'intention_ratio',
            key: 'intention_ratio',
            // render: text => text /100 + '%'
            render: (text,record)=> 
            record.intention_ratio ? record.intention_ratio === record.max_intention_ratio ? record.intention_ratio + '%' : record.intention_ratio  + '%-' + record.max_intention_ratio + '%' : ''
        },
        {
            title: '意向类型',
            dataIndex: 'system_name',
            key: 'system_name'
        },
        {
            title: '业务员',
            render: (text,record) => record.salesman_name + '-'+record.salesman_id
        },
        {
            title: '操作',
            render: (text,record) =>  <Tooltip placement="top" title={'详情'}>
            <Image src={detail_icon} className="action_icon"  preview={false} onClick={() => {
                history.push("/sidebar/merchantManage/purposeClient/detail",{id: record.id})
            }}></Image>
         </Tooltip>
        }
    ]

    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    
    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        authIntentionRequest({page:1,pageSize:3000}).then(response=>{
            import("../../../utils/Export2Excel").then((excel) => {
                const tHeader = ["商户名称", "真实姓名", "手机号码", "地区", "地址", "创建时间", "意向度", "意向类型", "业务员"];
                const filterVal = ["name", "real_name", "mobile", "address_str", "address", "created_at", "intention_ratio_str", "system_name", "salesman_str"];
                let dataArr = response.data.map((item,index)=>{
                    item.address_str = item.province+item.city+item.area
                    item.intention_ratio_str = item.intention_ratio ? item.intention_ratio === item.max_intention_ratio ? item.intention_ratio + '%' : item.intention_ratio  + '%-' + item.max_intention_ratio + '%' : ''
                    item.salesman_str = item.salesman_name + '-'+item.salesman_id
                    return item
                })
                const dataSourse = formatJson(filterVal, dataArr);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '意向客户', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }
    
    return <div className="salesmanManage">
         <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />} onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
         <div className="content_body">
            <div className="screen-dart">
                    <Search
                        placeholder="输入姓名、手机号搜索"
                        className="input_view"
                        onSearch={(e) => handleSerachForm(e,'search')}
                        onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
                    />
                    <div className="screen-item">
                        <div className="item-title">创建时间:</div>
                        <RangePicker
                            suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                            onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'timer')}
                            timer={serachParams.timer}
                        />
                    </div>
                </div>
                <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={pageInfo.list}
                    rowKey='id'
                    pagination={pagination}
                />
        </div>
            
           
        </div>
}

export default PurposeClient
