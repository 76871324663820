import React, { useState, useEffect } from 'react';
import { Button, Input, Image, Collapse,Radio,Checkbox, Modal, Select, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { getShopProblem, saveTask, delTask, saveProblem, delProblem, saveProblemAnswer } from '../../../../services/task'
import { IconFont } from '../../../../utils/common'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { stores } from '../../../../stores/store'
import { Observer } from 'mobx-react';
import edit_icon from '../../../../assets/images/edit_icon.png'
import delete_icon from '../../../../assets/images/delete_icon.png'
import './role.scss'

const { Panel } = Collapse;
const { Option } = Select;


const TaskDetailRole = (props) => {
    const { id, role, node_id } = props
    const [ visible, setVisible ] = useState(false)
	const [ info, setInfo ] = useState([])
	const [ taskOpenKey, setTaskOpenKey ] = useState([])
	const [ problemOpenKey, setProblemOpenKey ] = useState([])
	const [ loading, setLoading ] = useState(false)
	const [ problemInfo, setProblemInfo ] = useState({
		problem_id: 0,
		problem_name: "",
		problem_type: 0,
		items:[]
	})

    useEffect(()=>{
        _init()
    },[id])

    const _init = () => {
        let params = {
            apply_id:id,
            role:role,
            id:node_id,
        }
        getShopProblem(params).then(res=>{
			console.log('res',res)
			//展开所有的折叠面板
			let taskOpenKeyTemp = []
			let problemOpenKeyTemp = []
			let resTemp = []
			res.map((item,index)=>{
				taskOpenKeyTemp.push(index)
				item.items.map((subItem,subIndex)=>{
					item.items[subIndex].content = subItem.problem_type===2?[]:''
					subItem.items.map((lItem,lIndex)=>{
						if(subItem.problem_type===1&&lItem.selectd){
							item.items[subIndex].content = lItem.answer_id
						}
						if(subItem.problem_type===2&&lItem.selectd){
							item.items[subIndex].content.push(lItem.answer_id)
						}
					})
					if(subItem.problem_type===3){
						item.items[subIndex].content = subItem.answer_value
					}
					problemOpenKeyTemp.push(subIndex)
				})
				resTemp.push(item)
			})

			setInfo(resTemp)
			setTaskOpenKey(taskOpenKeyTemp)
			setProblemOpenKey(problemOpenKeyTemp)
        }).catch(err=>{
            message.error(err||'留言板信息获取失败')
        })
    }

    //折叠回调
    const changeCollapse = (key) => {
		console.log(key)
		setTaskOpenKey(key)
    }
    //折叠回调
    const changeSubCollapse = (key) => {
		console.log(key)
		setProblemOpenKey(key)
    }

	//添加任务
	const addTask = () => {
		let tempInfo = JSON.parse(JSON.stringify(info))
		tempInfo.push({
			task_id: 0, //任务ID
            task_name: "", //任务名称
			items: [],
			is_add:true
		})
		setInfo(tempInfo)
	}
	//编辑任务
	const editTask = (index) => {
		let tempInfo = JSON.parse(JSON.stringify(info))
		tempInfo[index].is_add = !tempInfo[index].is_add
		setInfo(tempInfo)
	}

	const changeInput = (value,index) => {
		let tempInfo = JSON.parse(JSON.stringify(info))
		tempInfo[index].task_name = value
		setInfo(tempInfo)
	}
	//提交任务
	const submitTask = (index) => {

		if(!info[index].task_name){
			message.error('请输入任务名称')
			return false
		}

		let params = {
			role: role, //工作角色 1客服 2技术
			task: info[index].task_name, //任务
			node_id: node_id,
			// id: '', //任务ID（修改时传）
		}

		if(info[index].task_id) params.id = info[index].task_id

		saveTask(params).then(res=>{
			// _init()
			info[index].task_id = res.id
			info[index].task_name = res.task
			info[index].is_add = false
			message.success(info[index].task_id?'修改任务成功':'提交任务成功')
		}).catch(err=>{
			message.error(err||(info[index].task_id?'修改任务失败':'提交任务失败'))
		})
	}

	//删除任务
	const deleteTask = (e,index) => {
		e.stopPropagation()
		if(!info[index].task_id){
			let tempInfo = JSON.parse(JSON.stringify(info))
			tempInfo.splice(index,1)
			setInfo(tempInfo)
			return false
		}
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除任务',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
				let params = {
					id:info[index].task_id
				}
				delTask(params).then(res=>{
					message.success('删除成功')
					let tempInfo = JSON.parse(JSON.stringify(info))
					tempInfo.splice(index,1)
					setInfo(tempInfo)
				}).catch(err=>{
					message.error(err||'删除失败')
				})
            }
        });
	}

	//添加答案
	const addAnswer = () => {
		let problemInfoTemp = JSON.parse(JSON.stringify(problemInfo))
		problemInfoTemp.items.push({
			answer_id: 0,
			answer_value: "",
			// isAdd:true
		})
		setProblemInfo(problemInfoTemp)
	}

	//删除答案
	const delAnswer = () => {
		let problemInfoTemp = JSON.parse(JSON.stringify(problemInfo))
		problemInfoTemp.items.splice(problemInfo.items.length-1,1)
		setProblemInfo(problemInfoTemp)
	}

	//监听问题修改
	const changeProblem = (value,field,index) => {
		let problemInfoTemp = JSON.parse(JSON.stringify(problemInfo))
		if(field === 'answer_value'){
			problemInfoTemp.items[index].answer_value = value
		}else{
			problemInfoTemp[field] = value
			if(problemInfoTemp['items'].length==0&&!problemInfoTemp.problem_id&&problemInfoTemp.problem_type===1){
				problemInfoTemp.items = [
					{answer_id: '',answer_value: "是",},
					{answer_id: '',answer_value: "否",}
				]
			}
		}
		setProblemInfo(problemInfoTemp)
	}
	//打开编辑问题弹窗
    const openModal = (e,task_id,info)=>{
		console.log(info)
		if(info){
			info.task_id = task_id
			setProblemInfo(info) //如果有问题信息，则记录
		}else{
			setProblemInfo({
				problem_id: 0,
				problem_name: "",
				problem_type: 0,
				items:[],
				task_id:task_id
			})
		}
        e.stopPropagation()
        setVisible(true)
    }

	//删除问答
	const deleteProblem = (e,pIndex,index) => {
		e.stopPropagation()
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除任务',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
				let params = {
					id: info[pIndex].items[index].problem_id
				}
				delProblem(params).then(res=>{
					_init()
					message.success('删除成功')
				}).catch(err=>{
					message.error(err||'删除失败')
				})
            }
        });
	}

	//提交问题
    const handleOk = ()=>{
		console.log(problemInfo)
		if(!problemInfo.problem_name){
			message.error('请输入问题名称')
			return false
		}
		if(!problemInfo.problem_type){
			message.error('请选择答案类型')
			return false
		}
		if(problemInfo.problem_type===1||problemInfo.problem_type===2){
			if(problemInfo.items.length===0){
				message.error('请至少添加一个选项')
				return false
			}
		}
		let answerArr = []
		problemInfo.items.map((item,indedx)=>{
			answerArr.push(item.answer_value)
		})
		let fromVal = {
			task_id: problemInfo.task_id,  //任务ID
			problem: problemInfo.problem_name,  //问题
			// answer: '',  //答案（可选，多个答案英文逗号隔开）
			type: problemInfo.problem_type,  //题型 1单选 2多选 3问答
			// id: problemInfo,  //问题ID（修改时传
		}
		console.log(answerArr)
		if(problemInfo.problem_id) fromVal.id = problemInfo.problem_id
		if(problemInfo.problem_type!=3) fromVal.answer = answerArr.join(',')
		
		saveProblem(fromVal).then(res=>{
			_init()
			setVisible(false)
			message.success(problemInfo.problem_id?'修改子任务问题成功':'提交子任务问题成功')
		}).catch(err=>{
			message.error(err||(problemInfo.problem_id?'修改子任务问题失败':'提交子任务问题失败'))
		})

    }

    const handleCancel = () => {
        setVisible(false)
    }

	const changeField = (value,pIndex,qIndex) => {
		let infoTemp = JSON.parse(JSON.stringify(info))
		infoTemp[pIndex].items[qIndex].content = value
		setInfo(infoTemp)
	}

	//提交任务
	const onSubmit = () => {
		let contentTemp = []
		info.map((item,index)=>{
			item.items.map((subItem,subIndex)=>{
				if(subItem.content){
					contentTemp.push({[subItem.problem_id]:subItem.content})
				}
			})
		})
		if(contentTemp.length === 0){
			message.error('请至少填写一个问题')
			return false
		}
		setLoading(true)
		let fromVal = {
			role: role, //工作角色 1客服 2技术
			apply_id: id, //商家申请ID
			content: JSON.stringify(contentTemp), //答案 （例如：[{"7":"不告诉你"},{"5":12},{"10":[17,18,19,20]}] 键为问题ID，值为答案id或内容）	
		}
		saveProblemAnswer(fromVal).then(res=>{
			setLoading(false)
			_init()
			message.success('提交成功')
		}).catch(err=>{
			setLoading(false)
			message.error('提交失败')
		})
		
	}

    return (
        <Observer render={() => <div className="task_detail_role">    
			{
				stores.userStore.adminInfo.group_operate_id === 0 && <div className="action">
					<Button type="default" icon={<IconFont type={'iconadd_icon'}  style={{fontSize:16}} />} onClick={(e)=>{addTask()}}>添加任务</Button>     
					{/* <Image src={add_work_icon}  onClick={(e)=>{openModal(e)}} preview={false}/> */}
				</div>
			}
            <div className="role_task">
                <Collapse onChange={changeCollapse} activeKey={taskOpenKey}>
					{
						info.map((item,index)=>{
							return <Panel 
								header={
									<div className="header">
										{
											item.is_add?<Input placeholder="请输入任务名称" value={item.task_name} onChange={(e)=>{changeInput(e.currentTarget.value.trim(),index)}} onBlur={(e)=>{submitTask(index)}} onPressEnter={(e)=>{submitTask(index)}}/>:item.task_name
										}
										{
											stores.userStore.adminInfo.group_operate_id === 0 && <div className="hearder_img">
												{
													item.task_id!=0 && <div className="hearder_add" onClick={(e)=>{openModal(e,item.task_id)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
												}
												<Image src={edit_icon} preview={false} onClick={()=>{editTask(index)}}/><Image src={delete_icon} onClick={(e)=>{deleteTask(e,index)}} preview={false}/>
											</div>
										}
									</div>
								} 
								// key="1"
								key={index}
							>
								<Collapse onChange={changeSubCollapse} activeKey={problemOpenKey}>
									{
										item.items.map((qItem,qIndex)=>{
											return <Panel 
												header={
													<div className="header">{qItem.problem_name}
														{
															stores.userStore.adminInfo.group_operate_id === 0 && <div className="hearder_img">
																<Image src={edit_icon} preview={false} onClick={(e)=>{openModal(e,item.task_id,qItem)}}/><Image src={delete_icon} preview={false} onClick={(e)=>{deleteProblem(e,index,qIndex)}}/>
															</div>
														}
													</div>
												} 
												// key="1"
												key={qIndex}
											>
												{
													qItem.problem_type === 1 ? (
														<Radio.Group value={qItem.content} onChange={(e)=>{changeField(e.target.value,index,qIndex)}}>
															{
																qItem.items.map((aItem,aIndex)=>{
																return <Radio key={'radio_'+aIndex} value={aItem.answer_id}>{aItem.answer_value}</Radio>
																})
															}
														</Radio.Group>
													):(
														qItem.problem_type === 2 ? (
															<Checkbox.Group value={qItem.content} onChange={(e)=>{changeField(e,index,qIndex)}}>
																{
																	qItem.items.map((aItem,aIndex)=>{
																		return <Checkbox key={'checkbox_'+aIndex} value={aItem.answer_id}>{aItem.answer_value}</Checkbox>
																	})
																}																
															</Checkbox.Group>
														):(
															<Input placeholder={"请输入"+qItem.problem_name} value={qItem.content} style={{width:450}} onChange={(e)=>{changeField(e.currentTarget.value,index,qIndex)}}/>
														)
													)
												}

											</Panel>

										})
									}
								</Collapse> 
							</Panel>
						})
					}
                </Collapse>         
            </div>
			<div className="footer">
				<Button onClick={()=>{onSubmit()}} loading={loading}>确定</Button>
			</div>       
            <Modal
                title={!problemInfo.problem_id?'添加子任务问题':"编辑子任务问题"}
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="task_add_modal"
                // footer={null}
                width={700}
            >
                {/* <div className="task_name">
                    子任务名称：<Input placeholder="请输入子任务名称"/>
                </div> */}
                <div className="task_content">
                    {/* <div className="task_sub_title">
                        编辑子任务问题
                        <Button type="default" icon={<IconFont type={'iconadd_icon'}  style={{fontSize:16}} />} onClick={(e)=>{openModal(e)}}>新增流程节点</Button>     
                    </div> */}
                    <div className="task_item">
                        <div className="task_item_name">
                            1.问题名称 
                            <Input placeholder="请输入问题名称" value={problemInfo.problem_name} onChange={(e)=>{changeProblem(e.currentTarget.value.trim(),'problem_name')}}/>
                            {/* <Image src={delete_icon} /> */}
                        </div>
                        <div className="task_item_content">
                            答案类型 ：
                            <Select className="task_item_select" value={problemInfo.problem_type} onChange={(e)=>{changeProblem(e,'problem_type')}}>
                                <Option value={0}>请选择</Option>
                                <Option value={1}>单选</Option>
                                <Option value={2}>多选</Option>
                                <Option value={3}>问答</Option>
                            </Select>
							{
								problemInfo.problem_type==1?(
									<div className="task_item_radio">
										<div>选项</div>
										<Radio.Group disabled>
											{
												problemInfo.items.map((item,index)=>{
													return <Radio key={index}>
														<Input placeholder="请输入选项" value={item.answer_value} style={{width:100}} onChange={(e)=>{changeProblem(e.currentTarget.value,'answer_value',index)}}/>
														{/* {
															item.isAdd?<Input placeholder="请输入选项" value={item.answer_value} style={{width:100}} onChange={(e)=>{changeProblem(e.currentTarget.value,'answer_value',index)}}/>:item.answer_value
														} */}
													</Radio>
												})
											}
											<div className="add" onClick={()=>{addAnswer()}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
											<Image src={delete_icon} preview={false} onClick={()=>{delAnswer()}}/>
										</Radio.Group>
									</div>
								):(
									problemInfo.problem_type==2?(
										<span className="task_item_checkbox">
											选项
											<Checkbox.Group disabled>
												{
													problemInfo.items.map((item,index)=>{
														return <Checkbox key={index}>
															<Input placeholder="请输入选项" value={item.answer_value} style={{width:100}} onChange={(e)=>{changeProblem(e.currentTarget.value,'answer_value',index)}}/>
															{/* {
																item.isAdd?<Input placeholder="请输入选项" value={item.answer_value} style={{width:100}} onChange={(e)=>{changeProblem(e.currentTarget.value,'answer_value',index)}}/>:item.answer_value
															} */}
														</Checkbox>
													})
												}
												<div className="add" onClick={()=>{addAnswer()}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
												<Image src={delete_icon} preview={false} onClick={()=>{delAnswer()}}/>												
											</Checkbox.Group>
										</span>
									):(
										problemInfo.problem_type==3?<span className="task_item_input">
											<Input style={{width:135}} disabled/>
										</span>:null										
									)
								)
							}
                        </div>
                    </div>
                </div>
            </Modal>             
        </div>
		} />
    )
}
export default TaskDetailRole