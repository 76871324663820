import { observable } from 'mobx'
import { setLocalStorage, getLocalStorage, TokenKey } from '../utils/storage'

export class UserStore {
    @observable token = '';
    @observable adminInfo = {};
    @observable adminMenuList = [];
    // 小程序提审额度
    @observable mpQuota = {
        limit:0,
        used:0
    }

    setToken = (str) =>{
        setLocalStorage(TokenKey,str)
    }
    getToken = () =>{
        let str = getLocalStorage(TokenKey)
        this.token = str
    }
    setAdminInfo = (obj) =>{
        setLocalStorage('adminInfo',obj)
    }
    getAdminInfo = () =>{
        let obj = getLocalStorage('adminInfo')
        this.adminInfo = obj
    }
    setMenuList = (arr) =>{
        setLocalStorage('adminMenuList',arr)
    }
    getMenuList = () =>{
        let arr = getLocalStorage('adminMenuList')
        this.adminMenuList = arr
    }

    setMpQuota = (quota)=>{
        this.mpQuota = quota;
    }

    getMpQuota = () => {
        this.mpQuota = {
            limit:0,
            used:0
        }
    }
    
}
