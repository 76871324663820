import React from 'react'
import { useHistory } from "react-router-dom";
import { 
    Button,
    Input,
    DatePicker,
    Tooltip,
    Image,
    Table,
    message
} from 'antd'
import {IconFont} from '../../../utils/common'
import detail_icon from '../../../assets/images/detail_icon.png'
import {shopListlRequest} from '../../../services/merchantService'
import './merchantList.scss'
import { useState } from 'react';
import { useEffect } from 'react';

const {Search} = Input
const { RangePicker } = DatePicker;

const MerchantList = (props) => {
    let history = useHistory();

    const [isLoading,setIsLoading] = useState(false)
    const [ downloadLoading, setDownloadLoading] = useState(false)
    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        catering_type: '', //餐饮类型
        timer: [], // 注册时间
        auth_timer: [], // 认证通过时间
        system_start_timer: [], //系统开始时间
        system_end_timer: [], //系统开始时间

        item_type: 0, //服务项目类型0：餐饮系统1：物料系统2：接龙3：私域增长
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])


    // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        shopListlRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.catering_type) params.catering_type = serachParams_temp.catering_type
        if(serachParams_temp.timer.length > 0) {
            params.created_start = serachParams_temp.timer[0]
            params.created_end = serachParams_temp.timer[1]
        }
        if(serachParams_temp.auth_timer.length > 0) {
            params.auth_start_time = serachParams_temp.auth_timer[0]
            params.auth_end_time = serachParams_temp.auth_timer[1]
        }
        if(serachParams_temp.system_start_timer.length > 0) {
            params.system_start_time_a = serachParams_temp.system_start_timer[0]
            params.system_start_time_b = serachParams_temp.system_start_timer[1]
        }
        if(serachParams_temp.system_end_timer.length > 0) {
            params.system_end_time_a = serachParams_temp.system_end_timer[0]
            params.system_end_time_b = serachParams_temp.system_end_timer[1]
        }
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'catering_type':
                serachParams_temp = {...serachParams,catering_type: e}
                break;
            case 'auth_timer':
                serachParams_temp = {...serachParams,auth_timer: e}
                break;
            case 'system_start_timer':
                serachParams_temp = {...serachParams,system_start_timer: e}
                break;
            case 'system_end_timer':
                serachParams_temp = {...serachParams,system_end_timer: e}
                break;
            case 'timer':
                serachParams_temp = {...serachParams,timer: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    const columns = [
        {
            title: '门店ID',
            dataIndex: 'shop_id',
            width: 100,
            key: 'shop_id',
            fixed: 'left',
        },
        {
            title: '商户名称',
            dataIndex: 'shop_name',
            width: 100,
            key: 'shop_name',
            fixed: 'left',
        },
        {
            title: '真实姓名',
            dataIndex: 'contact_name',
            key: 'contact_name',
        },
        {
            title: '手机号码',
            dataIndex: 'contact_phone',
            key: 'contact_phone',
        },
        {
            title: '注册时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '认证通过时间',
            dataIndex: 'auth_time',
            key: 'auth_time',
        },
        {
            title: '认证状态',
            dataIndex: 'status',
            key: 'status',
            render: text => text == 5 ? '审核失败':
                            text == 10 ? '审核成功': 
                            text == 0 ? '待审核': '审核中'
        },
        {
            title: '餐饮类型',
            dataIndex: 'catering_type',
            key: 'catering_type',
        },
        {
            title: '省市区',
            render: (text,record) => record.district ? record.province + record.city + record.district : ''
        },
        {
            title: '购买次数',
            dataIndex: 'pay_system_num',
            key: 'pay_system_num',
        },
        {
            title: '系统开始时间',
            dataIndex: 'system_start_time',
            key: 'system_start_time',
        },
        {
            title: '系统截止时间',
            dataIndex: 'system_end_time',
            key: 'system_end_time',
        },
        {
            title: '距截止日期剩余天数',
            dataIndex: 'date_part',
            key: 'date_part',
        },
        {
            title: '业务员',
            dataIndex: 'salesman_name',
            key: 'salesman_name',
        },
        {
            title: '业务员id',
            dataIndex: 'salesman_id',
            key: 'salesman_id',
        },
        {
            title: '操作',
            fixed: 'right',
            width: 100,
            render: (text,record) => <div>
                 <Tooltip placement="top" title={'详情'}>
                    <Image src={detail_icon} className="action_icon"  preview={false} onClick={() => {
                        history.push("/sidebar/merchantManage/merchantList/detail",{id: record.id})
                    }}></Image>
                 </Tooltip>
               
            </div>,
        },
    ]
    
    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        shopListlRequest({page:1,pageSize:3000}).then(response=>{
            import("../../../utils/Export2Excel").then((excel) => {
                const tHeader = ["门店ID", "商户名称", "真实姓名", "手机号码", "注册时间", "认证通过时间", "认证状态", "餐饮类型", "省市区", "购买次数", "系统开始时间", "系统截止时间", "距截止日期剩余天数", "业务员", "业务员id"];
                const filterVal = ["shop_id", "shop_name", "contact_name", "contact_phone", "created_at", "auth_time", "status_str", "catering_type", "adress", "pay_system_num", "system_start_time", "system_end_time", "date_part", "salesman_name", "salesman_id"];
                let dataArr = response.data.map((item,index)=>{
                    item.adreess = item.province+item.city+item.area
                    item.status_str = item.status == 5 ? '审核失败' : item.status == 10 ? '审核成功' : '待审核'
                    return item
                })
                const dataSourse = formatJson(filterVal, dataArr);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '商户列表', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }

    return <div className="merchant">
         <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />}  onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
        <div className="content_body">
        <div className="screen-dart">
            <Search
                placeholder="输入门店名称、业务员名称、手机号搜索"
                className="input_view"
                onSearch={(e) => handleSerachForm(e,'search')}
                onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
            />
            <div className="screen-item">
                <div className="item-title">餐饮类型:</div>
                <Search
                    placeholder="请输入餐饮类型"
                    className="input_view"
                    onSearch={(e) => handleSerachForm(e,'catering_type')}
                    onInput = {(e) => setSerachParams({...serachParams,catering_type: e.target.value})}
                />
            </div>
            <div className="screen-item">
                <div className="item-title">认证通过时间:</div>
                <RangePicker
                    timer={serachParams.auth_timer}
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'auth_timer')}
                    />
            </div>
        </div>
        <div className="screen-dart">
      
            <div className="screen-item">
                <div className="item-title">系统开始时间:</div>
                <RangePicker
                    timer={serachParams.system_start_timer}
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'system_start_timer')}
                    />
            </div>
            <div className="screen-item">
                <div className="item-title">系统截止时间:</div>
                <RangePicker
                    timer={serachParams.system_end_timer}
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'system_end_timer')}
                    />
            </div>
            <div className="screen-item">
                <div className="item-title">注册时间:</div>
                <RangePicker
                    timer={serachParams.timer}
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'timer')}
                    />
            </div>
        </div>

        <Table
            scroll={{ x: 2000 }}
            loading={isLoading}
            columns={columns}
            dataSource={pageInfo.list}
            rowKey='id'
            pagination={pagination}
        />
        </div>
        
    </div>
    
}

export default MerchantList