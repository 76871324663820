import { request } from '../api/http';

//登录
export async function loginRequest(params) {
    return request({
        url: '/login',
        params,
        method:'post'
    });
}
