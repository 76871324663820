import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { Divider,
    Button,
    Space,
    Input,
    Cascader,
    message,
    Spin
} from 'antd'
import cityData from '../../../../utils/cityData'
import {agentConfigListRequest,agentAddRequest,agentDetailRequest,agentEditRequest} from '../../../../services/agentService'
import validator from '../../../../utils/validator'

import './agentAdd.scss'

const AgentAdd = (props) =>{
    let history = useHistory();
    const [agentConfig,setAgentConfig] = useState({
        selMeal: [], //套餐列表
        system: [], //系统列表
        hardware: [], //增值服务
    })

    const [agentForm,setAgentForm] = useState({
        name: '', // 代理商名称
        contact_person: '', // 联系人
        mobile: '', // 手机号
        province: '', // 省
        city: '', // 市
        area: '', // 区
        address: '未知', // 详细地址
        bank_card_number: '', // 银行卡号
        bank_card_name: '', // 银行名称
        bank_name: '', // 支行名称
        agent_profit_config: [] // 推广收益配置 type0:系统 1：硬件 2:增值服务 4:套餐
    })

    const [cityChoose,setCityChoose] = useState([])
    const [serviceRatio,setServiceRatio] =useState('')
    const [isLoading,setIsLoading] =useState(false)

    useEffect(() =>{
        let id = 0
        if( history.location.state){
            id = history.location.state.id
        }
        initPageState(id)
    },[])

    // 初始化页面数据
    const initPageState = (id) =>{
        // 获取推广收益配置列表
        agentConfigListRequest({}).then(res =>{
            if(id){
                // 编辑 获取代理商详情
                agentDetailRequest({id}).then( response =>{
                    collectEdit(response)
                    setAgentConfig(collectInitCofig(res,response))
                }).catch((error)=>{
                    message.error(error)
                })
            }else{
                setAgentConfig(collectInitCofig(res))
            }
        }).catch((error)=>{
            message.error(error)
        })
    }

    // 确认保存
    const submitForm = () =>{
        let isflag = validatorParams()
        if(isflag){
            setIsLoading(true)
            // 合并数据
            agentForm.province = cityChoose[0]
            agentForm.city = cityChoose[1]
            agentForm.area = cityChoose[2]
            let serviceConfig = {id: 0,type: 2,name: '增值服务',price: 0,old_price: 0,ratio: serviceRatio}
            agentForm.agent_profit_config = ([].concat(agentConfig.selMeal).concat(agentConfig.system).concat(agentConfig.hardware))
            agentForm.agent_profit_config.push(serviceConfig)
            agentForm.agent_profit_config = JSON.stringify(agentForm.agent_profit_config)
            if(history.location.state){
                // 编辑
                agentEditRequest({...agentForm,id:history.location.state.id}).then(res =>{
                    setIsLoading(false)
                    message.success('编辑成功')
                    history.goBack()
                }).catch((error)=>{
                    setIsLoading(false)
                    message.error(error)
                })
            }else{
                // 新建
                agentAddRequest({...agentForm}).then(res =>{
                    setIsLoading(false)
                    message.success('添加成功')
                    history.goBack()
                }).catch((error)=>{
                    setIsLoading(false)
                    message.error(error)
                })
            }
            
        }
    }

    // 校验参数
    const validatorParams = () =>{
        if(!agentForm.name){
            message.warn('代理商名称不能为空')
            return false
        }
        if(!agentForm.contact_person){
            message.warn('联系人不能为空')
            return false
        }
        if(!agentForm.mobile){
            message.warn('手机号不能为空')
            return false
        }
        if(cityChoose.length <= 0){
            message.warn('归属地区不能为空')
            return false
        }
        if(!agentForm.bank_card_number){
            message.warn('银行卡号不能为空')
            return false
        }
        if(!agentForm.bank_card_name){
            message.warn('银行名称不能为空')
            return false
        }
        if(!agentForm.bank_name){
            message.warn('支行名称不能为空')
            return false
        }
        let isSelMeal = true
        agentConfig.selMeal.map( item => {if(!item.price) {isSelMeal = false;return}})
        if(!isSelMeal) {
            message.warn('请设置系统套餐的代理价')
            return false
        } 
        let isSystem = true
        agentConfig.system.map( item => {if(!item.price) {isSystem = false; return}})
        if(!isSystem) {
            message.warn('请设置系统收益的代理价')
            return false
        }
        let isHardware = true
        agentConfig.hardware.map( item => {if(!item.price) {isHardware = false; return}})
        if(!isHardware) {
            message.warn('请设置硬件收益的代理价')
            return false
        }

        if(!serviceRatio){
            message.warn('请设置增值服务--系统比例')
            return false
        }

        return true
    }

    const handleInput =(e,type) =>{
        switch(type) {
            case 'name':
                setAgentForm({...agentForm,name: e.target.value})
                break;
            case 'contact_person':
                setAgentForm({...agentForm,contact_person: e.target.value})
                break;
            case 'mobile':
                if(!validator(e.target.value,'number').ok&&e.target.value){
                    e.target.value = agentForm.mobile
                }
                setAgentForm({...agentForm,mobile: e.target.value})
                break;
            case 'address':
                setCityChoose(e)
                break;
            case 'serviceRatio':
                setServiceRatio(e.target.value)
                break;
            case 'bank_card_number':
                setAgentForm({...agentForm,bank_card_number: e.target.value})
                break;
            case 'bank_card_name':
                setAgentForm({...agentForm,bank_card_name: e.target.value})
                break;
            case 'bank_name':
                  setAgentForm({...agentForm,bank_name: e.target.value})
                break;
        }
    }

    const handleConfig = (type,index,e) =>{
       let agentConfig_temp = JSON.parse(JSON.stringify(agentConfig))
        switch(type){
            case 'selMeal':
                agentConfig_temp.selMeal[index].price = e.target.value
                setAgentConfig(agentConfig_temp)
                break
            case 'system':
                agentConfig_temp.system[index].price = e.target.value
                setAgentConfig(agentConfig_temp)
                break
            case 'hardware':
                agentConfig_temp.hardware[index].price = e.target.value
                setAgentConfig(agentConfig_temp)
                break
        }

    }
    
    // 编辑初始化数据
    const collectEdit = (res) =>{
        setAgentForm({
            ...agentForm,
            name: res.name,
            contact_person: res.contact_person,
            mobile: res.mobile,
            bank_card_number: res.bank_card_number,
            bank_card_name: res.bank_card_name,
            bank_name: res.bank_name,
            // bank_card_number: res.bank_name
        })
        setCityChoose([res.province,res.city,res.area])
        let index = res.agent_profit_config.length>0&&res.agent_profit_config.findIndex(item => item.id == 0);
        if(index!==-1){
            setServiceRatio(res.agent_profit_config.length>0&&res.agent_profit_config[index].ratio)
        }
    }

    // 整理配置数据
    const collectInitCofig = (res,detailRes = null) =>{
        let {selMeal,system,hardware} = res;
        let selMealTemp = [],systemTemp = [],hardwareTemp=[]

        // 在详情取数据
        let configList = detailRes ? detailRes.agent_profit_config: [];
        selMeal.map(item => {
            let obj = {
                id: item.id,
                name: item.name,
                price: getPriceById(item.id,configList),
                old_price: item.money,
                type: 4,
                ratio: 0
            }
            selMealTemp.push(obj)
        })
        system.map(item => {
            let obj = {
                id: item.id,
                name: item.name,
                price: getPriceById(item.id,configList),
                old_price: item.price,
                type: 0,
                ratio: 0
            }
            systemTemp.push(obj)
        })
        hardware.map(item => {
            let obj = {
                id: item.id,
                name: item.name,
                price: getPriceById(item.id,configList),
                old_price: item.price,
                type: 1,
                ratio: 0
            }
            hardwareTemp.push(obj)
        })

        return {
            selMeal: selMealTemp,
            system: systemTemp,
            hardware: hardwareTemp
        }

    }

    // 通过配置id 找到对应的金额
    const getPriceById = (id,list) =>{
        if(list.length > 0){
            let index =  list.findIndex(item => item.id == id)
            if(index != -1) {
                return list[index].price
            }
        }
        return ''
       
    }

    // 配置view模版
    const createConfigView = (type,list) =>{
        return  <div className="system_item_right">
                {
                    list.map((item,index) =>{
                        return <div className="item_value" key={item.id}>
                        <div  className="input_title">{item.name} ：￥{item.old_price}</div>
                        <div className="value_input">
                            <div>代理价：</div>
                            <Input type="number" prefix="￥" value={item.price} placeholder="金额" onInput={(e) => handleConfig(type,index,e)} className="value_input_view" />
                        </div>
                        <div>代理商获得收益：¥ {(item.old_price  - item.price).toFixed(2)}</div>
                    </div>
                    })
                }
            </div>
    }

    return <div className="add_agent">
       
            <div className="detail_body">
            <div className="form_title">详细信息</div>
                <Divider />
                <div className="inputForm">
                    <div className="form_item">
                        <div className="item_title">名称:</div>
                        <Input placeholder="请输入名称" value={agentForm.name} onInput={(e) => handleInput(e,'name')} className="form_right" />
                    </div>
                    <div className="form_item">
                        <div className="item_title">联系人:</div>
                        <Input placeholder="请输入真实姓名（非必填）" value={agentForm.contact_person} onInput={(e) => handleInput(e,'contact_person')} className="form_right" />
                    </div>
                    <div className="form_item">
                        <div className="item_title">手机号码:</div>
                        <Input placeholder="请输入手机号码" value={agentForm.mobile} onInput={(e) => handleInput(e,'mobile')} className="form_right" maxLength={11}/>
                    </div>
                    <div className="form_item">
                        <div className="item_title">归属地区:</div>
                        <Cascader placeholder="省市区"
                            value={cityChoose}
                            className="form_right"
                            options={cityData}
                            fieldNames = {{label: 'name',value: 'name'}}
                            onChange={(e) => handleInput(e,'address')}
                            />
                    </div>
                    <div className="form_item">
                        <div className="item_title">银行卡号:</div>
                        <Input placeholder="请输入银行卡号" value={agentForm.bank_card_number} onInput={(e) => handleInput(e,'bank_card_number')} className="form_right" />
                    </div>
                    <div className="form_item">
                        <div className="item_title">银行卡名称:</div>
                        <Input placeholder="请输入银行卡名称" value={agentForm.bank_card_name} onInput={(e) => handleInput(e,'bank_card_name')} className="form_right" />
                    </div>
                    <div className="form_item">
                        <div className="item_title">支行名称:</div>
                        <Input placeholder="请输入支行名称" value={agentForm.bank_name} onInput={(e) => handleInput(e,'bank_name')} className="form_right" />
                    </div>
                </div>
        </div>
        <div className="detail_body">
        <div className="form_title">推广收益</div>
            <Divider />
            <div className="inputForm">
                <div className="system_item ">
                    <div className="item_title">系统套餐: </div>
                    {createConfigView('selMeal',agentConfig.selMeal)}
                </div>
                <div className="system_item ">
                    <div className="item_title">系统收益: </div>
                    {createConfigView('system',agentConfig.system)}
                </div>
                <div className="system_item ">
                    <div className="item_title">硬件收益: </div>
                    {createConfigView('hardware',agentConfig.hardware)}
                </div>
                <div className="system_item ">
                    <div className="item_title">增值服务--系统: </div>
                    <div className="system_item_right">
                        <div className="item_value">
                            <div className="value_input" style={{margin: 0}}>
                                <div>代理获得收益：</div>
                                <Input type="number" suffix="%" placeholder="百分比" value={serviceRatio} onInput={(e) => handleInput(e,'serviceRatio')} className="value_input_view" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className="button_group">
                <Space size={60}>
                    <Button type="primary" onClick={() => submitForm()} loading={isLoading}>保 存</Button>
                    <Button type="default">取 消</Button>
                </Space>
            </div>
       
    </div>
}

export default AgentAdd