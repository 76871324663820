
import React, {useEffect} from 'react'
import { stores } from '../../../stores/store'
import { Observer } from 'mobx-react';

const QuotaComponent = (props) =>{
    useEffect(()=>{
        stores.userStore.getMpQuota();
    },[])

    if (props.path !== '/sidebar/miniProgram/index') {
        return null;
    }
    return <Observer render={() => 
       <div style={{marginLeft:24,fontSize:12,color:"black"}}>当月提审次数：{stores.userStore.mpQuota.used} / {stores.userStore.mpQuota.limit}</div>
    }/>
}

export default QuotaComponent;


