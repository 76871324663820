import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import {
    Button,
    Input,
    DatePicker,
    Table,
    Tooltip,
    Image,
    message
} from 'antd'
import {IconFont} from '../../utils/common'
import detail_icon from '../../assets/images/detail_icon.png'
import './siyuList.scss'
import {siyuManage} from '../../services/siyuService'
const {Search} = Input
const { RangePicker } = DatePicker;
const SiYuList = (props) => {
    let history = useHistory();
    const [isLoading,setIsLoading] = useState(false)
    const [ downloadLoading, setDownloadLoading] = useState(false)
    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10,
        item_type: 3
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        system_timer: [],
        created_timer: [],
        auth_timer: [],
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])


    // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        siyuManage({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size,
            item_type: 2
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.created_timer&&serachParams_temp.created_timer.length > 0) {
            params.last_pay_time_a = serachParams_temp.created_timer[0]
            params.last_pay_time_b = serachParams_temp.created_timer[1]
        }
        if(serachParams_temp.system_timer_start&&serachParams_temp.system_timer_start.length > 0) {
            params.system_start_time_a = serachParams_temp.system_timer_start[0]
            params.system_start_time_b = serachParams_temp.system_timer_start[1]
        }
        if(serachParams_temp.system_timer_end&&serachParams_temp.system_timer_end.length > 0) {
            params.system_end_time_a = serachParams_temp.system_timer_end[0]
            params.system_end_time_b = serachParams_temp.system_timer_end[1]
        }
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'created_timer':
                serachParams_temp = {...serachParams,created_timer: e}
                break;
            case 'system_timer_start':
                serachParams_temp = {...serachParams,system_timer_start: e}
                break;
            case 'system_timer_end':
                serachParams_temp = {...serachParams,system_timer_end: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '手机号码',
            dataIndex: 'contact_phone',
            key: 'contact_phone',
        },
        {
            title: '购买时间',
            dataIndex: 'last_pay_time',
            key: 'last_pay_time',
        },
        {
            title: '购买次数',
            dataIndex: 'pay_system_num',
            key: 'pay_system_num',
        },
        {
            title: '系统开始时间',
            dataIndex: 'system_start_time',
            key: 'system_start_time',
        },
        {
            title: '系统截止时间',
            dataIndex: 'system_end_time',
            key: 'system_end_time',
        },
        {
            title: '距截止日期剩余天数',
            dataIndex: 'date_part',
            key: 'date_part',
        },
        {
            title: '业务员',
            dataIndex: 'salesman_name',
            key: 'salesman_name',
        },
        {
            title: '业务员ID',
            dataIndex: 'salesman_id',
            key: 'salesman_id',
        },
        {
            title: '操作',
            render: (text,record) =>  <Tooltip placement="top" title={'详情'}>
            <Image src={detail_icon} className="action_icon"  preview={false} onClick={() => {
                history.push("/sidebar/siyuList/detail",{id:record.id})
            }}></Image>
         </Tooltip>
        }
    ]

    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        siyuManage({page:1,page_size:3000,item_type:3}).then(response=>{
            import("../../utils/Export2Excel").then((excel) => {
                const tHeader = ["ID", "手机号码", "购买时间", "购买次数", "系统开始时间", "系统截止时间", "距截止日期剩余天数", "业务员", "业务员id" ];
                const filterVal = ["id", "mobile", "last_pay_time", "pay_system_num", "system_start_time", "system_end_time", "date_part", "salesman_real_name", "salesman_id" ];
                // let dataArr = response.data.map((item,index)=>{
                //     item.remainder_day_str = getDateDiff('',item.system_time_end).toFixed(2)
                //     return item
                // })
                const dataSourse = formatJson(filterVal, response.data);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '私域增长列表', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }
  
    
    return <div className="siyuList">
         <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />}  onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
            <div className="content_body">
                <div className="screen-dart">
                    <Search
                        placeholder="输入手机号、业务员名称搜索"
                        className="input_view"
                        onSearch={(e) => handleSerachForm(e,'search')}
                        onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
                        />
                    <div className="screen-item">
                        <div className="item-title">购买时间:</div>
                        <RangePicker
                            timer={serachParams.created_timer}
                            suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                            onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'created_timer')}
                        />
                    </div>
                    <div className="screen-item">
                        <div className="item-title">系统开始时间:</div>
                        <RangePicker
                            timer={serachParams.system_timer_start}
                            suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                            onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'system_timer_start')}
                        />
                    </div>
                    <div className="screen-item">
                        <div className="item-title">系统截止时间:</div>
                        <RangePicker
                            timer={serachParams.system_timer_end}
                            suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                            onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'system_timer_end')}
                        />
                    </div>
                </div>
                <Table
                    loading={isLoading}
                    columns={columns}
                    dataSource={pageInfo.list}
                    rowKey='id'
                    pagination={pagination}
                />
            </div>
        </div>
}

export default SiYuList
