import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DatePicker, Select, Cascader, Space, Tooltip, Image, Table, Input, message } from 'antd'
import { getOverView, getStoreList } from '../../../services/index'
import { IconFont } from '../../../utils/common'
import { agentListRequest } from '../../../services/agentService'
import cityData from '../../../utils/cityData'
import edit_icon from '../../../assets/images/edit_icon.png'
import store_icon from '../../../assets/images/index/store_icon.png'
import store_line_icon from '../../../assets/images/index/store_line_icon.png'
import store_ready_icon from '../../../assets/images/index/store_ready_icon.png'
import store_total_icon from '../../../assets/images/index/store_total_icon.png'

import './index.scss'

const { RangePicker } = DatePicker;
const { Search } = Input
const { Option } = Select;

const Index = (props) => {
	const history = useHistory()
  const dateList = ['今天', '昨天', '近7天', '近30天', '本月', '上月', '本季度']
  const [ currentDate, setCurrentDate ] = useState(0)
  const [ sParams, setSParams ] = useState({}) //请求数据统计的参数
	const [ processList, setProcessList ] = useState([]) //接入流程中数据
	const [ agentList, setAgentList ] = useState([])
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ paramsObj, setParamsObj ] = useState({
    anent:-1,
    page: 1,
    page_size: 10,
  }) //门店列表参数

  const [ dataList, setDataList ] = useState([
    { id:1, name:'全部门店', imgUrl:store_icon,total:'--'},
    { id:2, name:'已上线', imgUrl:store_total_icon,total:'--'},
    { id:3, name:'接入中', imgUrl:store_icon,total:'--'},
    // { id:3, name:'已上线门店', imgUrl:store_line_icon,total:'--'},
    // { id:4, name:'正在接入门店', imgUrl:store_ready_icon,total:'--'}
  ]) //统计数据

  useEffect(() => {
		_init()
  }, [])
  
  const _init = () => {
		getStatistics()
		getList({...paramsObj})
		getAgentList()
	}
	
	//获取数据总览
  const getStatistics = (data = {}) => {
		let param = {
				...data
		}		
		Object.keys(param).map(el => {
				if(el==='status'){
						if(param[el]===-1) delete param[el]
				}else{
						if(!param[el]) delete param[el]
				}
		})		
		setSParams({...param})
    getOverView(param).then((res)=>{
			dataList[0].total = res.store_total
			dataList[1].total = res.line_store_total
			dataList[2].total = res.connecting_store_total
			// dataList[3].total = res.connecting_store_total
      setProcessList(res.items)
			setDataList(dataList)
    }).catch(err=>{
        message.error(err||'数据获取失败')
    })
	}

	//获取门店列表
  const getList = (data = {}) => {
      let param = {
          ...data
      }
      if(param.sortType)param.sortType=param.sortType==='ascend'?'asc':'desc'
      Object.keys(param).map(el => {
          if(el==='status'){
              if(param[el]===-1) delete param[el]
          }else{
              if(!param[el]) delete param[el]
          }
      })
      setParamsObj({...param})
      setLoading(true)
      getStoreList(param).then((res)=>{
          setLoading(false)
          setData(res.data);
          setTotal(res.total)
      }).catch((error)=>{
          setLoading(false)
          message.error(error)
      })
  }

	//获取代理商列表
	const getAgentList = () => {
    agentListRequest({page:1,pageSize:5000}).then((res)=>{
			setAgentList(res.list.data)
    }).catch(err=>{
			console.log(err)
    })
	}

	const goEdit = (id) => {
		history.push('/index/detail',{id:id})
	}

  const creatTable = ()=>{
          
      const columns = [
          {
              title: '门店ID',
              dataIndex: 'shop_id',
              key: 'shop_id',
          },
          {
              title: '门店名称',
              dataIndex: 'shop_name',
              key: 'shop_name',
          },
          {
              title: '门店负责人',
              dataIndex: 'contact_name',
              key: 'contact_name',
          },
          {
              title: '手机号',
              dataIndex: 'contact_phone',
              key: 'contact_phone',
          },
          {
              title: '微信号',
              dataIndex: 'userWx',
              key: 'userWx',
          },
          {
              title: '签约时间',
              dataIndex: 'created_at',
              key: 'created_at',
          },
          {
              title: '签约业务员',
              dataIndex: 'salesman_name',
              key: 'salesman_name',
          },
          {
              title: '客服',
              dataIndex: 'service',
							key: 'service',
							render: (text, record) => 
								<>
								{
									record.responsibles.findIndex((item)=>item.role===1)!==-1?record.responsibles.map((pItem,pIndex)=>{
										return pItem.role===1&&(pItem.items.length===0?'未分配':pItem.items.map((item,index)=>{
										return <div key={pIndex+'-'+index}>{item.name}</div>
										}))
									}):'未分配'
								}
								</>
          },
          {
              title: '技术IT',
              dataIndex: 'it',
							render: (text, record) => 
								<>
								{
									record.responsibles.findIndex((item)=>item.role===2)!==-1?record.responsibles.map((pItem,pIndex)=>{
										return pItem.role===2&&(pItem.items.length===0?'未分配':pItem.items.map((item,index)=>{
										return <div key={pIndex+'-'+index}>{item.name}</div>
										}))
									}):'未分配'
								}
								</>
          },
          {
              title: '接入阶段',
              dataIndex: 'circuit_node_name',
							key: 'circuit_node_name',
							// render: (text, record) => text?text:'未分配'
          },

					{
              title: '操作',
              dataIndex: 'option',
              key: 'option',
              render: ( text, record, index )=>
                  <>
                      <Space>
                          {/* <Tooltip placement="top" title={'查看'}>
                              <Image src={look_icon} className="action_icon" preview={false}></Image>
                          </Tooltip>     */}
                          <Tooltip placement="top" title={'编辑'} onClick={()=>{goEdit(record.id)}}>
                              <Image src={edit_icon} className="action_icon" preview={false} ></Image>
                          </Tooltip>    
                      </Space>
                  </>
          },
      ];

      return (
          <Table 
              dataSource={data} 
              columns={columns} 
              onChange={handleTableChange}   
              rowKey={'id'}  
              loading={loading}
              pagination={{
                  defaultCurrent: 1,
                  current:paramsObj.page,
                  pageSize: paramsObj.page_size,
                  total: total,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total) => `共${total}条`,
              }}   
          />
      )
  }

  const handleTableChange = (pagination, filters, sorter) => {
      getList({
          ...paramsObj,
          page:pagination.current,
          page_size:pagination.pageSize,
          sortField:sorter.field,
          sortType:sorter.order
      })
  }
  
  const tabChange = (index) => {
    setCurrentDate(index)
  }

  const dataItems = (item,imgUrl,index) => {
    return (
      <div className="card_item" key={item.id}>
        <div className="card_item_name">{item.name}</div>
        <div className="card_item_num">{item.name=='已上线'&&index==1?(item.total-processList[processList.length-1].total):item.total}</div>
        {/* {
            imgUrl&&<Image src={imgUrl}/>
        } */}
      </div>
    )
  }
  
  return <div className="index_list">
      {/* <div className="search">
          <div className="search_item">
              <div className="item_title">地区:</div>
              <Cascader
									changeOnSelect
                  fieldNames={{ label: 'name', value: 'name', children: 'children' }}
                  options={cityData}
                  onChange={(e,option) => getStatistics({...sParams,page:1,province:e[0],city:e[1],area:e[2]})}
                  placeholder="省/市/区"
              />
          </div>
          <div className="search_item">
              <div className="item_title">代理商:</div>
              <Select
									placeholder="请选择代理商"
									allowClear
                  onChange={(e)=>{ getStatistics({...sParams,page:1,user_id:e}) }}
                  className="select_css"
              >
                  {agentList.map((item,index) => <Option key={index} value={item.id}>{item.agent_name}</Option>)}
              </Select>
          </div>
          <div className="search_item">
              <div className="item_title">接入时间:</div>
              <RangePicker
                  suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                  onChange={(dates,dateString)=>{ getStatistics({...sParams,page:1,start_time:dateString[0],end_time:dateString[0]}) }}
                  />
          </div>
      </div> */}
      <div className="card">
        {/* <div className="card_title">数据总览</div> */}
        <div className="card_list">
            {
              processList.length>0&&dataList.map((item,index)=>{
                return dataItems(item,item.imgUrl,index)
              })
            }
            {/* {
              processList.length>0&&dataItems(processList[processList.length-1])
            } */}
        </div>
      </div>
      <div className="card">
        {/* <div className="card_title">接入流程中数据</div> */}
        <div className="card_list">
          {
            processList.map((item,index)=>{
              return dataItems(item,require('../../../assets/images/index/step_'+((index+1)%6!==0?(index+1)%6:6)+'.png').default,index)
            })
          }
        </div>
      </div>
      <div className="table_wrap">
        <div className="table_title">
          <span>门店列表</span>
          <div className="table_right">
            <div className="search_item">
                {/* <div className="item_title">代理商:</div> */}
                <Search
                  placeholder="输入门店名、ID、地区搜索"
                  onSearch={value => {
                      getList({...paramsObj,page:1,keyword:value})
                  }}
                  style={{width:300}}
              />  
            </div>              
            <div className="search_item">
                <div className="item_title">选择阶段:</div>
                <Select
										// value={paramsObj.anent}
										placeholder="请选择阶段"
										allowClear
                    onChange={(e)=>{ getList({...paramsObj,page:1,node_id:e}) }}
                    className="select_css"
                >
                    {processList.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                </Select>
            </div>
          </div>
        </div>
        {
            creatTable()
        }  
      </div>
  </div>
}
export default Index