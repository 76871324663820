import moment from 'moment';

// 一页的数据量
const PAGE_SIZE = 20;

const pagination = (list = [], nextPage, limit) => {
    const offset = (nextPage - 1) * limit;
    if (list.length - offset < limit) {
        return list.slice(offset);
    }
    return list.slice(offset, offset + limit);
};

// 携带时间字段的数据转换
const formatWithTime = (list = []) => {
    return sortByCreateTime(list).map((item) => {
        return Object.assign(item, { create_time: moment(new Date(item.create_time * 1000)).format('YYYY-MM-DD HH:mm:ss') });
    });
};

//  根据创建时间排序(降序)
const sortByCreateTime = (list = []) => {
    return list.sort((a, b) => {
        if (a.create_time > b.create_time) {
            return -1;
        }
        if (a.create_time < b.create_time) {
            return 1;
        }
        return 0;
    });
};

// 合并对象字段以及数据
const mergeTo = (from = [], to = []) => {
    if (from.length == 0) {
        return to;
    }
    return to.map((item) => {
        return Object.assign(
            item,
            from.find((cItem) => {
                return item.appid === cItem.appid;
            })
        );
    });
};

export default {
    PAGE_SIZE,
    pagination,
    formatWithTime,
    sortByCreateTime,
    mergeTo,
};
