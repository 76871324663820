import React, { useState, useEffect } from 'react'
import { Row, Input, Button, message, Select } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { getRoleSelectGroup, saveUser } from '../../../services/permissions'
import './add.scss'

const { Option } = Select

const PermissionsUserAdd = ()=>{

    const history = useHistory()
    const location = useLocation()
    const [ editPssword, setEditPssword ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ typeList, setTypeList ] = useState([])
    const [ formVal, setFormVal ] = useState({
        name: '',//名称
        mobile: '',//手机号
        group_id: 0,//分组id
        status: -1,//状态 1冻结 0正常
        password: '',//密码
        id: '',//管理员id，修改时传
    })
    const statusList = [ '正常', '冻结' ]

    useEffect(()=>{
        if(location.state&&location.state.id){
            setFormVal({
                ...formVal,
                ...location.state
            })
        }else{
            setEditPssword(true)
        }
        getGroupList()
    },[])

    const getGroupList = () => {
        getRoleSelectGroup().then((res)=>{
            setTypeList(res)
        })
    }

    //监听字段变化
    const changeFieldVal = async( e, field ) => {
        let fieldObj = { [field]: field === 'group_id'||field === 'status' ? e : e.trim() }
        if((field === 'mobile') && e){
            var rule = /^[0-9]*$/
            if(!rule.test(e)) fieldObj = { [field]: formVal[field] }
        }

        setFormVal({
            ...formVal,
            ...fieldObj
        })
    }

    const onSubmit = ()=>{
        let flag = validatorParams()
        if(flag){
            //整理提交数据
            let formValTemp = JSON.parse(JSON.stringify(formVal))
            if(!formValTemp.password){
                delete formValTemp.password
            }
            if(!formValTemp.id){
                delete formValTemp.id
            }
            setLoading(true)
            saveUser(formValTemp).then((res)=>{
                setLoading(false)
                message.success(formVal.id?'修改成功':'添加成功')
                history.goBack()
            }).catch((error)=>{
                setLoading(false)
                message.error(error||(formVal.id?'修改失败':'添加失败'))
            })
        }
    }
    // 校验提交数据
    const validatorParams = () =>{
        if(!formVal.name){
          message.error('请输入角色名称')
          return false
        }
        if(!formVal.mobile||!(/^1[3456789]\d{9}$/.test(formVal.mobile))){
          message.error('请输入正确的手机号')
          return false
        }
        if(!formVal.group_id){
            message.error('请选择分组')
            return false
        }
        if(formVal.status===-1){
            message.error('请选择状态')
            return false
        }
        if(!formVal.id&&!formVal.password){
            message.error('请选择设置密码')
            return false
        }
        return true
      }
  
    const goBack = () => {
        history.goBack()
    }

    return(
        <div className="permissions_user_add">
            {/* <div className="title">新增业务员</div> */}
            <div className="detail_body">
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">姓名：</div>
                    <Input className="row_input" value={formVal.name} placeholder="请输入角色名称" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'name')}}/>
                </Row>
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">手机号：</div>
                    <Input className="row_input" value={formVal.mobile} maxLength={11} placeholder="请输入手机号" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'mobile')}}/>
                </Row>
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">分组：</div>
                    <Select value={formVal.group_id} className="row_select" onChange={(e)=>{changeFieldVal(e,'group_id')}}>
                        <Option value={0}>请选择</Option>
                        {
                            typeList.map((item,index)=>{
                                return <Option value={item.id} key={'statusList'+index}>{item.group_name}</Option>
                            })
                        }
                    </Select> 
                </Row>
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">状态：</div>
                    <Select value={formVal.status} className="row_select" onChange={(e)=>{changeFieldVal(e,'status')}}>
                        <Option value={-1}>请选择</Option>
                        {
                            statusList.map((item,index)=>{
                                return <Option value={index} key={'statusList'+index}>{item}</Option>
                            })
                        }
                    </Select>                      
                </Row>
                <Row type="flex" align="middle" className="row_wrap">
                    {
                        !editPssword?(
                            <Button className="mr10" onClick={()=>{setEditPssword(true)}}>重置密码</Button>
                        ):(
                            <>
                                <div className="row_view">用户密码：</div>
                                <Input className="row_input" type="password" placeholder="请输入用户密码" onChange={(e)=>{changeFieldVal(e.currentTarget.value,'password')}}/>
                                {
                                    formVal.id&&<Button className="ml10" onClick={()=>{setEditPssword(false)}}>取消</Button>    
                                }
                                                       
                            </>  
                        )
                    }

                </Row>

            </div>
            <Row className="footer" size={50} justify="center">
                <Button className="footer_btn_l mr20" type="primary" onClick={()=>{onSubmit()}} loading={loading}>确定</Button>
                <Button onClick={()=>{goBack()}}>取消</Button>
            </Row>            
        </div>
    )
}

export default PermissionsUserAdd


