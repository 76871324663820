import React, { useState, useEffect } from 'react'
import { Row, Input, Button, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { Tree } from 'antd';
import { getRoleMenu, saveRoleGroup, getRoleGroupDetail } from '../../../services/permissions'
import './add.scss'

const PermissionsRoleAdd = ()=>{

    const history = useHistory()
    const location = useLocation()
    const [ name, setName ] = useState('')
    const [ operate_id, setOperateId ] = useState('')
    let tempAllCheckedKeys = []
    let tempExpandedKeys = []
    let tempCheckedKeys = []
    const [expandedKeys, setExpandedKeys] = useState(['0-0-0', '0-0-1']);
    const [checkedKeys, setCheckedKeys] = useState([]);
    const [allCheckedKeys, setAllCheckedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [dataSource, setDataSource] = useState([]);
    const [ loading, setLoading ] = useState(false)

    useEffect(()=>{
        if(location.state&&location.state.id){
            // tempExpandedKeys = []
            // tempCheckedKeys = []
            let params = {
                id: location.state.id
            }
            getRoleGroupDetail(params).then((res)=>{
                let data = parentDeal(res.role,0)
                setName(res.group_name)
                setOperateId(res.operate_id)
                setExpandedKeys(data.expandedKeys)
                setCheckedKeys(data.checkedKeys)
                setAllCheckedKeys(data.allCheckedKeys)
            }).catch((error)=>{
                message.error(error||'获取配置详情失败')
            })
        }
        getRoleMenu().then((res)=>{
            setDataSource(res)
        }).catch((error)=>{
            message.error(error||'获取菜单信息失败')
        })
    },[])


	const parentDeal = (data,pid)=> {
		//声明返回数组
        let returnArr = []
		data.forEach((item)=>{
			if(item.pid === pid){
                if(item.selectd){
                    if(item.children&&item.children.length!==0){
                        tempExpandedKeys.push(item.key)
                        //进入递归中处理 
                        childrenDeal(item,item.key)
                        tempAllCheckedKeys.push(item.key)
                    }else{
                        tempAllCheckedKeys.push(item.key)
                        tempCheckedKeys.push(item.key)
                    }
                }
			}
        })
        let obj = {
            expandedKeys: tempExpandedKeys,
            checkedKeys: tempCheckedKeys,
            allCheckedKeys: tempAllCheckedKeys,
        }
		return obj;
	}

	const childrenDeal = (data,pid)=> {
		//首先判断是否有子类  没有默认为空
        if(data.children&&data.children.length!==0){
            data.children.forEach((item)=>{
                if(item.selectd){
                    if(item.pid === pid){
                        if(item.children&&item.children.length!==0){
                            tempExpandedKeys.push(item.key)
                            //进入递归中处理 
                            childrenDeal(item,item.key)
                            tempAllCheckedKeys.push(item.key)
                        }else{
                            tempCheckedKeys.push(item.key)
                            tempAllCheckedKeys.push(item.key)
                        }
                    }
                }
            })
        }
	}

    const onExpand = expandedKeys => {
        // if not set autoExpandParent to false, if children expanded, parent can not collapse.
        // or, you can remove all expanded children keys.
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeys,e) => {
        setCheckedKeys(checkedKeys);
        setAllCheckedKeys(checkedKeys.concat(e.halfCheckedKeys))
    };

    // const onSelect = (selectedKeys, info) => {
    //     setSelectedKeys(selectedKeys);
    // };

    const onSubmit = ()=>{
        let flag = validatorParams()
        if(flag){
            let formVal = {
                name: name,
                role_ids: allCheckedKeys.join(','),
            }
            if(location.state&&location.state.id) formVal.id = location.state.id
            setLoading(true)
            saveRoleGroup(formVal).then((res)=>{
                message.success(formVal.id?'修改成功':'添加成功')
                setLoading(false)
                history.goBack()
            }).catch((error)=>{
                setLoading(false)
                message.error(error||(formVal.id?'修改失败':'添加失败'))
            })
        }
    }
    // 校验提交数据
    const validatorParams = () =>{
        if(!name){
            message.error('请输入分组名称')
            return false
        }
        if(checkedKeys.length === 0){
          message.error('请选择权限')
          return false
        }
        return true
    }
  
    const goBack = () => {
        history.goBack()
    }

    return(
        <div className="permissions_role_add">
            {/* <div className="title">新增业务员</div> */}
            <div className="detail_body">
                <Row type="flex" align="middle" className="row_wrap">
                    <div className="row_view">分组名称：</div>
                    <Input className="row_input" value={name} placeholder="输入分组名称" onChange={(e)=>{setName(e.currentTarget.value)}} disabled={operate_id===0}/>
                </Row>
                <div className="tree_wrap">
                    <div className="tree_title">权限配置</div>
                    {
                        dataSource&&dataSource.length>0&&<div className="tree">
                            <Tree
                                checkable
                                onExpand={onExpand}
                                expandedKeys={expandedKeys}
                                autoExpandParent={autoExpandParent}
                                onCheck={onCheck}
                                checkedKeys={checkedKeys}
                                // onSelect={onSelect}
                                // selectedKeys={selectedKeys}
                                treeData={dataSource}
                            />
                        </div>
                    }
                </div>
            </div>
            <Row className="footer" size={50} justify="center">
                <Button className="footer_btn_l mr20" type="primary" onClick={()=>{onSubmit()}} loading={loading}>确定</Button>
                <Button onClick={()=>{goBack()}}>取消</Button>
            </Row>            
        </div>
    )
}

export default PermissionsRoleAdd


