import React, { useEffect, useState } from 'react'
import { Row, Avatar, Descriptions, Button,Col,message} from 'antd';
import { useHistory } from 'react-router-dom';

import {shopDetailRequest} from '../../../../services/merchantService'

import './detail.scss'

const MerchantsDetail = (props)=>{

    const history = useHistory()
    const [ info, setInfo] = useState({
        shop_id: 0,
        contact_name: '',
        contact_phone: '',
        created_at: '',
        area: '',
        address: '',
        auth_time: '',
        catering_type: '',
        salesman: '',
        pay_system_num: 0,
        system_start_time: '',
        system_end_time: '',
        date_part: 0,
        pay_total: 0,
        sms_pay_num: 0,
        log_list: []
    })

    useEffect(() =>{
        if(history.location.state){
            getInfo(history.location.state.id)
        }else{
            history.goBack()
        }
    },[]);

    const getInfo = (id) =>{
        shopDetailRequest({id}).then(res => {
            setInfo({
                ...info,
                ...res,
                area: res.province+'/'+res.city+ '/'+res.district,
                salesman: res.salesman_name + '-' + res.salesman_id
            })
        }).catch((error)=>{
            message.error(error)
        })
    }



    return(
        <div className="merchants_detail">
            <Row type="flex" align="middle" style={{marginBottom: 17}}>
                <Col span={22}>
                    <Avatar size="large" src={''}/>
                    <span className="mr20">门店名称</span>
                </Col>
                <Col span={2}>
                    <Button type="link">审核资料</Button>
                </Col>
            </Row>
            <div className="detail_body_info">
                <Descriptions title="基本信息" bordered >
                    <Descriptions.Item label="门店ID">{info.shop_id}</Descriptions.Item>
                    <Descriptions.Item label="真实姓名">{info.contact_name}</Descriptions.Item>
                    <Descriptions.Item label="手机号">{info.contact_phone}</Descriptions.Item>
                    <Descriptions.Item label="注册时间">{info.created_at}</Descriptions.Item>
                    <Descriptions.Item label="地区">{info.area}</Descriptions.Item>
                    <Descriptions.Item label="门店地址">{info.address} </Descriptions.Item>
                    <Descriptions.Item label="认证通过时间">{info.auth_time}</Descriptions.Item>
                    <Descriptions.Item label="餐饮类型">{info.catering_type}</Descriptions.Item>
                    <Descriptions.Item label="推荐人">{info.salesman}</Descriptions.Item>
                </Descriptions>         
            </div>
            <div className="detail_body_info">
                <Descriptions title="系统信息" bordered >
                    <Descriptions.Item label="系统购买次数">{info.pay_system_num}</Descriptions.Item>
                    <Descriptions.Item label="系统开始时间">{info.system_start_time}</Descriptions.Item>
                    <Descriptions.Item label="距截止日期剩余天数">{info.date_part}</Descriptions.Item>
                    <Descriptions.Item label="购买总金额">{info.pay_total}</Descriptions.Item>
                    <Descriptions.Item label="系统结束时间">{info.system_end_time}</Descriptions.Item>
                    <Descriptions.Item label="短信购买条数">{info.sms_pay_num}</Descriptions.Item>
                </Descriptions>    
            </div>
                   
            <div className="detail_body_info">
                {
                    info.log_list.map((item,index) =>{
                        return (
                            <Descriptions title={`${index === 0 ? '购买记录':''}`} bordered  column={3} key={item.id}>
                                <Descriptions.Item label="购买时间">{item.pay_time}</Descriptions.Item>
                                <Descriptions.Item label="购买金额">{item.pay_money}</Descriptions.Item>
                                <Descriptions.Item label="项目类型">{item.system_name+(item.system_version?'-'+item.system_version:'')}

                                {/* {
                                    item.item_type === 0 ? '餐饮系统':
                                    item.item_type === 1 ? '物料系统':
                                    item.item_type === 2 ? '接龙':
                                    item.item_type === 3 ? '私域增长': '未知类型'+item.item_type

                                } */}
                                </Descriptions.Item>
                            </Descriptions>
                        )
                    })
                }
                    
            </div>
                     
        </div>
    )

}

export default MerchantsDetail