import React from 'react';
// import { TeamOutlined, VerifiedOutlined, BarsOutlined, FormOutlined, DollarOutlined} from '@ant-design/icons'
import Loadable from 'react-loadable';
import Loading from '../pages/components/Loading'
import MerchantList from '../pages/merchantManage/merchantList/merchantList';
import MerchantAuth from '../pages/merchantManage/merchantAuth/merchantAuth';
import AgentProfit from '../pages/financeManage/agentProfit/agentProfit';
import WithdrawalRecord from '../pages/financeManage/withdrawalRecord/withdrawalRecord';
import SalesmanList from '../pages/salesmanManage/salesmanList/salesmanManage';
import SalesmanRole from '../pages/salesmanManage/salesmanRole/salesmanRole';
import OrderManage from '../pages/orderManage/orderList/orderManage';
import OrderDetail from '../pages/orderManage/detail/detail';
import PurposeClient from '../pages/merchantManage/purposeClient/purposeClient';
import AgentAdd from '../pages/agentManage/agentList/agentAdd/agentAdd';
import MerchantsDetail from '../pages/merchantManage/merchantList/detail/detail';
import CustomerDetail from '../pages/merchantManage/purposeClient/detail/detail';
import SalesmanAdd from '../pages/salesmanManage/detail/add';
import AuthDetail from '../pages/merchantManage/merchantAuth/authDetail/authDetail';
import AuthEdit from '../pages/merchantManage/merchantAuth/edit/index';
import JieLongLeader from '../pages/jieLongLeader/leaderList/leaderList';
import LeaderDetail from '../pages/jieLongLeader/detail/detail';
import SiYuList from '../pages/siyuManage/siyuList'
import SiYuDetail from '../pages/siyuManage/detail/detail'
import SystemPrice from '../pages/systemStore/systemPrice/systemPrice'
import SystemPriceDetail from '../pages/systemStore/systemPrice/detail/detail'
import MealManage from '../pages/systemStore/mealManage/mealManage'
import MealManageDetail from '../pages/systemStore/mealManage/detail/detail'
import ServicesManage from '../pages/systemStore/servicesManage/servicesManage'
import ServicesManageDetail from '../pages/systemStore/servicesManage/detail/detail'
import TaskIndex from '../pages/task/index'
import TaskManageIndex from '../pages/task/manage/index'
import TaskDetailIndex from '../pages/task/detail/index'
import PermissionsUserIndex from '../pages/permissions/user/index'
import PermissionsUserAdd from '../pages/permissions/user/add'
import PermissionsRoleIndex from '../pages/permissions/role/index'
import PermissionsRoleAdd from '../pages/permissions/role/add'
import IndexList from '../pages/index/list';
import IndexDetail from '../pages/index/detail/index'
import DraftsPage from '../pages/miniProgram/draft/DraftPage';
import TemplatesPage from '../pages/miniProgram/template/TemplatesPage';
import MiniProgramsPage from '../pages/miniProgram/index/MiniProgramsPage';
import Print from '../pages/unit/print/Print';

const AgentList = Loadable({loader: () => import(/*webpackChunkName:'Goods'*/'../pages/agentManage/agentList/agentList'),loading: Loading});
const AgentExtension = Loadable({loader: () => import(/*webpackChunkName:'Goods'*/'../pages/agentManage/agentExtension/agentExtension'),loading: Loading});


const Error404 = Loadable({loader: () => import(/*webpackChunkName:'Error404'*/'../pages/error/404'),loading: Loading});
const Error401 = Loadable({loader: () => import(/*webpackChunkName:'Error401'*/'../pages/error/401'),loading: Loading});
const Index = Loadable({loader: () => import(/*webpackChunkName:'Index'*/'../pages/index/index'),loading: Loading});

// 所有路由组件
const allComments = [
    { path: "/sidebar/agentManage/agentList", component: <AgentList />, name: '代理商列表'},                    // 代理商列表
    { path: "/sidebar/agentManage/agentList/agentAdd", component: <AgentAdd />, name: '代理商详情'},                    // 新增代理商
    { path: "/sidebar/agentManage/agentExtension", component: <AgentExtension />, name: '代理商推广'},          // 代理商推广
    { path: "/sidebar/merchantManage/merchantList", component: <MerchantList />, name: '商户列表'},           // 商户列表
    { path: "/sidebar/merchantManage/merchantList/detail", component: <MerchantsDetail />, name: '商户详情'},           // 商户详情
    { path: "/sidebar/merchantManage/merchantAuth", component: <MerchantAuth />, name: '商户审核'},           // 商户审核
    { path: "/sidebar/merchantManage/merchantAuth/authDetail", component: <AuthDetail />, name: '商户审核'},           // 商户审核
    { path: "/sidebar/merchantManage/merchantAuth/edit", component: <AuthEdit />, name: '商户编辑'},           // 商户编辑
    { path: "/sidebar/merchantManage/purposeClient", component: <PurposeClient />, name: '意向客户'},           // 意向客户
    { path: "/sidebar/merchantManage/purposeClient/detail", component: <CustomerDetail />, name: '意向客户详情'},           // 意向客户详情
    { path: "/sidebar/financeManage/agentProfit", component: <AgentProfit />, name: '代理商收益'},             // 代理商收益
    { path: "/sidebar/financeManage/withdrawalRecord", component: <WithdrawalRecord />, name: '提现记录'},        // 提现记录  
    { path: "/sidebar/salesmanManage/list", component: <SalesmanList />, name: '业务员列表'},        // 业务员列表 
    { path: "/sidebar/salesmanManage/role", component: <SalesmanRole />, name: '业务员角色列表'},        // 业务员角色列表 
    { path: "/sidebar/salesmanManage/detail", component: <SalesmanAdd />, name: '业务员详情'},        // 业务员详情
    { path: "/sidebar/orderManage/list", component: <OrderManage />, name: '订单列表'},        // 订单列表
    { path: "/sidebar/orderManage/list/detail", component: <OrderDetail />, name: '订单详情'},        // 订单详情
    { path: "/sidebar/jieLongLeader/list", component: <JieLongLeader />, name: '接龙圈主列表'},        // 接龙圈主列表
    { path: "/sidebar/jieLongLeader/list/detail", component: <LeaderDetail />, name: '接龙圈主详情'},        // 接龙圈主详情
    { path: "/sidebar/siyuList", component: <SiYuList />, name: '私域增长列表'},        // 私域增长列表
    { path: "/sidebar/siyuList/detail", component: <SiYuDetail />, name: '私域增长信息'},        // 私域增长信息
    { path: "/sidebar/systemStore/systemPrice", component: <SystemPrice />, name: '系统售价'},        // 系统售价
    { path: "/sidebar/systemStore/systemPrice/detail", component: <SystemPriceDetail />, name: '编辑系统售价'},        // 系统售价
    { path: "/sidebar/systemStore/mealManage", component: <MealManage />, name: '系统套餐'},        // 系统套餐
    { path: "/sidebar/systemStore/mealManage/detail", component: <MealManageDetail />, name: '套餐定价'},        // 套餐定价
    { path: "/sidebar/systemStore/servicesManage", component: <ServicesManage />, name: '增值服务'},        // 增值服务
    { path: "/sidebar/systemStore/servicesManage/detail", component: <ServicesManageDetail />, name: '增值服务详情'},        // 增值服务详情
    { path: "/sidebar/task/index", component: <TaskIndex />, name: '工作台'},        // 工作台
    { path: "/sidebar/task/manage/index", component: <TaskManageIndex />, name: '流程管理'},        // 流程管理
    { path: "/sidebar/task/detail/index", component: <TaskDetailIndex />, name: '任务详情'},        // 任务详情
    { path: "/sidebar/permissions/user/index", component: <PermissionsUserIndex />, name: '账户管理'},        // 账户管理
    { path: "/sidebar/permissions/user/add", component: <PermissionsUserAdd />, name: '账户添加'},        // 账户添加
    { path: "/sidebar/permissions/role/index", component: <PermissionsRoleIndex />, name: ' 角色管理'},        // 角色管理
    { path: "/sidebar/permissions/role/add", component: <PermissionsRoleAdd />, name: ' 角色添加'},        // 角色添加
    { path: "/index/detail", component: <IndexDetail />, name: ' 门店信息'},        // 首页门店信息
    { path: "/sidebar/index/list", component: <IndexList />, name: '门店列表'},                    // 代理商列表

    // 小程序发布系统
    { path: "/sidebar/miniProgram/drafts", component: <DraftsPage />, name: '代码草稿箱'},
    { path: "/sidebar/miniProgram/templates", component: <TemplatesPage />, name: '模板库'},
    { path: "/sidebar/miniProgram/index", component: <MiniProgramsPage />, name: '发布库'},


    // 工具
    { path: "/sidebar/unit/print", component: <Print />, name: '打印机'},

    { path: "/error/404", component: <Error404 />},       // 404
    { path: "/error/401", component: <Error401 />},       // 401
    { path: "/index", component: <Index />},       // 404
]


const menus = [
    {
        id: '-1',
        path: '/',
        icon: 'agent',
        name: '首页',
        children: [
            {
                id: '-1_1',
                path: '/agent',
                name: '数据概览',
                icon: '',
            },
        ]
    },
    {
        id: '0',
        path: '/sidebar/agentManage',
        icon: 'agent',
        name: '代理商管理',
        children: [
            {
                id: '0_1',
                path: '/sidebar/agentManage/agentList',
                name: '代理商列表',
                icon: '',
            },
            {
                id: '0_2',
                path: '/sidebar/agentManage/agentExtension',
                name: '代理商推广',
                icon: '',
            }
        ]
    },
    {
        id: '1',
        path: '/sidebar/merchantManage',
        icon: 'merchant',
        name: '商户管理',
        children: [
            {
                id: '1_1',
                path: '/sidebar/merchantManage/merchantList',
                name: '商户列表',
                icon: '',
            },
            {
                id: '1_2',
                path: '/sidebar/merchantManage/merchantAuth',
                name: '商户审核',
                icon: '',
            },
            {
                id: '1_3',
                path: '/sidebar/merchantManage/purposeClient',
                name: '意向客户',
                icon: '',
            }
        ]
    },
    {
        id: '2',
        path: '/sidebar/financeManage',
        icon: 'finance',
        name: '财务管理',
        children: [
            {
                id: '2_1',
                path: '/sidebar/financeManage/agentProfit',
                name: '代理商收益',
                icon: '',
            },
            {
                id: '2_2',
                path: '/sidebar/financeManage/withdrawalRecord',
                name: '提现记录',
                icon: '',
            }
        ]
    },
    {
        id: '3',
        path: '/sidebar/salesmanManage',
        icon: 'salesman',
        name: '业务员管理',
        children: [
            {
                id: '3_1',
                path: '/sidebar/salesmanManage/list',
                name: '业务员列表',
                icon: '',
            },
            {
                id: '3_2',
                path: '/sidebar/salesmanManage/role',
                name: '业务员角色',
                icon: '',
            },
        ]
    },
    {
        id: '4',
        path: '/sidebar/jieLongLeader',
        icon: 'jielong',
        name: '接龙圈主管理',
        children: [
            {
                id: '4_1',
                path: '/sidebar/jieLongLeader/list',
                name: '接龙圈主列表',
                icon: '',
            },
        ]
    },
    {
        id: '5',
        path: '/sidebar/orderManage',
        icon: 'order',
        name: '订单管理',
        children: [
            {
                id: '5_1',
                path: '/sidebar/orderManage/list',
                name: '订单列表',
                icon: '',
            }
        ]
    },
    {
        id: '6',
        path: '',
        icon: 'siyu',
        name: '私域增长',
        children: [
            {
                id: '6_1',
                path: '/sidebar/siyuList',
                name: '私域增长列表',
                icon: '',
            }            
        ]
    },
    {
        id: '7',
        path: '/sidebar/systemStore',
        icon: 'system',
        name: '系统售价',
        children: [
            {
                id: '7_1',
                path: '/sidebar/systemStore/systemPrice',
                name: '系统售价列表',
                icon: '',
            },
            {
                id: '7_2',
                path: '/sidebar/systemStore/mealManage',
                name: '系统套餐',
                icon: '',
            },
            {
                id: '7_2',
                path: '/sidebar/systemStore/servicesManage',
                name: '增值服务',
                icon: '',
            }
        ]
    },
    {
        id: '8',
        path: '/sidebar/task',
        icon: 'system',
        name: '任务管理',
        children: [
            {
                id: '8_1',
                path: '/sidebar/task/index',
                name: '工作台',
                icon: '',
            },
            {
                id: '8_2',
                path: '/sidebar/task/manage/index',
                name: '流程管理',
                icon: '',
            },
        ]
    },
    {
        id: '9',
        path: '/sidebar/permissions',
        icon: 'system',
        name: '权限管理',
        children: [
            {
                id: '9_1',
                path: '/sidebar/permissions/user/index',
                name: '账户管理',
                icon: '',
            },
            {
                id: '9_2',
                path: '/sidebar/permissions/role/index',
                name: '角色管理',
                icon: '',
            },

        ]
    }
]

export {
    allComments,
    menus
};

// // 导航
// const menus = [];

// // 所有路由 数组
// const allComments = [];


// export const routerConfig = {
//     menus,
//     allComments
// };