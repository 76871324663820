import {tool} from './common.js'

var rules = {
    required: {
        message: "这是必填写项",
        rule: /\S{1,}/
    },
    tel: {
        message: "请输入正确的手机号",
        rule: /^1[3|4|5|6|7|8|9][0-9]{9}$/
    },
    telCode: {
        message: "请输入正确的6位验证码",
        rule: /^\d{6}$/
    },
    password: {
        message: "请输入6-16位密码",
        rule: /[A-Za-z0-9@#]{6,16}/
    },
    max10: {
        message: "只能输入1-10之间的数字",
        rule: /^[0-9a-zA-Z\u4e00-\u9fa5]{1,10}$/,
    },
    number: {
        message: "只能输入数字",
        rule: /^\d+$/
        // rule: /([1-9]\d*(\.\d*[1-9])?)|(0\.\d*[1-9])/
    },
    numberMin0: {
        message: "大于0的数字",
        rule: /^[1-9]{1}[0-9]*$/
    },
    money: {
        message: "请输入正确的金额",
        rule: /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)|^\+?(\d*\.\d{2})$/
    },
    chinese: {
        message: "只能输入汉字",
        rule: /[\u4e00-\u9fa5]/
    },
    Email: {
        message: "请输入正确的邮箱",
        rule: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
    },
    url: {
        message: "请输入正确的网址",
        rule: `/[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(/.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+/.?/`
    },
    idCard: {
        message: "请输入正确的身份证号",
        rule: /^((\d{18})|([0-9x]{18})|([0-9X]{18}))$/
    }
}

/**
 * 写一个简单的数据校验方法
 * @param {Object} value 需要校验的值
 * @param {Object} rule 校验规则
 * @param {Object} callback 返回结果
 * 
 * ```
 *  使用方式: validator('','required',function(val,status){console.log})
 * 
 * ```
 */
function validator(value, rule, callback) {
    if (tool.isUndefined(value)) {
        console.error('参数校验的值必须存在');
    }
    if (!rule) {
        // rule = 'required';
        let valueTemp = ''
        if((typeof value==='string') && value.constructor===String){
            valueTemp = value.trim()
        }else{
            valueTemp = value
        }
        let data = {}
        if(!valueTemp){
            data.ok = false
            data.msg = ''
            return data
        }else{
            data.ok = true
            return data
        }
    }

    if(rules[rule].rule.test(value)){
        const data = {
            ok:true,
        }
        return data
    }else{
        const data = {
            ok:false,
            msg:rules[rule].message,
        }
        return data
    }
}

export default validator;
