import { createFromIconfontCN } from '@ant-design/icons';
var tool = {
	
	/**
	 * 判断是否为Object
	 * @param arg
	 * @returns {boolean}
	 */
	isJson: function(arg){
		if(typeof(arg) === "object" && Object.prototype.toString.call(arg).toLowerCase() === "[object object]" && !arg.length){
			return true;
		}
		return false;
	},

	/**
	 * 判断是否为Array
	 * @param array
	 * @returns {boolean}
	 */
	isArray: function(array){
		var is = ( Object.prototype.toString.call(array) === '[object Array]' );
		return is;
	},

	/**
	 * 判断是否为String
	 * @param arg
	 * @returns {boolean}
	 */
	isString: function(arg){
		if((typeof arg==='string') && arg.constructor===String){
			return true;
		}
		return false;
	},

	/**
	 * 判断是否为Function
	 * @param name
	 * @returns {boolean}
	 */
	isFunction: function(name){
		if(typeof name === "function") {
			return true;
		}
		return false;
	},

	/**
	 * 判断是否为Undefined
	 * @param arg
	 * @returns {boolean}
	 */
	isUndefined: function(arg){
		if (typeof(arg) === "undefined"){
			return true;
		}
		return false;
	},

	/**
	 * 判断是否为NULL
	 * @param arg
	 * @returns {boolean}
	 */
	isNull: function(arg){
		if(!arg && arg !== 0 && typeof arg !== "boolean"){
			return true;
		}
		return false;
	}
	
};

const IconFont = createFromIconfontCN({
	scriptUrl: [
	  '//at.alicdn.com/t/font_2092600_m3p08dzwjbs.js', 
	],
});

//保留两位小数
const checkNumber = (value) => {
	value = value.replace(/[^\d.]/g,"")
	value = value.replace(/\.{2,}/g,".")
	value = value.replace(".","$#$").replace(/\./g,"").replace("$#$",".")
	value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
	if(value.indexOf(".")< 0 && value !=""){//以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额  
		value= parseFloat(value);  
	}
	return value
}

//获取日期
const getDate = (times='') => {
    // 获取当前日期
    let date = times ? new Date(times) : new Date();
  
    // 获取当前月份
    let nowMonth = date.getMonth() + 1;
  
    // 获取当前是几号
    let strDate = date.getDate();
  
    // 添加分隔符“-”
    const seperator = "-";
  
    // 对月份进行处理，1-9月在前面添加一个“0”
    if (nowMonth >= 1 && nowMonth <= 9) {
      nowMonth = "0" + nowMonth;
    }
  
    // 对月份进行处理，1-9号在前面添加一个“0”
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
  
    // 最后拼接字符串，得到一个格式为(yyyy-MM-dd)的日期
    const nowDate = date.getFullYear() + seperator + nowMonth + seperator + strDate;

    return nowDate;
}

export {
	tool,
	IconFont,
	checkNumber,
	getDate
}