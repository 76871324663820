import React, { useState, useEffect } from 'react';
import { Image, Input, Button, Modal, AutoComplete, List, Spin, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { findCircuitNodeStore, getAllRoleMember, saveResponsible } from '../../../services/task'
import { stores } from '../../../stores/store'
import { Observer } from 'mobx-react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment'

// import './workList.scss'

const WorkList = (props) => {
  
  const { id, callBack, pIndex, total, initData } = props
  const [ visible, setVisible ] = useState(false)
  const [ options, setOptions ] = useState([]);
	const [ loading, setLoading ] = useState(false)
	const [ hasMore, setHasMore ] = useState(true)
  const [ data, setData ] = useState([])
  const [ paramsObj, setParamsObj ] = useState({})
  const [ params, setParams ] = useState({
			id: '',//管理员ID
			name: '', //管理员名称
      apply_id: '',//商家申请ID
      role: '',//工作角色 1客服 2技术
  }); //提交参数

  useEffect(() => {
    stores.userStore.getAdminInfo()
    setParamsObj({
      id:id,
      page:2,
      page_size:15
		})
		let tempData = JSON.parse(JSON.stringify(initData))
    setData(tempData)
  }, [total])
	
  const loadMore = () => {
		setLoading(true)
    findCircuitNodeStore(paramsObj).then(res=>{
      if(res.data.length === 0){
        message.warning('没有更多数据了');
        setLoading(false)
        setHasMore(false)        
        return false
      }
      let dataTemp = data.concat(res.data)
      setParamsObj({...paramsObj,page:paramsObj.page+1})
      setData(dataTemp)
      setLoading(false)
    }).catch(err=>{
      setLoading(false)
      message.error(err||'请求异常');
      console.log(err)
    })
	};

  const openModal = (id,role)=>{
      setVisible(true)
			// setParams({...params,apply_id:id,role:role})
			setParams({id:'',name:'',apply_id:id,role:role})
  }

  const handleOk = ()=>{
      if(!params.id){
          message.error('请选择成员')
          return false
      }
      setLoading(true)
      saveResponsible(params).then((res)=>{
          message.success('分配成功')
          setVisible(false)
					setLoading(false)
					callBack&&callBack()
          // if(pIndex === 0){
					// 	//未分配门店需要全部分配才能进入到下一步
          //   let arr = data.map((item,index)=>{
          //     if(item.id === params.apply_id){
					// 			let index = item.responsibles.findIndex(item=>item.role==params.role)
					// 			if(index === -1){
					// 				item.responsibles.push({
					// 					items:[{
					// 						id: params.id,
					// 						name: params.name
					// 					}],
					// 					role:params.role
					// 				})
					// 			}
          //     }
          //     return item
					// 	})
					// 	setData(arr)
          // }else{
            let arr = data.map((item,index)=>{
              if(item.id === params.apply_id){
								let index = item.responsibles.findIndex(item=>item.role==params.role)
								if(index !== -1){
									let subIndex = item.responsibles[index].items.findIndex(item=>params.id==item.id)
									if(subIndex === -1){
										item.responsibles[index].items.push({
											id: params.id,
											name: params.name
										})
									}
								}else{
									item.responsibles.push({
										items:[{
											id: params.id,
											name: params.name
										}],
										role:params.role
									})
								}
              }
              return item
						})
						setData(arr)						
					// }
          // _init()
      }).catch(err=>{
          message.error(err||'分配失败')
          setLoading(false)
      })
  }

  const handleCancel = () => {
    setVisible(false)
  }

  const onSelect = (data,option) => {
    setParams({...params,id:option.key,name:data})
  }

  let timer
  const onChange = (e) => {
      if(!e){
          setOptions([])
          setParams({...params,id:''})
          return false
      }
      timer && clearTimeout(timer)
      //500毫秒后请求
      timer = setTimeout(async()=>{
          var res = await getAllRoleMember({ keyword: e })
          let arrTemp = []
          res.map((item,index)=>{
              arrTemp.push(        {
                  label: renderTitle(item.group_name),
                  // options: [renderItem('AntDesign', 10000,1), renderItem('AntDesign UI', 10600,2)],
                  options: item.items.map((subItem)=>{
                      return renderItem(subItem.name,subItem.id)
                  }),
              })
          })
          setOptions(arrTemp)
      },500)
  }

  const renderTitle = (title) => {
      return (
          <span>
              {title}
          </span>
      );
  };

  const renderItem = (title, id) => {
      return {
          value: title,
          key: id,
          label: (
              <div
                  style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                  }}
              >
                  {title}
              </div>
          ),
      };
  };
	
	const times = (item) => {
		let className = "task_sub_item_center_times" ;
		if(((new Date().getTime()-moment("2020-11-10").toDate().getTime())/24/3600/1000).toFixed(2)>=3&&pIndex===1){
			className = "task_sub_item_center_times color_red"
		}
		if(((new Date().getTime()-moment("2020-11-10").toDate().getTime())/24/3600/1000).toFixed(2)>=30&&pIndex===2){
			className = "task_sub_item_center_times color_red"
		}
		return <span className={className}>{item.updated_node_at?moment(item.updated_node_at).format('YYYY-MM-DD'):''}</span>
	}

  return (
		<Observer render={() => <div className="task_sub_list">
			<InfiniteScroll
					initialLoad={false}
					pageStart={0}
					loadMore={loadMore}
					hasMore={!loading && hasMore}
					useWindow={false}
					className="task_sub_list_scroll"
			>
			<List
					dataSource={data}
					renderItem={(item,index) => (
						<div className="task_sub_item" key={'p'+item.id+paramsObj.id}>
								<div className="task_sub_item_content">
										<Image src={item.shop_exterior_pic} className="task_sub_item_left"/>
										<div className="task_sub_item_center">
												<div className="task_sub_item_center_name">
													{item.shop_name}
													{times(item)}
												</div>
												<div className="task_sub_item_center_role">
														<div className="task_sub_item_center_role_left">业务员 ：</div>
														<div className="task_sub_item_center_role_center"><div>{item.salesman_name}</div></div>
												</div>
												<div className="task_sub_item_center_role">
														<div className="task_sub_item_center_role_left">客服 ：</div>
														<div className="task_sub_item_center_role_center">
                              {
                                  item.responsibles&&item.responsibles.findIndex((item)=>item.role===1)!==-1?(
                                      item.responsibles.map((pItem,pIndex)=>{
                                          return pItem.role==1&&(pItem.items.length===0?<div key={'service'+pIndex}>未分配</div> :pItem.items.map((item,index)=>{
                                              return <div key={'service'+pIndex+'-'+index}>{item.name}</div>
                                          }))
                                      })
                                  ):<div>未分配</div>                    
                              }                                      
                            </div>   
														{
															stores.userStore.adminInfo.group_operate_id === 0 && <div className="task_sub_item_center_role_right" onClick={()=>{openModal(item.id,1)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
														}                   
												</div>
												<div className="task_sub_item_center_role">
														<div className="task_sub_item_center_role_left">技术IT ：</div>
														<div className="task_sub_item_center_role_center">
                              {
                                  item.responsibles&&item.responsibles.findIndex((item)=>item.role===2)!==-1?(
                                      item.responsibles.map((pItem,pIndex)=>{
                                          return pItem.role==2&&(pItem.items.length===0?<div key={'it_'+pIndex}>未分配</div> :pItem.items.map((item,index)=>{
                                              return <div key={'it_'+pIndex+'-'+index}>{item.name}</div>
                                          }))
                                      })
                                  ):<div>未分配</div>                    
                              }                                      
                            </div>
														{
															stores.userStore.adminInfo.group_operate_id === 0 && <div className="task_sub_item_center_role_right" onClick={()=>{openModal(item.id,2)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
														}
												</div>
										</div>
										{
											pIndex!=0&&<div className="task_sub_item_status">
													<span>{item.circuit_node_name}</span>
											</div>
										}
								</div>
						</div>          
					)}
			>
					{loading && hasMore && (
							<div className="list_loading">
									<Spin />
							</div>
					)}
			</List>
			</InfiniteScroll>
      <Modal
          title="添加成员"
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          className="task_add_user_modal"
          footer={null}
          width={343}
      >
          <AutoComplete
              dropdownClassName="certain-category-search-dropdown"
              dropdownMatchSelectWidth={'100%'}
              style={{ width: '100%' }}
              options={options}
              onChange={onChange}
              onSelect={onSelect}
          >
              <Input.Search size="large" placeholder="搜索成员" />
          </AutoComplete>            
          <div className="footer">
              <Button onClick={()=>{handleOk()}} loading={loading}>确认分配</Button>
          </div>
      </Modal>  
		</div>
		}/>
	)
}
export default WorkList