import { request } from '../api/http';

//数据总览
export async function getDataOverView(params) {
    return request({
        url: '/manage/data_overview',
        params,
        method:'get'
    });
}
//数据总览折线图
export async function getOverviewLineChart(params) {
    return request({
        url: '/manage/overview_line_chart',
        params,
        method:'get'
    });
}

//数据总览
export async function getOverView(params) {
    return request({
        url: '/manage/overview',
        params,
        method:'get'
    });
}

//门店列表 (数据总览)
export async function getStoreList(params) {
    return request({
        url: '/manage/store_rows',
        params,
        method:'get'
    });
}

//门店详情
// export async function getStoreDetail(params) {
//     return request({
//         url: '/manage/store_detail',
//         params,
//         method:'get'
//     });
// }

// //获取所有角色成员
// export async function getAllRoleMember(params) {
//     return request({
//         url: '/role/all_role_member',
//         params,
//         method:'get'
//     });
// }
