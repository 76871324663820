// 未上传代码
const MP_COMMIT_CODE_NOT_DONE = 0;
// 上传代码失败
const MP_COMMIT_CODE_FAIL = 1;
// 上传代码成功
const MP_COMMIT_CODE_SUCCESS = 2;

// 未提交审核
const MP_AUDIT_NOT_DONE = 0;
// 审核被拒
const MP_AUDIT_REJECT = 1;
// 审核中
const MP_AUDIT_ING = 2;
// 撤销审核
const MP_AUDIT_UNDO = 3;
// 审核延期
const MP_AUDIT_DELAY = 4;
// 审核成功
const MP_AUDIT_SUCCESS = 5;
// 提交审核操作失败
const MP_AUDIT_COMMIT_FAIL = 6;

// 未发布
const MP_RELEASE_NOT_DONE = 0;
// 发布失败
const MP_RELEASE_FAIL = 1;
// 发布成功 (发布成功后,所有的状态可以流转)
const MP_RELEASE_SUCCESS = 2;

// 提交代码-请求(template_id,user_version,user_desc)
const commitCodeRequest = (template = {}, miniProgramList = []) => {
    return miniProgramList.map((item) => {
        return {
            id: item.id,
            appid: item.appid,
            name: item.name,
            template_id: template.template_id,
            version: template.user_version,
            user_desc: template.user_desc,
            audit_id:item.audit_id,
            release_status: item.release_status,
            submit_audit_used_num: item.submit_audit_used_num,
            latest_submit_audit_time:item.latest_submit_audit_time,
            previous_release_version: item.previous_release_version,
            latest_release_version: item.latest_release_version,
            latest_release_time: item.latest_release_time,
        };
    });
};
// 提交代码响应
const commitCodeResponse = (resp = [], miniProgramList = []) => {
    return miniProgramList.map((item) => {
        return Object.assign(
            item,
            resp.find((cItem) => {
                return item.appid === cItem.appid;
            })
        );
    });
};

// 提交审核-请求数据
const submitAuditRequest = (miniProgramList = []) => {
    return miniProgramList.map((item) => {
        return {
            id: item.id,
            appid: item.appid,
            commit_code_status: item.commit_code_status,
            submit_audit_status: item.submit_audit_status,
            submit_audit_used_num: item.submit_audit_used_num,
            latest_submit_audit_time:item.latest_submit_audit_time,
            release_status: item.release_status,
            version: item.version,
            audit_id:item.audit_id,
            previous_release_version: item.previous_release_version,
            latest_release_version: item.latest_release_version,
            latest_release_time: item.latest_release_time,
        };
    });
};

// 提交审核-响应数据
const submitAuditResponse = (resp = [], miniProgramList = []) => {
    return miniProgramList.map((item) => {
        const cItem = resp.find((cItem) => {
            return item.appid === cItem.appid;
        });

        return Object.assign(item, cItem === null ? {} : cItem);
    });
};

// 撤销-响应
const undoAuditResponse = (appID, miniProgramList = []) => {
    return miniProgramList.map((item) => {
        if (item.appid === appID) {
            return Object.assign(item, { audit_id: 0, submit_audit_status: MP_AUDIT_UNDO, reason: '已主动撤销审核' });
        }
        return item;
    });
};

// 发布-与提交审核一致
const releaseRequest = submitAuditRequest;
const releaseResponse = submitAuditResponse;
const revertReleaseRequest = submitAuditRequest;
const revertReleaseResponse = submitAuditResponse;

// 上传文字描述
const commitCodeStatusToText = (commitStatus) => {
    if (commitStatus === MP_COMMIT_CODE_NOT_DONE) {
        return '未上传';
    }
    return commitStatus === MP_COMMIT_CODE_FAIL ? '上传失败' : '上传成功';
};

// 审核状态文字描述
const auditStatusToText = (auditStatus) => {
    switch (auditStatus) {
        case MP_AUDIT_NOT_DONE:
            return '';
        case MP_AUDIT_REJECT:
            return '审核被拒';
        case MP_AUDIT_ING:
            return '审核中';
        case MP_AUDIT_UNDO:
            return '审核撤销';
        case MP_AUDIT_DELAY:
            return '审核延期';
        case MP_AUDIT_SUCCESS:
            return '审核成功';
        case MP_AUDIT_COMMIT_FAIL:
            return '提审失败';
        default:
            break;
    }
    return '未知,请联系开发者';
};

// 发布状态文字描述
const releaseStatusToText = (releaseStatus) => {
    if (releaseStatus == MP_RELEASE_NOT_DONE) {
        return '';
    }
    return releaseStatus === MP_RELEASE_FAIL ? '发布失败' : '发布成功';
};

// 定义tag渲染颜色
const renderStatusColorOfTag = (statusValue) => {
    if (statusValue === 0) {
        return 'default';
    }
    if (statusValue === 1) {
        return 'red';
    }
    return 'green';
};

export default {
    MP_COMMIT_CODE_NOT_DONE,
    MP_COMMIT_CODE_FAIL,
    MP_COMMIT_CODE_SUCCESS,
    MP_AUDIT_SUCCESS,
    MP_AUDIT_ING,
    MP_RELEASE_SUCCESS,
    commitCodeRequest,
    commitCodeResponse,
    submitAuditRequest,
    submitAuditResponse,
    undoAuditResponse,
    releaseRequest,
    releaseResponse,
    revertReleaseRequest,
    revertReleaseResponse,
    commitCodeStatusToText,
    auditStatusToText,
    releaseStatusToText,
    renderStatusColorOfTag,
};
