import { request } from '../api/http';

//查找店铺打印机
export async function shopPrintListRequest(params) {
    return request({
        url: '/unit/print/list',
        params,
        method:'get'
    });
}


//检查打印机状态
export async function shopPrintIndexCheckRequest(params) {
    return request({
        url: '/unit/print/check',
        params,
        method:'post'
    });
}