import React, { useEffect, useState } from 'react'

import { useHistory } from "react-router-dom";
import {
    Button,
    Input,
    DatePicker,
    Select,
    Table,
    Tooltip,
    Image,
    message
} from 'antd'
import {IconFont} from '../../../utils/common'
import detail_icon from '../../../assets/images/detail_icon.png'
import edit_icon from '../../../assets/images/edit_icon.png'

import {authShopListRequest} from '../../../services/merchantService'

import './merchantAuth.scss'

const { Option } = Select;
const {Search} = Input
const selectData = [{id: -1,name:'全部'},{id:  0,name:'待审核'},{id: 11,name:'审核中'},{id: 5,name:'审核失败'},{id: 10,name:'审核成功'}]
const { RangePicker } = DatePicker;
const MerchantAuth = (props) => {
    let history = useHistory();

    const [isLoading,setIsLoading] = useState(false)

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        status: -1, // 0待审核 5 审核失败 10 审核成功
        timer: [],
        auth_timer: [],
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])


    // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        authShopListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.status != -1) params.status = serachParams_temp.status
        if(serachParams_temp.timer.length > 0) {
            params.created_start = serachParams_temp.timer[0]
            params.created_end = serachParams_temp.timer[1]
        }
        if(serachParams_temp.auth_timer.length > 0) {
            params.auth_start = serachParams_temp.auth_timer[0]
            params.auth_end = serachParams_temp.auth_timer[1]
        }
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'status':
                serachParams_temp = {...serachParams,status: e}
                break;
            case 'timer':
                serachParams_temp = {...serachParams,timer: e}
                break;
            case 'auth_timer':
                serachParams_temp = {...serachParams,auth_timer: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    const columns = [        
        {
            title: '商户名称',
            dataIndex: 'shop_name',
            width: 100,
            key: 'shop_name',
            fixed: 'left',
        },
        {
            title: '真实姓名',
            dataIndex: 'contact_name',
            key: 'contact_name',
            width: 100,
            fixed: 'left',
        },
        {
            title: '手机号码',
            dataIndex: 'contact_phone',
            key: 'contact_phone',
        },
        {
            title: '注册时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '认证通过时间',
            dataIndex: 'auth_time',
            key: 'auth_time',
        },
        {
            title: '认证状态',
            dataIndex: 'status',
            key: 'status',
            render: (text) => text == 5 ? '审核失败' : text == 10 ? '审核成功': text == 0 ? '待审核': '审核中'
        },
        {
            title: '业务员',
            dataIndex: 'salesman_id',
            key: 'salesman_id',
            render: (text,record) => record.salesman_id ? record.salesman_id + record.salesman_name : ''
        },
        {
            title: '身份证号',
            dataIndex: 'contact_id_card',
            key: 'contact_id_card',
        },

        {
            title: '身份证照片正面',
            dataIndex: 'id_card_front',
            key: 'id_card_front',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '身份证照片背面',
            dataIndex: 'id_card_reverse',
            key: 'id_card_reverse',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },

        {
            title: '银行卡号',
            dataIndex: 'contact_bank_card',
            key: 'contact_bank_card',
        },
        {
            title: '银行卡照片',
            dataIndex: 'bank_front',
            key: 'bank_front',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '营业执照照片',
            dataIndex: 'business_license',
            key: 'business_license',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '食品经营许可证',
            dataIndex: 'food_license',
            key: 'food_license',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '门头照片',
            dataIndex: 'shop_exterior_pic',
            key: 'shop_exterior_pic',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '店内照片',
            dataIndex: 'shop_inner_pic',
            key: 'shop_inner_pic',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '收银台',
            dataIndex: 'cashier_desk_pic',
            key: 'cashier_desk_pic',
            render: text => text&&<Image className="table_img" preview={true} src={text}></Image>
        },
        {
            title: '操作',
            fixed: 'right',
            width: 100,
            render: (text,record) => <div>
                <Tooltip placement="top" title={'详情'}>
                    <Image src={detail_icon} className="action_icon"  preview={false} onClick={() => {
                        history.push("/sidebar/merchantManage/merchantAuth/authDetail",{id: record.id})
                    }}></Image>
                 </Tooltip>
                 <Tooltip placement="top" title={'编辑'} className="ml10">
                    <Image src={edit_icon} className="action_icon"  preview={false} onClick={() => {
                        history.push("/sidebar/merchantManage/merchantAuth/edit",{id: record.id})
                    }}></Image>
                 </Tooltip>
            </div>,
        },
    ]

    return <div className="merchantAuth">
         {/* <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />}>导出</Button> */}
        <div className="content_body">
        <div className="screen-dart">
            <Search
                placeholder="输入门店名称、姓名、业务员名称、手机号搜索"
                className="input_view"
                onSearch={(e) => handleSerachForm(e,'search')}
                onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
            />
            <div className="screen-item">
                <div className="item-title">状态:</div>
                <Select
                    value={serachParams.status}
                    onChange={(e) => handleSerachForm(e,'status')}
                    className="select-css"
                  >
                    {selectData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                  </Select>
            </div>
            <div className="screen-item">
                <div className="item-title">注册时间:</div>
                <RangePicker
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'timer')}
                    timer={serachParams.timer}
                    />
            </div>
            <div className="screen-item">
                <div className="item-title">认证通过时间:</div>
                <RangePicker
                    suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                    onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'auth_timer')}
                    timer={serachParams.auth_timer}
                    />
            </div>
        </div>

        <Table
            loading={isLoading}
            scroll={{ x: 2500 }}
            columns={columns}
            dataSource={pageInfo.list}
            rowKey='id'
            pagination={pagination}
        />
        </div>
        
    </div>
    
}

export default MerchantAuth