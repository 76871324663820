import React, { useState, useEffect } from 'react';
import { Button, Input, Image, message, Modal } from 'antd'
import { getStoreMessage, addMessage, delMessage } from '../../../../services/task'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import delete_icon from '../../../../assets/images/delete_icon.png'
import './remark.scss'


const TaskDetailRemark = (props) => {
    const { id } = props
    const [ list, setList ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ msg, setMsg ] = useState('')

    useEffect(()=>{
        _init()
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    },[id])

    const _init = () => {
        getStoreMessage({apply_id:id,page:1,page_size:10000}).then(res=>{
            //暂时不做分页
            setList(res.data)
        }).catch(err=>{
            message.error(err||'留言板信息获取失败')
        })
    }

    const sendMessage = () => {
        if(!msg){
            message.error('请输入留言内容')
            return false
        }
        setLoading(false)
        addMessage({apply_id:id,message:msg}).then(res=>{
            message.success('发送成功')
            setMsg('')
            _init()
        }).catch(err=>{
            message.error(err||'发送失败')
        })
    }

    const deleteMessage = (id) => {
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除留言',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                delMessage({id:id}).then((res)=>{
                    message.success('删除成功')
                    _init()
                }).catch((error)=>{
                    message.error(error||'删除失败')
                })
            }
        });   
    }

    return (
        <div className="task_detail_remark">       
            <div className="remark_list">
                {
                    list.map((item,index)=>{
                        return <div className="remark_item" key={item.id}>
                            <div className="remark_item_left">
                                <div>{item.operate_name}--{item.group_name}<span>{item.created_at}</span></div>
                                <div>{item.content}</div>
                            </div>
                            <Image src={delete_icon} preview={false} onClick={()=>{deleteMessage(item.id)}}/>
                        </div>
                    })
                }
            </div>
            <div className="remark_footer">
                <Input placeholder="请输入内容" value={msg} onChange={(e)=>{setMsg(e.currentTarget.value.trim())}}></Input>
                <Button onClick={()=>{sendMessage()}} loading={loading}>发 送</Button>
            </div>
        </div>
    )
}
export default TaskDetailRemark