import { request } from '../api/http';

//套餐内容列表
export async function serviceListRequest(params) {
    return request({
        url: '/setmeal/systemList',
        params,
        method:'get'
    });
}

//套餐列表
export async function mealListRequest(params) {
    return request({
        url: '/setmeal/list',
        params,
        method:'get'
    });
}
//添加套餐
export async function mealAddRequest(params) {
    return request({
        url: '/setmeal/add',
        params,
        method:'post'
    });
}

//编辑套餐
export async function mealEditRequest(params) {
    return request({
        url: '/setmeal/edit',
        params,
        method:'post'
    });
}
//编辑套餐
export async function meaDelRequest(params) {
    return request({
        url: '/setmeal/delete',
        params,
        method:'get'
    });
}

//套餐详情
export async function mealDetailRequest(params) {
    return request({
        url: '/setmeal/detail',
        params,
        method:'get'
    });
}


//增值服务列表
export async function incrementListRequest(params) {
    return request({
        url: '/system/list',
        params,
        method:'get'
    });
}

//增值服务新增
export async function incrementAddRequest(params) {
    return request({
        url: '/system/add',
        params,
        method:'post'
    });
}

//增值服务详情
export async function incrementDetailRequest(params) {
    return request({
        url: '/system/index',
        params,
        method:'get'
    });
}

//增值服务删除
export async function incrementDetailDelete(params) {
    return request({
        url: '/system/delete',
        params,
        method:'post'
    });
}
