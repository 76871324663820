import { request } from '../api/http';
// 审核商户列表
export async function authShopListRequest(params) {
    return request({
        url: '/shop/shopApplyList',
        params,
        method:'get'
    });
}

// 意向客户列表
export async function authIntentionRequest(params) {
    return request({
        url: '/intention/list',
        params,
        method:'get'
    });
}

// 商户详情
export async function shopApplyDetailRequest(params) {
    return request({
        url: '/shop/shopApplyIndex',
        params,
        method:'get'
    });
}

// 商户详情
export async function intentionDetailRequest(params) {
    return request({
        url: '/intention/index',
        params,
        method:'get'
    });
}


// 商户列表
export async function shopListlRequest(params) {
    return request({
        url: '/manage/sassList',
        params,
        method:'get'
    });
}
// 商户详情
export async function shopDetailRequest(params) {
    return request({
        url: '/manage/sassIndex',
        params,
        method:'get'
    });
}

// 商户详情
export async function storeAuthConfirmReview(params) {
    return request({
        url: '/shop/confirmReview',
        params,
        method:'post'
    });
}

// 商家认证提交
export async function shopAuth(params) {
    return request({
        url: '/shop/auth',
        params,
        method:'post'
    });
}

// 获取百度API token
// export async function getBaiduAccessToken(params) {
//     return request({
//         url: '/getBaiduAccessToken',
//         params,
//         method:'post'
//     });
// }

// 百度识别营业执照
export async function getBaiduBusinessLicense(params) {
    return request({
        url: '/baiduBusinessLicense',
        params,
        method:'post'
    });
}

// 百度识别身份证
export async function getBaiduIdcard(params) {
    return request({
        url: '/baiduIdcard',
        params,
        method:'post'
    });
}

// 百度识别银行卡
export async function getBaiduBankcard(params) {
    return request({
        url: '/baiduBankcard',
        params,
        method:'post'
    });
}

// 获取银行卡支行列表
export async function searchUnionpay(params) {
    return request({
        url: '/searchUnionpay',
        params,
        method:'post'
    });
}

// 编辑进件信息
export async function shopApplyEdit(params) {
    return request({
        url: '/shop/shopApplyEdit',
        params,
        method:'post'
    });
}




