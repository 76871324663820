import { request } from '../api/http';
// 私域增长管理
export async function siyuManage(params) {
    return request({
        url: '/manage/sassList',
        params,
        method:'get'
    });
}
// 私域增长管理
export async function getSiyuDetail(params) {
    return request({
        url: '/manage/sassIndex',
        params,
        method:'get'
    });
}
