import React, { useEffect, useState } from 'react'
import {
    Button,
    Input,
    DatePicker,
    Select,
    Space,
    Table,
    Tooltip,
    Image,
    message,
    Modal,
    Upload
} from 'antd'
import {IconFont} from '../../../utils/common'
import {withdrawListRequest, withdrawEditStatus} from '../../../services/agentService'
import detail_icon from '../../../assets/images/detail_icon.png'
import withdraw_icon from '../../../assets/images/withdraw_icon.png'
import { getQiniuToken } from '../../../services/commonService'
import { QINIU_SERVER } from '../../../api/config'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import './withdrawalRecord.scss'
import { useHistory } from 'react-router-dom'

const { Option } = Select;
const {Search,TextArea} = Input
const { RangePicker } = DatePicker;
const selectData = [{id: -1,name:'全部'},{id: 10,name:'提现成功'},{id: 5,name:'提现失败'},{id: 0,name:'申请中'}]

const WithdrawalRecord = (props) =>{
    let history = useHistory()
    const [isLoading,setIsLoading] = useState(false)
    const [visible,setVisible] = useState(false)
    const [currentStatus,setCurrentStatus] = useState(0)
    const [currentId,setCurrentId] = useState(0)
    const [withdrawalStatus,setWithdrawalStatus] = useState(1)
    const [failReason,setFailReason] = useState('')
    const [successful,setSuccessful] = useState('') //成功凭证字段
    const [fileList,setFileList] = useState([])
    const [ uploadObj, setUploadObj ] = useState({})
    const [loading,setLoading] = useState(false)
    const [ downloadLoading, setDownloadLoading] = useState(false)

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        status: -1, //状态0:申请中5:提现失败10:提现成功
        timer: [],
        auth_timer: [],
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])


    // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        withdrawListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.status != -1) params.status = serachParams_temp.status
        if(serachParams_temp.timer.length > 0) {
            params.created_start = serachParams_temp.timer[0]
            params.created_end = serachParams_temp.timer[1]
        }
        if(serachParams_temp.auth_timer.length > 0) {
            params.fulfill_start = serachParams_temp.auth_timer[0]
            params.fulfill_end = serachParams_temp.auth_timer[1]
        }
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'status':
                serachParams_temp = {...serachParams,status: e}
                break;
            case 'timer':
                serachParams_temp = {...serachParams,timer: e}
                break;
            case 'auth_timer':
                serachParams_temp = {...serachParams,auth_timer: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }


    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    const columns = [
        {
            title: '订单号',
            dataIndex: 'order_sn',
            key: 'order_sn',
        },
        {
            title: '申请时间',
            dataIndex: 'created_at',
            key: 'created_at',
        },
        {
            title: '代理商名称',
            dataIndex: 'agent_name',
            key: 'agent_name',
        },
        {
            title: '代理商ID',
            dataIndex: 'agent_id',
            key: 'agent_id',
        },
        {
            title: '手机号',
            dataIndex: 'mobile',
            key: 'mobile',
        },
        {
            title: '提现金额',
            dataIndex: 'money',
            key: 'money',
        },
        { 
            title: '提现状态',
            dataIndex: 'status',
            key: 'status',
            render: text => text == 0 ? '申请中':
                            text == 5 ? '提现失败':
                            text == 10 ? '提现成功': '未知'
        },
        {
            title: '完成时间',
            dataIndex: 'fulfill_at',
            key: 'fulfill_at',
        },
        {
            title: '操作人',
            render: (text,record) => !record.operator_name?'':`${record.operator_name}-${record.operator_id}`
        },
        {
            title: '操作',
            render: (text,record) =>  <div>
                <Space>
                    {
                       record.status!=0?(
                            <Tooltip placement="top" title={'详情'} onClick={()=>{openModal(record.id,record.status,record.fail)}}>
                                <Image src={detail_icon} className="action_icon"  preview={false}></Image>
                            </Tooltip>
                       ):(
                            <Tooltip placement="top" title={'确认提现'} onClick={()=>{openModal(record.id,record.status,record.fail)}}>
                                <Image src={withdraw_icon} className="action_icon"  preview={false}></Image>
                            </Tooltip>
                       ) 
                    }
                </Space>
            </div>
        }
    ]

    const openModal = (id,status,fail)=>{
        setVisible(true)
        setCurrentStatus(status)
        setCurrentId(id)
        setFailReason(fail)
    }
    const closeModal = ()=>{
        setVisible(false)
    }
    const handleOk = ()=>{
        if(currentStatus==0){
            let params = {
                id:currentId,
            }

            if(withdrawalStatus==2){
                if(!failReason){
                    message.error('请填写失败理由')
                    return false
                }
                params.fail = failReason
            }else{
                if(!successful){
                    message.error('请上传凭证')
                    return false
                }
                params.successful = successful
            }
            withdrawEditStatus(params).then(()=>{
                message.success('操作成功')
                getPageList({...pageParams})
                closeModal()
            })
        }else{
            closeModal()
        }
    }

    //导出
    const handleDownload = () => {
        setDownloadLoading(true)
        withdrawListRequest({page:1,pageSize:3000}).then(response=>{
            import("../../../utils/Export2Excel").then((excel) => {
                const tHeader = ["订单号", "申请时间", "代理商名称", "代理商ID", "手机号", "提现金额", "提现状态", "完成时间", "操作人"];
                const filterVal = ["order_sn", "created_at", "agent_name", "agent_id", "mobile", "money", "status_str", "fulfill_at", "operator_str"];
                let dataArr = response.data.map((item,index)=>{
                    item.operator_str = !item.operator_name?'':`${item.operator_name}-${item.operator_id}`
                    item.status_str = item.status == 0 ? '申请中': item.status == 5 ? '提现失败' : item.status == 10 ? '提现成功' : '未知'
                    return item
                })
                const dataSourse = formatJson(filterVal, dataArr);
                excel.export_json_to_excel({
                    header: tHeader,
                    data:dataSourse,
                    filename: '提现记录', //文件名
                    autoWidth: true, //单元格宽度是否自适应
                    bookType: 'xlsx',   //文件类型
                });
                setDownloadLoading(false)
            });
        }).catch((err)=>{
            setDownloadLoading(false)
            message.error(err,3)
        })
    };   

    const formatJson = (filterVal, jsonData) => {
        return jsonData.map(v => filterVal.map(j => v[j]))
    }

    const createUpload = () =>{
      const uploadButton = (
        <div>
          {loading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传凭证</div>
        </div>        
      );
     return <>
        <Upload
          action={QINIU_SERVER}
          listType="picture-card"
          fileList={fileList}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          data={uploadObj}
          showUploadList={false}
        >
          {successful ? <span className="poster"><img src={successful} style={{ width: '100%' }} /></span>: uploadButton}
        </Upload>
      </>
    }
    
    // 获取图片token
    const beforeUpload = async(file) => {
        try{
            const res = await getQiniuToken({ type: 'food' })
            const key = `food/${Math.random().toString(36).slice(-8)}${(new Date()).valueOf()}.png`
            const obj = { token: res.upload_token, region: `https://up-z2.qiniu.com`, key: key, 'x:type': "food" }
            setUploadObj(obj)
        }catch(err){
            message.error(err||'获取七牛token失败')
        }
    }

    const handleChange = async(info) => {
        setFileList(info.fileList)
        if(info.fileList.length<=0)setSuccessful('')
        if (info.file.status === 'uploading') {
            setLoading(true)
            return;
        }
        if (info.file.status === 'done') {
            let file = await getBase64(info.file.originFileObj);
            setSuccessful(info.file.response.url)
            setLoading(false)
        }
    };

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    }
      
    return <div className="withdrawalRecord">
         <Button type="default" icon={ <IconFont type={'icondaochu'} style={{fontSize:16}} />} onClick={()=>{handleDownload()}} loading={downloadLoading}>导出</Button>
         <div className="content_body">
            <div className="screen-dart">
                <Search
                    placeholder="输入姓名、手机号搜索"
                    className="input_view"
                    onSearch={(e) => handleSerachForm(e,'search')}
                    onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
                />
                <div className="screen-item">
                    <div className="item-title">订单状态:</div>
                    <Select
                        onChange={(e) => handleSerachForm(e,'status')}
                        value={serachParams.status}
                        className="select-css"
                    >
                        {selectData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                    </Select>
                </div>
                <div className="screen-item">
                    <div className="item-title">申请时间:</div>
                    <RangePicker
                        timer={serachParams.status}
                        suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                        onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'timer')}
                        />
                </div>
                <div className="screen-item">
                    <div className="item-title">完成时间:</div>
                    <RangePicker
                        timer={serachParams.auth_timer}
                        suffixIcon={<IconFont type={'iconrili_1'}  style={{fontSize:18}} />}
                        onChange={(dates,dateStrings) => handleSerachForm(dateStrings,'auth_timer')}
                        />
                </div>
            </div>

            <Table
                loading={isLoading}
                columns={columns}
                dataSource={pageInfo.list}
                rowKey='id'
                pagination={pagination}
            />
         </div>
       
        <Modal
          title={currentStatus==0?'确认提现':"提示"}
          visible={visible}
          onOk={()=>{handleOk()}}
          onCancel={()=>{closeModal()}}
          footer={null}
          className="withdrawal_modal"
        >
            {
                currentStatus==0?(
                    <>
                        {/* <div>确认提现</div> */}
                        <Select value={withdrawalStatus} onChange={(e)=>{setWithdrawalStatus(e)}}>
                            <Option value={1}>提现成功</Option>
                            <Option value={2}>提现失败</Option>
                        </Select>
                        {
                            withdrawalStatus==2?(
                                <>
                                    <div>失败原因</div>
                                    <TextArea rows={4} onChange={(e)=>{setFailReason(e.currentTarget.value)}}/>                                
                                </>
                            ):(
                                <div>{createUpload()}</div>
                            )
                        }
                    </>
                ):(
                    currentStatus==10?(
                        <div>提现成功</div>
                    ):(
                        <>
                            <div>提现失败</div>
                            <div>提现失败原因：<span className="color_red">{failReason}</span></div>                        
                        </>
                    )
                )
            }
            <Button loading={false} className="mt20" onClick={()=>{handleOk()}}>确认</Button>
        </Modal>        
    </div>
}

export default WithdrawalRecord