import React from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { inject , observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import { Layout, Form, Input, Button, Card, message } from 'antd'
import CaptchaMini from 'captcha-mini'
import {loginRequest} from '../../services/login'
import {setLocalStorage,TokenKey} from '../../utils/storage'
import './index.scss'

@withRouter
@inject('userStore')
@observer
class LoginComponent extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            username:'',
            password:'',
            svg_code:'',
            currentSvgCode:'',
            loginLoding:false
        }
    }

    componentWillMount(){
        // const token = this.props.userStore.token
        // if(token){
        //     this.props.history.push('/')
        // }
    }
     
    componentDidMount(){
        /*不传值，默认配置*/
        let svg_captcha = new CaptchaMini(); 
        svg_captcha.draw(document.querySelector('#svg_captcha'), r => {
            this.setState({
                currentSvgCode:r
            })
        }); 
    }

    onFinish = values  => {

        const { currentSvgCode } = this.state
        if(!(/^1[3456789]\d{9}$/.test(values.username))){ 
            message.error('请填写正确的手机号码')
            return
        } 
        
        if(!this.compare(currentSvgCode,values.svg_code)){ 
            message.error('请填写正确的图形验证码')
            return
        } 

        let params = {
            mobile:values.username,
            password:values.password+''
        }
        this.setState({
            loginLoding:true
        })
        loginRequest(params).then( async (res)=>{
            this.setState({
                loginLoding:false
            })
            // await setLocalStorage(TokenKey,res.token)
            this.props.userStore.setAdminInfo(res.user)
            this.props.userStore.setToken(res.token)
            this.props.userStore.setMenuList(res.menus)
            this.props.history.push('/')
            //登录成功后缓存Token值，设置全局UserInfo
            // let userInfo = {
            //     // headimgurl:res.headimgurl,
            //     // nickname:res.nickname,
            //     // phone:res.phone,
            //     // uid:res.uid,
            //     username:res.username,
            //     uid:res.id,
            // }
            // this.props.userStore.setUserInfo(userInfo)
            // this.props.userStore.setToken(res.Authorization)
            // this.props.history.push('/')      
            //登录成功获取菜单
            // getUserMenusList({}).then((menu)=>{
            //     this.props.userStore.setMenuList(menu)
            //     this.props.history.push('/')            
            // }).catch((error)=>{
            //     this.setState({
            //         loginLoding:false
            //     })
            //     message.error(error)
            // })
        }).catch((error)=>{
            this.setState({
                loginLoding:false
            })
            message.error(error)
        })
    };     
    fieldChange = (e,key) => {
        this.setState({
           [key]: e.target.value.trim(),
        })
    }

    compare = (str,compareStr) => {
        //不区分大小写
        if(str.toLowerCase() === compareStr.toLowerCase()) {
            return true;
        } else {
            return false;
        }
    }

    render(){
        // const { second, buttonLoading, loginLoding } = this.state
        const { loginLoding } = this.state

        return (
            <Layout 
                className="login"
                style={{ 
                    textAlign:'center' ,
                    // backgroundImage:'url(https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg)',
                    height:'100vh'
                }}
            >
                <Card
                    style={{ width:400,position: 'absolute',top: '50%',left: '50%',transform: 'translate(-50%, -50%)' }}
                >
                    <h1>有点牛餐饮私域增长系统总后台</h1>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[{ required: true, message: '请输入用户名！' }]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名"  onChange={(e)=>{this.fieldChange(e,'username')}}/>
                        </Form.Item>
                        
                        <Form.Item
                            name="password"
                            rules={[{ required: true, message: '请输入用户密码！' }]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="密码"
                                onChange={(e)=>{this.fieldChange(e,'password')}}
                            />
                        </Form.Item>
                        <Form.Item
                            name="svg_code"
                            rules={[{ required: true, message: '请输入图形验证码！' }]}
                        >                           
                            <Input placeholder="图形验证码"  addonAfter={<canvas width="120" height="40" id="svg_captcha" className="svg_captcha"></canvas>}/>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loginLoding} type="primary" htmlType="submit" className="login-form-button">
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Layout>
        )
    }
}

export default withRouter(LoginComponent);