import React, { useState, useEffect } from 'react';
import { Modal, AutoComplete, Button, Input, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons';
import { getAllRoleMember, saveResponsible } from '../../../../services/task'
import { stores } from '../../../../stores/store'
import { Observer } from 'mobx-react';
import './join.scss'


const TaskDetailJoin = (props) => {
    const { info } = props
    const [ responsibles, setResponsibles ] = useState([])
    const [ visible, setVisible ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ options, setOptions ] = useState([]);
    const [ params, setParams ] = useState({
        id: '',//管理员ID
        apply_id: '',//商家申请ID
        role: '',//工作角色 1客服 2技术
        name:''
    }); //提交参数
    
    useEffect(()=>{
        _init()
        //以下注释可以屏蔽依赖警告
        // eslint-disable-next-line react-hooks/exhaustive-deps         
    },[info])

    const _init = () => {
        stores.userStore.getAdminInfo();
        setResponsibles(info.responsibles)
        setParams({...params,apply_id:info.id})
    }

    const renderTitle = (title) => {
        return (
            <span>
                {title}
            </span>
        );
    };

    const renderItem = (title, id) => {
        return {
            value: title,
            key: id,
            label: (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    {title}
                </div>
            ),
        };
    };

    const openModal = (role)=>{
        setVisible(true)
        setParams({...params,role:role})
    }

    const handleOk = ()=>{
        if(!params.id){
            message.error('请选择成员')
            return false
        }
        let responsiblesTemp = JSON.parse(JSON.stringify(responsibles))
        let index = responsiblesTemp.findIndex(item=>item.role === params.role)
        if(index!==-1){
            let subIndex = responsiblesTemp[index].items.findIndex(item=>item.id === params.id)
            if(subIndex!==-1){
                message.error('该成员已选择')
                return false
            }
        }
        setLoading(true)
        saveResponsible(params).then((res)=>{
            message.success('分配成功')
            setVisible(false)
            setLoading(false)
            // let responsiblesTemp = JSON.parse(JSON.stringify(responsibles))
            // index = responsiblesTemp.findIndex(item=>item.role == params.role)
            if(index!==-1&&responsiblesTemp[index]){
                responsiblesTemp[index].items.push({
                    id:params.id,
                    name:params.name,
                })
                setResponsibles(responsiblesTemp)
            }else{
                responsiblesTemp.push({
                    items:[{
                        id:params.id,
                        name:params.name,
                    }],
                    role:params.role
                })
                setResponsibles(responsiblesTemp)
            }
        }).catch(err=>{
            message.error(err||'分配失败')
            setLoading(false)
        })
    }

    const handleCancel = () => {
        setVisible(false)
    }

    const onSelect = (data,option) => {
        setParams({...params,id:option.key,name:data})
    }

    let timer
    const onChange = (e) => {
        if(!e){
            setOptions([])
            setParams({...params,id:''})
            return false
        }
        timer && clearTimeout(timer)
        //500毫秒后请求
        timer = setTimeout(async()=>{
            var res = await getAllRoleMember({ keyword: e })
            let arrTemp = []
            res.map((item,index)=>{
                arrTemp.push({
                    label: renderTitle(item.group_name),
                    options: item.items.map((subItem)=>{
                        return renderItem(subItem.name,subItem.id)
                    }),
                })
            })
            setOptions(arrTemp)
        },500)
    }


    return (
        <Observer render={() => <div className="task_detail_join">
            <div className="join">
                <div className="join_item">
                    <div className="join_item_left">业务员 ：</div>
                    <div className="join_item_center">{info.salesman_name}</div>
                    {/* <div className="join_item_right" onClick={()=>{openModal()}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div> */}
                </div>
                <div className="join_item">
                    <div className="join_item_left">客服 ：</div>
                    {
                        responsibles&&responsibles.findIndex((item)=>item.role===1)!==-1?(
                            responsibles.map((pItem,pIndex)=>{
                                return pItem.role===1&&(pItem.items.length===0?<div className="join_item_center">未分配</div> :pItem.items.map((item,index)=>{
                                    return <div key={pIndex+'-'+index} className="join_item_center">{item.name}</div>
                                }))
                            })
                        ):<div className="join_item_center">未分配</div>                    
                    }         
                    {
                        stores.userStore.adminInfo.group_operate_id === 0 && <div className="join_item_right" onClick={()=>{openModal(1)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
                    }           
                </div>
                <div className="join_item">
                    <div className="join_item_left">技术IT ：</div>
                    {
                        responsibles&&responsibles.findIndex((item)=>item.role===2)!==-1?(
                            responsibles.map((pItem,pIndex)=>{
                                return pItem.role===2&&(pItem.items.length===0?<div className="join_item_center">未分配</div> :pItem.items.map((item,index)=>{
                                    return <div key={pIndex+'-'+index} className="join_item_center">{item.name}</div>
                                }))
                            })
                        ):<div className="join_item_center">未分配</div>                    
                    }  
                    {
                        stores.userStore.adminInfo.group_operate_id === 0 && <div className="join_item_right" onClick={()=>{openModal(2)}}><PlusOutlined  style={{fontSize: 12,color: '#fff'}}/></div>
                    }
                </div>
            </div>
            <Modal
                title="添加成员"
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                className="task_add_user_modal"
                footer={null}
                width={343}
            >
                <AutoComplete
                    dropdownClassName="certain-category-search-dropdown"
                    dropdownMatchSelectWidth={'100%'}
                    style={{ width: '100%' }}
                    options={options}
                    onChange={onChange}
                    onSelect={onSelect}
                >
                    <Input.Search size="large" placeholder="搜索成员" />
                </AutoComplete>            
                <div className="footer">
                    <Button onClick={()=>{handleOk()}} loading={loading}>确认分配</Button>
                </div>
            </Modal> 
        </div>
        } />
    )
}
export default TaskDetailJoin