import React, { useEffect, useState } from 'react'
import { useHistory } from "react-router-dom";
import './systemPrice.scss'
import {IconFont} from '../../../utils/common'
import {
    Button,
    Input,
    Select,
    Space,
    Table,
    Image,
    Tooltip,
    message,
    Modal
} from 'antd'
import delete_icon from '../../../assets/images/delete_icon.png'
import edit_icon from '../../../assets/images/edit_icon.png'
import {incrementListRequest, incrementDetailDelete} from '../../../services/systemService'
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const {Search} = Input

const selectData = [{id: 0,name:'系统'},{id: 1,name:'硬件'}]

const SystemPrice = (props) =>{
    let history = useHistory();

    const [isLoading,setIsLoading] = useState(false)

    const [pageParams,setPageParams] = useState({
        page: 1,
        page_size: 10,
        service_type: 0
    })
    const [serachParams,setSerachParams] = useState({
        search: '', // keyword
        service_type: 0
    })
    const [pageInfo,setPageInfo] = useState({
        total: 0,
        list: []
    })

    useEffect(() =>{
        getPageList(pageParams)
    },[])

      // 获取页面列表
    const getPageList = (params) =>{
        setIsLoading(true)
        incrementListRequest({...params}).then(res =>{
            setPageInfo({
                total: res.total,
                list: res.list.data
            })
            setIsLoading(false)
        }).catch((error)=>{
            setIsLoading(false)
            message.error(error)
        })
    }

    // 整理请求参数
    const collectParams = (page,serachParams_temp) =>{
        let params = {
            page,
            page_size: pageParams.page_size
        };
        if(serachParams_temp.search) params.search = serachParams_temp.search
        if(serachParams_temp.service_type!==undefined) params.service_type = serachParams_temp.service_type
        getPageList(params)
    }

    const handleSerachForm = (e,type) =>{
        let serachParams_temp = serachParams
        let page = 1
        switch(type){
            case 'search':
                serachParams_temp = {...serachParams,search: e}
                break;
            case 'service_type':
                serachParams_temp = {...serachParams,service_type: e}
                break;
            case 'page':
                page = e
                break;
        }

        setSerachParams(serachParams_temp)
        setPageParams({...pageParams,page: page})
        collectParams(page,serachParams_temp)
    }

    const pagination = {
        showSizeChanger: false,
        total: pageInfo.total,
        pageSize: pageParams.page_size,
        current: pageParams.page,
        showTotal: (total,range) => `共${total}条数据`,
        onChange: (e) =>{handleSerachForm(e,'page')}
    }

    // const delMeal = (id) =>{
    //     meaDelRequest({id}).then(res =>{
    //         getPageList(pageParams)
    //         message.success('删除成功')
    //     }).catch((error)=>{
    //         message.error(error)
    //       })
    // }
    const del = (id) => {
        Modal.confirm({
            title: '操作提醒',
            icon: <ExclamationCircleOutlined />,
            content: '是否删除该增值服务项目',
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                incrementDetailDelete({id:id}).then((res)=>{
                    message.success('删除成功')
                    getPageList({...pageParams})
                }).catch((error)=>{
                    message.error(error||'删除失败')
                })
            }
        });
    }        

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '类型',
            dataIndex: 'service_type',
            key: 'service_type',
            render: (text,record) => text !== 0 ? '硬件' : '系统'
        },
        {
            title: '服务项目',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '版本',
            dataIndex: 'version',
            key: 'version',
        },
        {
            title: '描述',
            dataIndex: 'desc',
            key: 'desc',
        },
        {
            title: '使用周期',
            dataIndex: 'unit',
            key: 'unit',
        },
        {
            title: '收费标准',
            dataIndex: 'price',
            key: 'price',
        },
        {
            title: '服务内容',
            dataIndex: 'service_content',
            key: 'service_content',
            render: (text,record) => 
                <>
                    {
                        record.service_content&&JSON.parse(record.service_content).map((item,index)=>{
                            return <div key={index} style={{textAlign:'left'}}>{index+1}.{item}</div>
                        })
                    }            
                </>
        },
        {
            title: '更新时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
        },
        {
            title: '操作人',
            dataIndex: 'operation_name',
            key: 'operation_name',
            render: (text,record) => record.operation_name?record.operation_name + '-' + (record.is_official == 1 ? record.operation_id : record.user_id) : ''
        },
        {
            title: '操作',
            render: (text,record) => <div>
                <Space>
                    <Tooltip placement="top" title={'详情'}>
                        <Image src={edit_icon} className="action_icon" preview={false}
                        onClick={() => {
                            history.push("/sidebar/systemStore/systemPrice/detail",{id:record.id})
                    }}></Image>
                    </Tooltip>
                    <Tooltip placement="top" title={'删除'}>
                        <Image src={delete_icon} className="action_icon"  preview={false} onClick={()=>{del(record.id)}}></Image>
                    </Tooltip>
                </Space>
            </div>,
        },
    ]
    const data = [
        {
            id: 1,
            name: '代理商1',
            phone: '18770207933',
            area: '省市区',
            date: '2015-12-12 12:45',
            price: '代理商1',
            status: '代理商1',
            num: 100,
            sum_price: 99,
            action_p: '操作员1',
        }
    ]

    return <div className='systemPrice'>
        <Space>
            <Button type="default" onClick={() => {
                    history.push("/sidebar/systemStore/systemPrice/detail")
            }} icon={<IconFont type={'iconadd_icon'}  style={{fontSize:16}} />}>新增</Button>
        </Space>

        <div className="content_body">
            <div className="screen-dart">
                <Search
                    placeholder="输入服务项目搜索"
                    className="input_view"
                    onSearch={(e) => handleSerachForm(e,'search')}
                    onInput = {(e) => setSerachParams({...serachParams,search: e.target.value})}
                    />                
                <div className="screen-item">
                    <div className="item-title">类型:</div>
                    <Select
                        onChange={(e) => handleSerachForm(e,'service_type')}
                        value={serachParams.service_type}
                        className="select-css"
                    >
                        {selectData.map((item,index) => <Option key={index} value={item.id}>{item.name}</Option>)}
                    </Select>
                </div>
            </div>
            <Table
                 loading={isLoading}
                 columns={columns}
                 dataSource={pageInfo.list}
                 rowKey='id'
                 pagination={pagination}
            />
        </div>
        
    </div>

    
}

export default SystemPrice